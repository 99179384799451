import {
  Component,
  Order,
  OrderUrgency,
  store,
  t,
  time,
} from "@maintmark/shared";
import React from "react";
import { ModalProps } from "@maintmark/shared-react/src/ui/modals";
import styled from "styled-components";
import { Menu, MenuButton, Popup, x } from "@maintmark/shared-web/src/ui";
import { Comments } from "./Comments";
import { AssetInput } from "./AssetInput";
import {
  DateInput,
  Dropdown,
  DurationInput,
  Label,
  TextInput,
  useTabs,
} from "@maintmark/shared-web/src/ui";
import { data } from "@maintmark/shared-react";
import { AssigneeInput } from "./AssigneeInput";
import { Customize as Customize } from "./Customize";
import _ from "lodash";
import produce from "immer";
import RightImg from "../../images/right@3x.png";
import { UpdateArg } from "@maintmark/shared/src/store";
import { getSummaries } from "@maintmark/shared/src/patch";
import { ops } from "@maintmark/shared/src/data/orders";
import { Items } from "./Items";
import { MenuItem, useMenuItems } from "../item/menuitems";

const Urgency = (
  props: {
    urgency: OrderUrgency;
    onUpdate: (urgency: OrderUrgency) => void;
  } & x.divProps
) => {
  const { urgency, onUpdate, ...other } = props;
  const urgent = urgency === OrderUrgency.Urgent;
  const width = 180;
  const margin = 4;

  return (
    <x.div {...other}>
      <Label>{t("Urgency")}</Label>

      <x.div h40p br20p bgray200 bgwhite row style={{ width }} pointer relative>
        <x.div
          fill
          bggray100={!urgent}
          bgray200
          bgalert600={urgent}
          br20p
          transition100
          style={{
            position: "absolute",
            top: margin,
            left: !urgent ? margin : width / 2,
            width: width / 2 - margin * 2 + 2,
            height: 40 - margin * 2 - 2,
          }}
        ></x.div>
        <x.div
          style={{ marginLeft: margin }}
          light={urgent}
          flex
          row
          centers
          centerp
          f13
          z1
          onClick={() => onUpdate(OrderUrgency.Normal)}
        >
          {t("Normal")}
        </x.div>
        <x.div
          style={{ marginRight: margin }}
          white={urgent}
          medium={urgent}
          light={!urgent}
          f13
          flex
          row
          centers
          centerp
          z1
          onClick={() => onUpdate(OrderUrgency.Urgent)}
        >
          {t("Urgent")}
        </x.div>
      </x.div>
      {/* <Group row>
        <ToggleButton
          active={!urgent}
          activeColor={ui.theme.colors.primary[900]}
          balert600={urgent}
          bp900={!urgent}
          alert600={urgent}
          onClick={() => onUpdate(OrderUrgency.Normal)}
          h40p
        >
          {t("Normal")}
        </ToggleButton>
        <ToggleButton
          active={urgent}
          activeColor={ui.theme.colors.alert[600]}
          balert600={urgent}
          bgray300={!urgent}
          p900={!urgent}
          onClick={() => onUpdate(OrderUrgency.Urgent)}
          h40p
        >
          {t("Urgent")}
        </ToggleButton>
      </Group> */}
    </x.div>
  );
};

interface Props extends ModalProps<any> {
  orderId: string;
  initialTab?: "comments";
}

function padRow(views: JSX.Element[], size: number) {
  return [...views, ..._.range(0, size).map(() => <x.div flex />)]
    .slice(0, size)
    .map((c, index) => (
      <>
        <x.div w1={index > 0} />
        {c}
      </>
    ));
}

function renderMenuItem(item: MenuItem, close: () => void) {
  if (item === "separator") {
    return <x.div mv5p h1p bggray200 />;
  } else if ("subItems" in item) {
    const { subItems, ...other } = item;
    return (
      <MenuButton
        {...other}
        icon="arrow"
        renderChild={() => (
          <Menu sm={!item.md} pv5p>
            {item.subItems.map((child) => renderMenuItem(child, close))}
          </Menu>
        )}
      />
    );
  } else {
    return <MenuButton {...item} />;
  }
}

const NullState = (props: { desc: string; button?: string }) => (
  <x.div btgray200 bggray100 p2 flex>
    <x.div
      bggray50
      pt2
      pb3={!!props.button}
      pb1={!props.button}
      centers
      centerp
      column
      br12p
      bgray200
    >
      <x.div mb2 mt1 f12 p900 light>
        {props.desc}
      </x.div>
      {props.button && (
        <x.div
          br6p
          pointer
          ph1
          row
          centers
          centerp
          h2
          bgwhite
          bggray100="hover"
          bgray200
          f12
          p900
          noselect
        >
          {props.button}
        </x.div>
      )}
    </x.div>
  </x.div>
);

const Change = (
  props: x.divProps & { change: store.Change<any>; order: Order }
) => {
  const { change, order, ...other } = props;

  let summaries = "fwd" in change ? getSummaries(change.fwd, ops) : [];

  const user = data.users.useItem(change.createdById);

  return (
    <>
      {summaries.map((s) => (
        <x.div bgwhite bgray200 br6p row centers {...other} pv1 ph2>
          <x.div column p900 centers>
            <x.div f16 semibold>
              {time.formatShortDate(time.now())}
            </x.div>
            <x.div f12>{time.formatTime(time.now())}</x.div>
          </x.div>
          <x.div column ml1 flex f12 p900>
            <x.div medium mb2p>
              {t("Change by {user}", { user: user.name })}
            </x.div>
            <x.div>{s}</x.div>
          </x.div>
        </x.div>
      ))}
    </>
  );
};

export const OrderPanel = styled((props: Props & x.divProps) => {
  const { onClose, orderId, initialTab, ...other } = props;

  const order = data.orders.useItem(orderId);
  const createMenuItems = useMenuItems(order, () => {});

  const [changes, setChanges] = React.useState<store.Change<any>[]>([]);

  // data.orders.useStore(
  //   (store) => {
  //     setTimeout(() => {
  //       setChanges(
  //         store.getChanges().filter((change) => {
  //           if ("itemId" in change) {
  //             return change.itemId === orderId;
  //           } else {
  //             return false;
  //           }
  //         })
  //       );
  //     }, 1);
  //   },
  //   [orderId]
  // );

  // console.log(">>>>>>>>>> changes", changes.length);

  const renderMenu = React.useCallback(
    (close: () => void) => {
      return (
        <Menu mt1 pv10p md>
          {createMenuItems(close).map((item) => renderMenuItem(item, close))}
        </Menu>
      );
    },
    [createMenuItems]
  );

  const update = React.useCallback((arg: UpdateArg<Order>) => {
    data.orders.update(orderId, arg);
  }, []);

  // const save = React.useCallback(() => {
  //   onClose({});32

  //   commit();
  // }, [onClose, commit]);

  const activeTabs = new Set(order.tabs.tabs.map((t) => t.def.id));

  const { tabs, tabsContent } = useTabs(
    {
      description: t("Description"),
      comments:
        t("Work / Comments") +
        (order.comments.length > 0 ? ` (${order.comments.length})` : ""),
      images: t("Images"),
      changes: t("Changes"),
      attachments: t("Attachments"),
      items: t("Items"),
      skills: t("Skills"),
    },
    {
      description: activeTabs.has("description"),
      images: activeTabs.has("images"),
      changes: activeTabs.has("changes"),
      comments: activeTabs.has("comments"),
      attachments: activeTabs.has("attachments"),
      items: activeTabs.has("items"),
      skills: activeTabs.has("skills"),
    },
    initialTab
  );

  // TODO: Refactor this
  const builtInFields: { [key: string]: any } = {
    startsAt: (
      <DateInput
        mt1
        name={t("Start date")}
        flex
        date={order.startsAt}
        onUpdate={(startsAt) => update({ startsAt })}
      />
    ),
    plannedTime: <DurationInput mt1 name={t("Estimated time")} flex />,
    reportedTime: (
      <Dropdown
        mt1
        textright
        name={t("Reported time")}
        text={time.formatDuration(
          _.sumBy(order.reportedTime, (t) => t.duration)
        )}
        hidden
        flex
        renderContent={() => <x.div />}
      />
    ),
  };

  const customFields = order.fields.fields.filter(
    (f) => f.def.group.id === "custom"
  );

  const isTemplate = data.templates.useStore(
    (store) => store.all().some((item) => item.order.id === order.id),
    [order]
  );

  return (
    <x.div fill column {...other}>
      <x.div column flex>
        <x.div row pl2 pr1 h48p shadowLight bbgray200 centers>
          <Popup renderContent={renderMenu}>
            <x.div
              ml5p
              noselect
              f12
              p900
              opacity80
              opacity100="hover"
              pointer
              mr2
            >
              {t("Actions")}
            </x.div>
          </Popup>

          <Customize orderId={orderId}>
            <x.div
              noselect
              f12
              p900
              opacity80
              light
              opacity100="hover"
              pointer
              mr1
            >
              {t("Customise")}
            </x.div>
          </Customize>
          <x.div flex />

          <x.div
            w2
            sflex
            pointer
            row
            centers
            centerp
            opacity50
            opacity100="hover"
            onClick={() => onClose({})}
          >
            <x.img width={8} height={14} src={RightImg} />
          </x.div>
        </x.div>

        {isTemplate && (
          <x.div sflex bgp900 white ph2 pv10p f12 medium>
            {t("This is a template")}
          </x.div>
        )}

        <x.div ph2 pt2 column>
          <x.div row>
            <TextInput
              name={t("Name")}
              value={order.name}
              onUpdate={(name) => update({ name })}
              autoFocus={!order.name}
              flex
            />

            <x.div w1 />

            <Urgency
              urgency={order.urgency}
              onUpdate={(urgency) => update({ urgency })}
            />
          </x.div>
          <x.div row mt1>
            <AssigneeInput
              order={order}
              onUpdate={(assignedTo) => {
                update({ assignedTo });
              }}
              flex
            />
            <x.div w1 />
            <AssetInput
              asset={order.asset}
              onUpdate={(asset) => update({ asset })}
              flex
            />
          </x.div>
          {/* 
              <TextInput
                name={t("Asset")}
                value={order.asset?.name || ""}
                onUpdate={(name) => {}}
                flex
              /> */}
          <x.div row>
            {padRow(
              Object.keys(builtInFields)
                .filter((id) =>
                  order.fields.fields.some((f) => f.def.id === id)
                )
                .map((id) => builtInFields[id]),
              3
            )}
            {/* <DateInput
              mt1
              name={t("Start date")}
              flex
              date={order.startsAt}
              onUpdate={(startsAt) => update({ startsAt })}
            />

            {order.fields.fields.some((f) => f.def.id === "reported-time") && (
              <Dropdown mt1 name={t("Reported time")} text="" hidden flex />
            )}

            <x.div w1 />
            <DurationInput mt1 name={t("Estimated time")} flex /> */}
          </x.div>
        </x.div>
        {customFields.length > 0 && <x.div mt1 btgray200 />}

        <x.div ph2 column>
          {_.chunk(
            order.fields.fields.filter((f) => f.def.group.id === "custom"),
            3
          ).map((group) => (
            <x.div row mt1>
              {padRow(
                group.map((field) => (
                  <TextInput
                    flex
                    name={field.def.name}
                    value={field.value}
                    onUpdate={(value) =>
                      update({
                        fields: produce(order.fields, (draft) => {
                          draft.fields.forEach((f) => {
                            if (f.def.id === field.def.id) {
                              f.value = value;
                            }
                          });
                        }),
                      })
                    }
                  />
                )),
                3
              )}
            </x.div>
          ))}
        </x.div>
        <x.div mt2 ph24p>
          {tabs}
        </x.div>

        <x.div column flex bggray50>
          {tabsContent.description(
            <x.div flex column btgray200>
              <x.textarea
                lh180
                spellCheck={false}
                autoCorrect="none"
                bgray200
                bt0p
                bb0p
                bgwhite
                f14
                p900
                ph2
                pv1
                value={order.description}
                onChange={(e) => update({ description: e.target.value })}
                flex
              />
            </x.div>

            // <TextArea
            //   flex
            //   value={order.description}
            //   onUpdate={(description) => update({ description })}
            // />
          )}
          {tabsContent.comments(
            <x.div column flex ph1>
              <Comments
                order={order}
                onAdd={(comment) => {
                  update({ comments: [...order.comments, comment] });
                }}
              />
            </x.div>
          )}
          {tabsContent.images(
            <NullState desc={t("No images found")} button={t("+ Add image")} />
          )}
          {tabsContent.changes(
            changes.length > 0 ? (
              <x.div ph2 pv1 bggray50 btgray200>
                {changes.map((change) => (
                  <Change mt1 change={change} order={order} />
                ))}
              </x.div>
            ) : (
              <NullState desc={t("There are no changes")} />
            )
          )}
          {tabsContent.attachments(
            <NullState
              desc={t("No attachments found")}
              button={t("+ Add attachment")}
            />
          )}
          {tabsContent.items(<Items order={order} onUpdate={update} />)}
          {tabsContent.skills(
            <NullState desc={t("No skills found")} button={t("+ Add skill")} />
          )}
        </x.div>
      </x.div>
    </x.div>
  );
})`
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation-fill-mode: forwards;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.27, 0, 0, 1);
  animation-delay: 0s;
  animation-name: fade-in;
`;
