import React from "react";

function update(textAreaRef: HTMLTextAreaElement | null, minHeight: number) {
  if (textAreaRef) {
    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    textAreaRef.style.height = "0px";
    const scrollHeight = textAreaRef.scrollHeight;

    // We then set the height directly, outside of the render loop
    // Trying to set this with state or a ref will product an incorrect value.
    textAreaRef.style.height = Math.max(minHeight, scrollHeight) + "px";
  }
}

export function useAutosizeTextArea(
  minHeight: number,
  deps: React.DependencyList
) {
  const [element, setElement] = React.useState<HTMLTextAreaElement | null>(
    null
  );

  React.useEffect(() => {
    update(element, minHeight);
  }, [element, ...deps]);

  const onRef = React.useCallback((element: HTMLTextAreaElement | null) => {
    setElement(element);
  }, []);

  return onRef;
}
