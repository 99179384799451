import React from "react";
import { x } from "@maintmark/shared-web/src/ui";

export const FormFooter = (props: React.PropsWithChildren<{}>) => {
  return (
    <x.div h80p row centers ph2 p800 btgray200 bggray100>
      {props.children}
    </x.div>
  );
};
