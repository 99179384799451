import React from "react";
import { t, ui } from "@maintmark/shared";
import AccountImg from "./images/account@3x.png";
import MarkImg from "../images/mark@3x.png";
import AddImg from "./images/add@3x.png";
import { Search } from "../components";
import { tokenStorage } from "src/token";
import { Menu, MenuButton, Popup, x } from "@maintmark/shared-web/src/ui";
import { data } from "@maintmark/shared-react";
import cuid from "cuid";
import { NavMenu } from "./NavMenu";
import * as actions from "../../actions/order";

const Logo = () => {
  return (
    <x.div row f24 p900 opacity80>
      <x.div bold>{t("maint")}</x.div>
      <x.div regular>{t("mark")}</x.div>
    </x.div>
  );
};

interface Props {
  onOpenChat: () => void;
}

export const NavBar = (props: x.divProps & Props) => {
  const signOut = React.useCallback(() => {
    tokenStorage.set({
      token: null,
    });
  }, []);

  const renderAccountMenu = React.useCallback(
    (close: () => void) => (
      <Menu mt5p pv1>
        <MenuButton lg>{t("My profile")}</MenuButton>
        <MenuButton
          lg
          onClick={() => {
            signOut();
            close();
          }}
        >
          {t("Sign out")}
        </MenuButton>
      </Menu>
    ),
    [signOut]
  );

  const templates = data.templates.useStore((store) => store.all(), []);

  const createOrder = actions.useCreate();

  const renderCreateMenu = React.useCallback(
    (close: () => void) => (
      <Menu mt5p pv5p mr1>
        <MenuButton
          onClick={() => {
            createOrder();
            close();
          }}
        >
          {t("Empty work order")}
        </MenuButton>
        <x.div mv5p h1p bggray200 />
        {templates.map((template) => (
          <MenuButton
            onClick={() => {
              createOrder({
                ...template.order,
                id: cuid(),
              });
              close();
            }}
          >
            {t(template.order.name)}
          </MenuButton>
        ))}
      </Menu>
    ),
    [templates]
  );

  function renderCreateButton() {
    return (
      <Popup renderContent={renderCreateMenu} align="right">
        <x.div opacity70 opacity100="hover" pointer="hover" mr1>
          <x.img src={AddImg} width={40} height={40} />
        </x.div>
      </Popup>
    );
  }

  return (
    <x.div pv24p ph48p row {...props} centers noselect>
      <Logo />
      <x.div pl3>
        <NavMenu />
      </x.div>
      <x.div flex />

      <Search
        ml2
        onChange={() => {}}
        p900
        mr20p
        placeholderColor={ui.theme.colors.primary[900]}
        mr1
      />
      {renderCreateButton()}
      <x.div
        mr1
        opacity70
        opacity100="hover"
        pointer="hover"
        onClick={props.onOpenChat}
      >
        <x.img src={MarkImg} width={40} height={40} pointer />
      </x.div>
      <x.div opacity70 opacity100="hover" pointer="hover">
        <Popup renderContent={renderAccountMenu} align="right">
          <x.img src={AccountImg} width={40} height={40} pointer />
        </Popup>
      </x.div>
    </x.div>
  );
};
