import * as React from "react";
import { Dashboard, t, time, Workflow } from "@maintmark/shared";
import { ModalProps } from "@maintmark/shared-react/src/ui/modals";
import {
  Form,
  FormFooter,
  FormHeader,
  TextInput,
  x,
} from "@maintmark/shared-web/src/ui";
import { Button } from "../components";
import cuid from "cuid";
import { useContext } from "@maintmark/shared-react";

export const DashboardForm = (props: ModalProps<Dashboard | "cancel">) => {
  const [name, setName] = React.useState("");
  const valid = name.trim().length > 0;
  const { user } = useContext();

  return (
    <x.div
      fill
      column
      centers
      centerp
      overlay
      onClick={() => props.onClose("cancel")}
    >
      <Form
        w500p
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <FormHeader>
          <x.div f16>{t("Create dashboard")}</x.div>
        </FormHeader>

        <x.div ph2 mt20p mb2 row>
          <TextInput
            name={t("Name")}
            value={name}
            onUpdate={setName}
            flex
            autoFocus
            mr1
          />
        </x.div>
        <FormFooter>
          <x.div
            p900
            h35p
            w120p
            row
            centers
            centerp
            opacity80
            opacity100="hover"
            f14
            pointer
            onClick={() => props.onClose("cancel")}
          >
            {t("Cancel")}
          </x.div>
          <x.div flex />
          <Button
            disabled={!valid}
            onClick={() => {
              props.onClose({
                id: cuid(),
                name,
                charts: [],
                __typename: "Dashboard",
                createdAt: time.now(),
                createdBy: user,
              });
            }}
          >
            {t("Create")}
          </Button>
        </FormFooter>
      </Form>
    </x.div>
  );
};
