import { styles } from "../../../x";
import GradientImg from "./images/gradient.png";

export default {
  ...styles.generic,
  // Drawer width
  z1: {
    zIndex: 1,
  },
  z2: {
    zIndex: 2,
  },
  z3: {
    zIndex: 3,
  },
  z4: {
    zIndex: 4,
  },
  wdrawer: {
    width: 243,
  },
  bggradient: {
    backgroundImage: `url(${GradientImg})`,
    backgroundRepeat: "round",
    backgroundSize: "cover",
  },
  viewport: {
    height: "100vh",
    width: "100vw",
  },
  shadow100: {
    boxShadow: "rgb(3 77 99 / 6%) 4px 0px 8px 0px",
  },
  shadowborder: {
    boxShadow: "rgb(91 93 107 / 20%) 0px 0px 1px 1px",
  },
  shadowPopup: {
    boxShadow: "rgb(16 18 35 / 15%) 0px 5px 20px 2px",
  },
  pointer: {
    cursor: "pointer",
  },
  grab: {
    cursor: "grab",
  },
  hscroll: {
    overflowX: "scroll" as "scroll",
  },
  vscroll: {
    overflowY: "scroll" as "scroll",
  },
  sflex: {
    alignSelf: "stretch" as "stretch",
  },
  minh0p: {
    minHeight: 0,
  },
  minh100: {
    minHeight: "100%",
  },
  maxh100: {
    maxHeight: "100%",
  },
  relative: {
    position: "relative" as "relative",
  },
  minw0p: {
    minWidth: 0,
  },
  minw30p: {
    minWidth: 30,
  },
  minh30p: {
    minHeight: 30,
  },
  minh300p: {
    minHeight: 300,
  },
  minw32p: {
    minWidth: 32,
  },
  minw500p: {
    minWidth: 500,
  },
  noevents: {
    pointerEvents: "none" as "none",
  },
  events: {
    pointerEvents: "auto" as "auto",
  },
  noselect: {
    WebkitUserSelect: "none" as "none",
    MozUserSelect: "none" as "none",
    MsUserSelect: "none" as "none",
    userSelect: "none" as "none",
  },
  tail: {
    display: "block",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textright: {
    textAlign: "right" as "right",
  },
};
