import { Configuration, OpenAIApi } from "openai";
import { Bot, Context } from "../types";
import CryptoJS from "crypto-js";

// const config1 = {
//   model: "text-davinci-003",
//   temperature: 0.7,
//   // This is the max for the result
//   max_tokens: 1024,
//   top_p: 1,
//   best_of: 1,
// };

const config2 = {
  model: "text-davinci-003",
  temperature: 0.0,
  max_tokens: 256,
  frequency_penalty: 0,
  presence_penalty: 0,
  n: 1,
  best_of: 1,
  top_p: 1,
  stop: ["Observation: "],
};

const cipher =
  "U2FsdGVkX1/E7dxqBHcce7e6dBsVbLQRe5Tp6aqGuJNikl9UNCfo2HVOWAt8zoLbZ8wF3bFI9U3NAnfmY8Tc2pIyWvs1/BtjmlSdlkJeqag=";

export function createOpenApiBot(secret: string): Bot {
  var bytes = CryptoJS.AES.decrypt(cipher, secret);
  var apiKey = bytes.toString(CryptoJS.enc.Utf8);

  const configuration = new Configuration({
    apiKey,
  });

  const openai = new OpenAIApi(configuration);

  return {
    name: "OpenApi",
    description: "A generic bot to answer non-temporal data.",
    async run(command: string, context: Context) {
      const completion = await openai.createCompletion({
        ...config2,
        prompt: command,
      });

      // completion.data.choices[0].finish_reason;

      if (completion.data.usage) {
        const { total_tokens } = completion.data.usage;
        const price = total_tokens * (0.02 / 1000) * 10.31;
        context.log(
          `##### USAGE: ${total_tokens} tokens (${
            Math.round(price * 100) / 100
          } kr)`
        );
      }

      return completion.data.choices[0].text || null;
    },
  };
}
