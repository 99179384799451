export interface Color {
  50?: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const primary: Color = {
  50: "#F3F4F8",
  100: "#D4EFE5",
  200: "#BEE7D7",
  300: "#A9DFCA",
  400: "#93D7BD",
  500: "#7DD0B0",
  600: "#68C8A3",
  700: "#52C095",
  800: "#3DB888",
  // 900: "#034D63",
  900: "#09291D",
};

export const gray: Color = {
  50: "#F9F9FB",
  100: "#F3F4F8",
  200: "#E3E4E8",
  300: "#B3B5BD",
  400: "#9496A1",
  500: "#777986",
  600: "#5B5D6B",
  700: "#404252",
  800: "#282A3A",
  900: "#101223",
};

export const alert: Color = {
  100: "",
  200: "",
  300: "",
  400: "",
  500: "",
  600: "#F71808",
  700: "",
  800: "",
  900: "",
};

export const marker: Color = {
  100: "",
  200: "#FEF2E6",
  300: "",
  400: "",
  500: "",
  600: "#F77B08",
  700: "",
  800: "",
  900: "#FF7A00",
};

export const common = {
  white: "#fff",
  black: "#000",
  overlay: "rgba(0,0,0,0.25)",
};
