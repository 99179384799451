import { constants } from "@maintmark/shared/src/ui/theme";
import _ from "lodash";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { DroppableList } from "../components/DroppableList";
import { DropTarget } from "../droppable";
import { x } from "@maintmark/shared-web/src/ui";
import { KanbanItem } from "./types";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

interface HeaderProps {
  title?: string;
  subTitle?: string;
}

const Header = (props: React.PropsWithChildren<HeaderProps>) => {
  return (
    <x.div centers centerp white ph1 mb20p p900 bbp800 noselect>
      <x.div flex mr1 opacity60 f12 uppercase>
        {props.title}
      </x.div>
      <x.div bold f24 opacity70 mb8p>
        {props.subTitle}
      </x.div>
    </x.div>
  );
};

interface Props<T extends KanbanItem> {
  items: T[];
  ItemComponent: React.ComponentType<{
    item: T;
  }>;
  target: DropTarget<"kanban">;
}

const grid = constants.gridSize;

const itemStyle: CSSProperties = {
  marginBottom: `${grid}px`,
};

export const KanbanLane = styled(
  <T extends KanbanItem>(props: Props<T> & HeaderProps & x.divProps) => {
    const { title, subTitle, target, ...other } = props;
    return (
      <x.div flex {...other} minw0p column>
        <Header title={title} subTitle={subTitle} />

        <DroppableList
          target={target}
          ItemComponent={props.ItemComponent}
          items={props.items}
          itemStyle={itemStyle}
        />
      </x.div>
    );
  }
)`
  max-width: 260px;
`;
