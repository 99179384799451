import { addListener, EventResult } from "../events";
import { Socket } from "../socket";
import { StoreEvent } from "./types";

function encode<T>(event: StoreEvent) {
  return JSON.stringify(event);
}

function decode<T>(data: any): StoreEvent | null {
  const obj = JSON.parse(data);
  if ("op" in obj) {
    return obj as StoreEvent;
  } else {
    return null;
  }
}

export function pubToSocket(socket: Socket, event: StoreEvent) {
  socket.send(encode(event));
}

export function subToSocket(
  socket: Socket,
  onEvent: (event: StoreEvent) => void
) {
  return addListener(socket.onMessage, (data) => {
    const event = decode(data);
    if (event) {
      onEvent(event);
      return EventResult.Handled;
    }
    return EventResult.NotHandled;
  });
}
