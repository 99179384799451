import React from "react";
import { x } from "@maintmark/shared-web/src/ui";

export const FormHeader = (props: React.PropsWithChildren<{}>) => {
  return (
    <x.div h60p row centers ph2 p900 bbgray200 bgwhite>
      {props.children}
    </x.div>
  );
};
