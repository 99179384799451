import React from "react";
import { ui } from "@maintmark/shared";
import { useFocus } from "../../observable";
import { x } from "../../";
import styled from "styled-components";
import { Label } from "./Label";

interface Props {
  name?: string;
  value: string;
  onUpdate: (value: string) => void;
  focus?: boolean;
}

export const TextInput = styled(
  (
    props: Props &
      x.divProps &
      Pick<x.inputProps, "autoFocus" | "type" | "placeholder">
  ) => {
    const {
      name,
      focus,
      autoFocus = props.focus,
      type,
      placeholder,
      value,
      onUpdate,
      defaultValue,
      ...other
    } = props;

    const ref = React.useRef<HTMLInputElement>(null);

    const [hasFocus, setFocus, setBlur] = useFocus();

    const change = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onUpdate(event.target.value);
      },
      [onUpdate]
    );

    React.useEffect(() => {
      if (focus) {
        if (ref.current) {
          ref.current.focus();
        }
      }
    }, [focus]);

    return (
      <x.div column {...other} minw0p>
        {props.name && <Label active={hasFocus}>{props.name}</Label>}
        <x.input
          ref={ref}
          onFocus={setFocus}
          onBlur={setBlur}
          onChange={change}
          h40p
          f14
          p900
          bgray200
          br6p
          ph1
          defaultValue={defaultValue}
          value={value}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholder}
          bgwhite
        />
      </x.div>
    );
  }
)`
  & input:focus {
    border-color: ${ui.theme.colors.gray[300]} !important;
  }
`;
