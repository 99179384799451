import React from "react";
import { Droppable as DroppableCmp, DroppableProps } from "react-beautiful-dnd";
import { DropTarget } from "../droppable";

interface Props extends Omit<DroppableProps, "droppableId"> {
  target: DropTarget<any>;
}

export const Droppable = (props: Props) => {
  const { target, ...other } = props;
  const [enabled, setEnabled] = React.useState(false);

  const droppableId = React.useMemo(() => JSON.stringify(target), [target]);

  React.useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <DroppableCmp droppableId={droppableId} {...other}>
      {props.children}
    </DroppableCmp>
  );
};
