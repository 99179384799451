import {
  ChatMessage,
  Order,
  OrderUrgency,
  Prompt,
  time,
} from "@maintmark/shared";
import React from "react";
import cuid from "cuid";
import { createOpenApiBot } from "./infrastructure/bots/openapi";
// import { createSerpApiBot } from "./infrastructure/bots/serpapi";
import { createContext } from "./infrastructure/context";
import { Context } from "./infrastructure/types";
import { createSqlBot } from "./infrastructure/bots/sql";
import dateParser from "any-date-parser";
const monthDay = require("any-date-parser/src/formats/monthDay/monthDay.js");

dateParser.removeFormat(monthDay);
// import { createSerpApiBot } from "./infrastructure/bots/serpapi-reset";

import initSqlJs from "./sql-wasm.js";
import sqlWasm from "./sql-wasm.wasm";
import { data, useContext } from "@maintmark/shared-react";

function createFunction(
  name: string,
  title: string,
  example: any,
  run: (cmd: string, context: Context) => Promise<string>
) {
  return {
    description: `${title}. Input should be in JSON format in the following format, example: ${example}`,
    name,
    run,
  };
}

function getCount(db: any) {
  const [
    {
      values: [[count]],
    },
  ] = db.exec("SELECT count(*) FROM orders");
  return count;
}

function useAssistantContext(secret: string) {
  const orders = data.orders.useStore((s) => s.all(), []);
  const { user } = useContext();

  const context = React.useMemo(
    () =>
      createContext(
        createOpenApiBot(secret),
        [
          // createSerpApiBot("google"),
          {
            description:
              "Convert date and time to unix time. The Action Input should be a date/time.",
            name: "datetime",
            async run(cmd: string) {
              console.log(
                ">>>>>>>>>>>>>>> date",
                dateParser.attempt(cmd, "sv-se")
              );

              const parsed = dateParser.attempt(cmd, "sv-se");

              const date = new Date();
              // first setting day, then year, then month
              // to handle months with fewer days and years without Feb 29
              if (typeof parsed.day === "number") {
                date.setUTCDate(parsed.day);
              }
              if (typeof parsed.year === "number") {
                date.setUTCFullYear(parsed.year);
              }
              if (typeof parsed.month === "number") {
                date.setUTCMonth(parsed.month - 1);
              }
              // default to first unit for time components
              date.setUTCHours(parsed.hour || 0);
              date.setUTCMinutes(parsed.minute || 0);
              date.setUTCSeconds(parsed.second || 0);
              date.setUTCMilliseconds(parsed.millisecond || 0);
              if (typeof parsed.offset === "number") {
                return new Date(
                  date.valueOf() - parsed.offset * 60 * 1000
                ).toString();
              }

              return date.valueOf().toString();
            },
          },
          createSqlBot(
            {
              describe() {
                return "Current year is 2023.";
              },
              async run(cmd: string) {
                const SQL = await initSqlJs({ locateFile: () => sqlWasm });
                const db = new SQL.Database();
                db.exec(
                  "CREATE TABLE orders (id TEXT, name TEXT, startsAt NUMBER, assignedToUserId TEXT, assetId TEXT, description TEXT, tag NUMBER)"
                );

                orders.forEach((o) => {
                  const { id, name, startsAt } = o;
                  db.exec(
                    "INSERT INTO orders (id, name, startsAt, description, tag) VALUES ($id, $name, $startsAt, $description, $tag)",
                    {
                      $id: id,
                      $name: name,
                      $startsAt: startsAt || null,
                      $tag: 1,
                    }
                  );
                });

                try {
                  const count = getCount(db);
                  let result = db.exec(cmd);
                  if (getCount(db) > count) {
                    const [row] = db.exec(
                      "SELECT * FROM orders WHERE tag IS NULL"
                    );

                    result = row.values.map((values: any) => {
                      const black = new Set(["tag", "id"]);
                      const props = row.columns.reduce(
                        (r: any, col: string, index: number) => {
                          if (black.has(col)) {
                            return r;
                          }
                          return {
                            ...r,
                            [col]: values[index],
                          };
                        },
                        {}
                      );

                      let order: Order = {
                        __typename: "Order",
                        id: cuid(),
                        comments: [],
                        description: "",
                        name: "",
                        number: "83882",
                        plannedTime: 0,
                        reportedTime: [],
                        urgency: OrderUrgency.Normal,
                        createdAt: time.now(),
                        startsAt: time.timeAt(props.startsAt),
                        assignedTo: user,
                        subOrders: [],
                        components: [],
                        fields: {
                          type: "fields",
                          fields: [],
                        },
                        tabs: {
                          type: "tabs",
                          tabs: [],
                        },
                      };

                      order = {
                        ...order,
                        ...props,
                      };

                      data.orders.insert(order);

                      return order;
                    });
                  }
                  return JSON.stringify(result);
                } catch (error) {
                  console.log(">>>>>>>>>>>> ERROR", error);
                  throw error;
                }
              },
            },
            `
              TABLE: orders
              COLUMNS:
                id                TEXT NOT NULL       // Do not set on INSERT
                name              TEXT NOT NULL
                description       TEXT NOT NULL       // Optional, only set if specified.
                startsAt          NUMBER DEFAULT NULL   // Optional, only set if specified. Milliseconds since epoch. Current year is 2023.
                assignedToUserId  TEXT DEFAULT NULL   // Optional, only set if specified
                assetId           TEXT DEFAULT NULL   // Optional, only set if specified


              EXAMPLES:
                {
                  name: "Empty the bin",
                  startsAt: null,
                  assignedToUserId: "kkasdkjg2309123",
                  assetId: "a234jnk23h4wf9"
                }

            `
            // JSON.stringify({
            //   tableName: "orders",
            //   columns: [
            //     {
            //       columnName: "id",
            //       columnType: "TEXT NOT NULL",
            //       columnFormat: "Should not used on INSERT.",
            //     },
            //     {
            //       columnName: "title",
            //       columnType: "TEXT NOT NULL",
            //     },
            //     {
            //       columnName: "startsAt",
            //       columnType: "TEXT DEFAULT NULL",
            //       columnFormat: "NULL if date is not set.",
            //     },
            //     {
            //       columnName: "assignedToId",
            //       columnType: "TEXT DEFAULT NULL",
            //       columnFormat: "NULL if not assigned to anyone.",
            //     },
            //     {
            //       columnName: "assetId",
            //       columnType: "TEXT DEFAULT NULL",
            //       columnFormat: "NULL if not assigned to any asset.",
            //     },
            //   ],
            // })
          ),
          // createFunction(
          //   "create-work-order",
          //   "Action to create work order.",
          //   JSON.stringify({
          //     title: "the title of the work order",
          //     date: "the date of the work order in YYYY-MM-DD format",
          //     userId: "a user the work order is assigned to",
          //   }),
          //   async (cmd, context) => {
          //     return "Work order has been created.";
          //   }
          // ),
          // createFunction(
          //   "read-work-order",
          //   "Action to read a work order based on filter.",
          //   JSON.stringify({
          //     title: "the title of the work order",
          //     date: "the date of the work order in YYYY-MM-DD format",
          //     userId: "a user the work order is assigned to",
          //   }),
          //   async (cmd, context) => {
          //     return "Work order has been created.";
          //   }
          // ),
          // {
          //   description: "Format duration for final result.",
          //   name: "duration-formatter",
          //   async run(cmd: string, context: Context) {
          //     return (
          //       (Number.parseInt(cmd, 10) / 1000 / 60).toString() + " minutes"
          //     );
          //   },
          // },
          // {
          //   description: "Format time and dates for final result.",
          //   name: "date-formatter",
          //   async run(cmd: string, context: Context) {
          //     return new Date(cmd).toString();
          //   },
          // },
        ],
        console.log
      ),
    []
  );

  return context;
}

export function useAssistant(
  secret: string
): [(text: string) => ChatMessage, ChatMessage[], Prompt[], boolean] {
  const messages = data.messages.useStore((store) => store.all(), []);
  const prompts = data.prompts.useStore((store) => store.all(), []);

  const { user } = useContext();

  const context = useAssistantContext(secret);

  const send = React.useCallback(
    (text: string) => {
      const id = cuid();
      const message: ChatMessage = {
        id,
        senderUserId: user.id,
        text,
        read: false,
      };

      data.messages.insert(message);

      // await wait(400);

      // const response = await context.run(message.text);

      // setMessages((msgs) =>
      //   produce(msgs, (draft) => {
      //     draft.forEach((item) => {
      //       if (item.id === id) {
      //         item.pending = false;
      //       }
      //     });

      //     draft.push({
      //       senderUserId: "",
      //       text: response || "I wasn't able to complete that.",
      //       pending: false,
      //       id: cuid(),
      //     });
      //   })
      // );

      return message;
    },
    [user.id]
  );

  return [send, messages, prompts, prompts.some((p) => !p.completedAt)];
}
