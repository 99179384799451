import { DateTime, Order, store, time } from "@maintmark/shared";
import { ChangeEvent, Operation } from "@maintmark/shared/src/store";
import { colors } from "@maintmark/shared/src/ui/theme";
import produce from "immer";
import _ from "lodash";
import { DayData, HeatmapData, MonthData, WeekData } from "./types";

interface Position {
  monthIndex: number;
  weekIndex: number;
  dayIndex: number;
}

function getPosition(date: DateTime, startOfYear: DateTime): Position | null {
  const monthIndex = time.differenceInCalendarMonths(date, startOfYear);
  if (monthIndex < 0 || monthIndex > 11) {
    return null;
  }

  const startOfMonth = time.addMonths(startOfYear, monthIndex);
  const weekIndex = time.differenceInCalendarWeeks(date, startOfMonth);
  const dayIndex = time.differenceInCalendarDays(
    date,
    time.startOfWeek(time.addWeeks(startOfMonth, weekIndex))
  );

  return {
    monthIndex,
    weekIndex,
    dayIndex,
  };
}

function getOriginalDate(event: ChangeEvent<Order>) {
  switch (event.change.op) {
    case Operation.Insert:
    case Operation.Delete: {
      return event.change.item.startsAt;
    }
    case Operation.Update: {
      return (event as store.UpdateChangeEvent<Order>).prev.startsAt;
    }
  }
}

export const nullDayData: DayData = {
  color: colors.common.white,
  orders: [],
  selected: false,
};

function mergeDay(
  week: WeekData,
  dayIndex: number,
  callback: (day: DayData) => DayData
) {
  const data = week[dayIndex];
  week[dayIndex] = data
    ? callback(data)
    : callback({
        color: "",
        orders: [],
        selected: false,
      });
}

const palette = [
  colors.primary[300],
  colors.primary[400],
  colors.primary[500],
  colors.primary[600],
  colors.primary[700],
  colors.primary[800],
];

function getColor(value: number, max: number) {
  if (max === 1) {
    return palette[1];
  }
  const index = Math.round(((value - 1) / (max - 1)) * (palette.length - 1));
  return palette[index];
}

export function updateColors(months: MonthData[], max: number) {
  months.forEach((weeks) => {
    weeks.forEach((days) => {
      days.forEach((day) => {
        if (day) {
          if (day.orders.length === 0) {
            day.color = colors.common.white;
          } else {
            const count = day.orders.length || 0;
            day.color = getColor(count, max);
          }
        }
      });
    });
  });
}

export function handleChange(
  data: HeatmapData,
  event: ChangeEvent<Order>,
  startOfYear: DateTime
): HeatmapData {
  const { op } = event.change;
  if (
    op !== Operation.Update &&
    op !== Operation.Delete &&
    op !== Operation.Insert
  ) {
    return data;
  }

  const startsAt = getOriginalDate(event);
  if (!startsAt) {
    return data;
  }

  const pos = getPosition(startsAt, startOfYear);
  if (!pos) {
    return data;
  }

  return produce(data, (draft) => {
    switch (op) {
      case Operation.Delete: {
        const day = draft.months[pos.monthIndex][pos.weekIndex][pos.dayIndex];
        if (!day) {
          return;
        }

        const { item } = event.change;
        day.orders = day.orders.filter((order) => order.id !== item.id);
        day.color = getColor(day.orders.length, draft.max);
        break;
      }

      case Operation.Update: {
        const day = draft.months[pos.monthIndex][pos.weekIndex][pos.dayIndex];
        if (!day) {
          return;
        }

        const { item, prev } = event as store.UpdateChangeEvent<Order>;

        if (prev.startsAt === item.startsAt) {
          day.orders = day.orders.map((order) => {
            if (order.id !== item.id) {
              return order;
            } else {
              return item;
            }
          });
        } else {
          day.orders = day.orders.filter((order) => order.id !== item.id);
          day.color = getColor(day.orders.length, draft.max);

          if (item.startsAt) {
            const newPos = getPosition(item.startsAt, startOfYear);
            if (newPos) {
              const newWeek = draft.months[newPos.monthIndex][newPos.weekIndex];

              mergeDay(newWeek, newPos.dayIndex, (day) => {
                day.orders.push(item);
                day.color = getColor(day.orders.length, draft.max);
                return day;
              });

              draft.max = Math.max(
                draft.max,
                newWeek[newPos.dayIndex]!.orders.length
              );
            }
          }
        }
        break;
      }
      case Operation.Insert: {
        const week = draft.months[pos.monthIndex][pos.weekIndex];
        const { item } = event.change;

        mergeDay(week, pos.dayIndex, (day) => {
          day.orders.push(item);
          day.color = getColor(day.orders.length, draft.max);
          return day;
        });

        draft.max = Math.max(draft.max, week[pos.dayIndex]!.orders.length);
        break;
      }
    }

    if (draft.max > data.max) {
      updateColors(draft.months, draft.max);
    }
  });
}

export function createData(orders: Order[], startOfYear: DateTime) {
  const result: HeatmapData = {
    max: 0,
    months: _.range(0, 12).map((month) => {
      const numWeeks = time.getWeeksInMonth(time.addMonths(startOfYear, month));
      return _.range(0, numWeeks).map(() => []);
    }),
  };

  let max: number = 0;

  orders.forEach((order) => {
    const { startsAt } = order;

    if (!startsAt) {
      return;
    }

    const date = time.startOfDay(startsAt);
    const pos = getPosition(date, startOfYear);

    if (!pos) {
      return;
    }

    mergeDay(
      result.months[pos.monthIndex][pos.weekIndex],
      pos.dayIndex,
      (day) => {
        max = Math.max(day.orders.length, max);

        return {
          ...day,
          orders: [...day.orders, order],
        };
      }
    );

    result.max = max;
  });

  console.log(">>>>>>>>> MAX", result.max);

  updateColors(result.months, result.max);

  return result;
}

function iterateWeek(
  data: HeatmapData,
  date: DateTime,
  startOfYear: DateTime,
  callback: (week: WeekData, dayIndex: number) => void
) {
  _.range(0, 7).forEach((dayIndex) => {
    const d = time.addDays(date, dayIndex);
    const p = getPosition(d, startOfYear);

    if (p) {
      const week = data.months[p.monthIndex][p.weekIndex];
      callback(week, p.dayIndex);
    }
  });
}
