import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import { Spinner } from "./Spinner";

export interface ButtonProps extends x.divProps {
  index?: number;
  total?: number;
  disabled?: boolean;
  busy?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { index = 0, total = 1, disabled, busy, children, ...other } = props;

  return (
    <x.div
      h35p
      br6p
      bl0p={index > 0}
      brr0p={total > 0 && index < total - 1}
      brl0p={index > 0}
      w120p
      bgwhite
      bggray100="hover"
      row
      centerp
      centers
      p900
      bgray300
      f14
      pointer
      noselect
      dimmed={disabled}
      noevents={disabled || busy}
      {...other}
    >
      {busy ? <Spinner /> : children}
    </x.div>
  );
};
