import {
  ChartAggregate,
  ChartDataSource,
  FieldDef,
  FieldType,
  time,
  XYChart,
} from "@maintmark/shared";
import { data } from "@maintmark/shared-react";
import { Item, ItemType } from "@maintmark/shared/src/store";
import _ from "lodash";

export interface ChartData {
  data: { [key: string]: any }[];
  xField: FieldDef<any>;
  yFields: FieldDef<any>[];
}

function useItems(source: ChartDataSource) {
  switch (source) {
    case "Order": {
      return data.orders.useStore((store) => store.all(), []);
    }
    case "TimeReport": {
      return [];
    }
  }
}

function getValue<T extends ItemType>(
  item: Item<T>,
  field: FieldDef<any>
): number {
  return (item as any)[field.id] as number;
}

function reduce<T extends Item<any>>(
  aggregate: ChartAggregate,
  yField: FieldDef<T>,
  groups: _.Dictionary<T[]>
) {
  switch (aggregate) {
    case ChartAggregate.Average: {
      return _.map(groups, (group) => {
        const values = _.map(group, (group) => group[yField.id]);
        return _.sum(values) / values.length;
      });
    }
    case ChartAggregate.Count: {
      return _.map(groups, (group) => {
        return group.length;
      });
    }
    case ChartAggregate.Max: {
      return _.map(groups, (group) => {
        const values = _.map(group, (group) => group[yField.id]);
        return _.max(values);
      });
    }
    case ChartAggregate.Min: {
      return _.map(groups, (group) => {
        const values = _.map(group, (group) => group[yField.id]);
        return _.min(values);
      });
    }
    case ChartAggregate.Sum: {
      return _.map(groups, (group) => {
        const values = _.map(group, (group) => group[yField.id]);
        return _.sum(values);
      });
    }
    case ChartAggregate.Accumulate: {
      const keys = _.keys(groups);
      const result = new Array<number>(keys.length);
      keys.forEach((key, index) => {
        const group = groups[key];
        const value = _.sum(_.map(group, (group) => getValue(group, yField)));
        result[index] = index > 0 ? value + result[index - 1] : value;
      });
      return result;
    }
  }
}

function formatValue(field: FieldDef<any>, value: any) {
  switch (field.type) {
    case FieldType.Date: {
      return time.timeAt(value);
    }
  }
}

function getGroups<T extends ItemType>(
  items: Item<T>[],
  xField: FieldDef<any>
) {
  return _.chain(items)
    .filter((item: any) => item[xField.id] !== undefined)
    .sortBy((item: any) => getValue(item, xField))
    .groupBy((item: any) => item[xField.id])
    .value();
}

export function useXYData(chart: XYChart<any>): ChartData {
  const items = useItems(chart.source);

  const xField = data.fieldDefs.useItem(chart.xAxis.fieldId);
  const yField = data.fieldDefs.useItem(chart.yAxis.fieldId);

  const groups = getGroups(items, xField);
  const yValues = reduce(chart.yAxis.aggregate, yField, groups);

  return {
    data: _.keys(groups).map((xValue, index) => ({
      [xField.id]: xValue,
      [yField.id]: yValues[index],
    })),
    xField,
    yFields: [yField],
  };
}
