import _ from "lodash";
import { produce, Draft } from "immer";
import { DateTime, User } from "../types";
import {
  addListener,
  createEvent,
  emit,
  EventDef,
  EventListener,
  EventResult,
} from "../events";
import { time } from "../time";
import { diff, Diff, applyChange } from "deep-diff";

export type ItemType =
  | "Order"
  | "FieldDef"
  | "Group"
  | "Asset"
  | "User"
  | "Filter"
  | "TimeReport"
  | "Comment"
  | "WeekKanban"
  | "TabDef"
  | "Workflow"
  | "Prompt"
  | "ChatMessage"
  | "Template"
  | "Item"
  | "Chart"
  | "Dashboard";

export interface Item<T extends ItemType> {
  __typename?: T;
  id: string;
  createdAt?: number;
  createdBy?: User;
}

export interface ItemDraft<T> {
  update(props: Partial<T> | ((item: Draft<NonNullable<T>>) => void)): void;
  hasChanges(): boolean;
}

export type ItemOperation = "set" | "delete";

export enum Operation {
  NoOp,
  Delete,
  Update,
  Insert,
  Undo,
}

interface ChangeBase<O extends Operation> {
  op: O;
  createdAt: DateTime;
  createdById: string;
}

interface NoChange extends ChangeBase<Operation.NoOp> {}

export interface UpdateChange<T> extends ChangeBase<Operation.Update> {
  itemId: string;
  fwd: Diff<T, T>[];
  bwd: Diff<T, T>[];
}

export interface DeleteChange<T> extends ChangeBase<Operation.Delete> {
  item: T;
}

export interface InsertChange<T> extends ChangeBase<Operation.Insert> {
  item: T;
}

export interface UndoChange<T> extends ChangeBase<Operation.Undo> {
  change: Change<T>;
}

export type Change<T> =
  | UpdateChange<T>
  | DeleteChange<T>
  | InsertChange<T>
  | UndoChange<T>
  | NoChange;

export type UpdateArg<T> = Partial<T> | ((item: Draft<NonNullable<T>>) => void);

export type UpdateChangeEvent<T> = {
  remote?: boolean;
  change: UpdateChange<T>;
  store: Items<T>;
  item: T;
  prev: T;
};

export type DeleteChangeEvent<T> = {
  remote?: boolean;
  change: DeleteChange<T>;
  store: Items<T>;
};

export type InsertChangeEvent<T> = {
  remote?: boolean;
  change: InsertChange<T>;
  store: Items<T>;
};

export type ChangeEvent<T> =
  | UpdateChangeEvent<T>
  | DeleteChangeEvent<T>
  | InsertChangeEvent<T>;

export type ResetEvent<T> = {
  op: "reset";
  store: Items<T>;
  remote?: boolean;
};

export interface Items<T> {
  type: ItemType;
  change(change: Change<T>[], reset?: boolean): void;
  all(): T[];
  get(id: string): T;
  take(count: number): T[];
  has(id: string): boolean;

  upsert(items: T): Change<T> | null;
  insert(item: T): Change<T>;
  delete(item: { id: string } | string): Change<T> | null;
  update(id: string | T, data: UpdateArg<T>): Change<T> | null;
  revert(change: Change<T>): void;

  subscribe(
    listener: EventListener<ChangeEvent<T> | ResetEvent<T>>
  ): () => void;
  filter(predicate: (item: T) => boolean): T[];
  search(term: string): T[];

  changeEvent: EventDef<ChangeEvent<T>>;
}

interface ItemsOpts<T> {
  userId: string;
  initial?: T[];
  onUpdate?: (store: Items<T>, draft: Draft<T>) => Draft<T>;
  onInsert?: (store: Items<T>, item: Draft<T>) => Draft<T>;
  onDelete?: (store: Items<T>, item: T) => void;
  search?: (term: string, items: T[]) => T[];
}

const identityFunc =
  <T>() =>
  (store: Items<T>, item: Draft<T>): Draft<T> =>
    item;

export function createStore<R extends ItemType, T extends Item<R>>(
  type: R,
  opts: ItemsOpts<T>
): Items<T> {
  const {
    initial = [],
    onUpdate = identityFunc<T>(),
    onInsert = identityFunc<T>(),
    onDelete = () => {},
  } = opts;
  let _items = new Map<string, T>(initial.map((item) => [item.id, item]));

  const changeEvent = createEvent<ChangeEvent<T>>("store-change");
  const resetEvent = createEvent<ResetEvent<T>>("store-reset");

  const store = {
    type,
    change(changes: Change<T>[], reset?: boolean) {
      for (const change of changes) {
        switch (change.op) {
          case Operation.Update: {
            const prev = _items.get(change.itemId)!;
            const item = produce(prev, (draft) => {
              for (let patch of change.fwd) {
                applyChange(draft as T, null, patch);
              }
            });

            _items.set(change.itemId, item);

            if (!reset) {
              emit(
                changeEvent({
                  change,
                  store,
                  prev,
                  item,
                  remote: true,
                })
              );
            }
            break;
          }
          case Operation.Delete: {
            _items.delete(change.item.id);

            if (!reset) {
              emit(
                changeEvent({
                  change,
                  store,
                  remote: true,
                })
              );
            }
            break;
          }
          case Operation.Insert: {
            const { item } = change;
            _items.set(item.id, item);

            if (!reset) {
              emit(
                changeEvent({
                  change,
                  store,
                  remote: true,
                })
              );
            }
            break;
          }
          default: {
            return;
          }
        }
      }

      if (reset) {
        emit(
          resetEvent({
            op: "reset",
            store,
            remote: true,
          })
        );
      }
    },
    all() {
      return Array.from(_items.values());
    },
    get(id: string) {
      return _items.get(id)!;
    },
    take(count: number) {
      return Array.from(_items.values()).slice(0, count);
    },
    has(id: string) {
      return _items.has(id);
    },
    upsert(data: T): Change<T> | null {
      const prev = _items.get(data.id);
      if (prev) {
        return store.update(data.id, data);
      } else {
        return store.insert(data);
      }
    },
    insert(data: T): Change<T> {
      if (_items.has(data.id)) {
        throw new Error("Item already exists.");
      }

      const item = produce(data, (draft) => onInsert(store, draft));

      _items.set(item.id, item);

      const change: InsertChange<T> = {
        op: Operation.Insert,
        item,
        createdById: opts.userId,
        createdAt: time.now(),
      };

      emit(
        changeEvent({
          change,
          store,
        })
      );

      return change;
    },
    delete(item: { id: string } | string): Change<T> | null {
      const id = typeof item === "string" ? item : item.id;

      if (!_items.has(id)) {
        return null;
      }

      const toDelete = _items.get(id)!;

      const change: DeleteChange<T> = {
        op: Operation.Delete,
        item: toDelete,
        createdAt: time.now(),
        createdById: opts.userId,
      };

      onDelete(store, toDelete);

      _items.delete(id);

      emit(
        changeEvent({
          change,
          store,
        })
      );

      return change;
    },
    update(input: string | T, data: UpdateArg<T>): Change<T> | null {
      const id = typeof input === "string" ? input : input.id;

      if (!_items.has(id)) {
        return null;
      }

      const prev = _items.get(id)!;

      const item = produce(prev, (draft) => {
        if (typeof data === "function") {
          data(draft);
        } else {
          _.assign(draft, data);
        }
        onUpdate(store, draft);
      });

      const fwd = diff(prev, item);
      const bwd = diff(item, prev);

      if (!fwd || !bwd) {
        return null;
      }

      const change: UpdateChange<T> = {
        op: Operation.Update,
        itemId: id,
        fwd,
        bwd,
        createdById: opts.userId,
        createdAt: time.now(),
      };

      if (prev === item) {
        return change;
      }

      _items.set(item.id, item);

      emit(
        changeEvent({
          change,
          store,
          item,
          prev,
        })
      );

      return change;
    },
    revert(change: Change<T>) {
      switch (change.op) {
        case Operation.Update: {
          const item = _items.get(change.itemId)!;
          _items.set(
            item.id,
            produce(item, (draft) => {
              for (let patch of change.bwd) {
                applyChange(draft as T, null, patch);
              }
            })
          );
          break;
        }
        case Operation.Delete: {
          const { item } = change;
          _items.set(item.id, item);
          break;
        }
        case Operation.Insert: {
          _items.delete(change.item.id);
          break;
        }
        default: {
          return;
        }
      }
    },
    subscribe(listener: EventListener<ChangeEvent<T> | ResetEvent<T>>) {
      const subs = [
        addListener(changeEvent, listener),
        addListener(resetEvent, listener),
      ];
      return () => {
        subs.forEach((s) => s());
      };
    },
    filter(predicate: (item: T) => boolean): T[] {
      const result: T[] = [];
      for (let key of Array.from(_items.keys())) {
        const item = _items.get(key)!;
        if (predicate(item)) {
          result.push(item);
        }
      }
      return result;
    },
    search(term: string) {
      if (opts.search) {
        return opts.search(term, Array.from(_items.values()));
      }
      return [];
    },
    changeEvent,
  };
  return store;
}
