import React from "react";
import { EventResult } from "@maintmark/shared";
import { Draft, applyPatches, Patch, produceWithPatches } from "immer";
import { Item, Items } from "@maintmark/shared/src/store";
import cuid from "cuid";
import _ from "lodash";

export interface ReactStore<T extends Item<any>> extends Items<T> {
  useStore<R>(cb: (store: Items<T>) => R, deps: React.DependencyList): R;
  useItem(id: string): T;
  useDraft(
    id: string | T
  ): [
    Readonly<T>,
    (props: Partial<T> | ((item: Draft<T>) => void)) => void,
    boolean,
    () => void
  ];
}

export function withReact<T extends Item<any>>(store: Items<T>): ReactStore<T> {
  return {
    ...store,
    useStore<R>(cb: (store: Items<T>) => R, deps: React.DependencyList): R {
      const [value, setValue] = React.useState<R>(() => cb(store));
      const callback = React.useRef(cb);

      React.useEffect(() => {
        setValue(cb(store));
        callback.current = cb;
      }, deps);

      React.useEffect(() => {
        return store.subscribe(({ store }) => {
          setValue(callback.current!(store));
          return EventResult.Handled;
        });
      }, []);

      return value;
    },
    useItem(id: string) {
      return this.useStore((store) => store.get(id), [id]);
    },
    useDraft(id: string | T) {
      const orgItem =
        typeof id === "string"
          ? this.useStore((store) => store.get(id), [id])
          : React.useRef({
              ...id,
              id: null,
            }).current;

      const [item, setItem] = React.useState(orgItem);
      const { current: allPatches } = React.useRef<Patch[]>([]);

      React.useEffect(() => {
        if (allPatches.length > 0) {
          console.log(">>>>>>>>>>>> setting item 1", allPatches);
          setItem(applyPatches(orgItem as any, allPatches) as T);
        } else {
          console.log(">>>>>>>>>>>> setting item 2");
          setItem(orgItem);
        }
      }, [orgItem]);

      const updateDraft = React.useCallback(
        (props: Partial<T> | ((item: Draft<T>) => void)) => {
          const [next, patches] = produceWithPatches(item, (draft) => {
            if (typeof props === "function") {
              props(draft);
            } else {
              console.log(
                ">>>>>>>>> assign 1",
                props,
                (draft as any).reportedTime
              );
              _.assign(draft, props);
              console.log(">>>>>>>>> assign 2", (draft as any).reportedTime);
            }
          });

          console.log(
            ">>>>>>>>>>>>>>> update",
            (props as any).reportedTime,
            patches
          );

          allPatches.push(...patches);
          setItem(next);
        },
        [item]
      );

      const commit = React.useCallback(() => {
        if (item.id) {
          this.update(item.id, applyPatches(orgItem, allPatches));
        } else {
          const toAdd = {
            ...applyPatches(orgItem, allPatches),
            id: cuid(),
          };
          this.insert(toAdd);
          console.log(">>>>>>>>>>>> setting item 3");
          setItem(toAdd);
        }
      }, [item, orgItem]);

      console.log(">>>>>>>>>>>>> asdadasdasd", (item as any).reportedTime);

      return [item, updateDraft, allPatches.length > 0, commit] as [
        Readonly<T>,
        typeof updateDraft,
        boolean,
        () => void
      ];
    },
  };
}
