import { Context } from "@maintmark/shared";
import { data } from "./data";

export function useContext(): Context {
  const user = data.users.useStore((store) => store.all()[0], []);

  return {
    user,
  };
}
