import { Assignee, data, Order, t } from "@maintmark/shared";
import React from "react";
import { x } from "@maintmark/shared-web/src/ui";
import DownImg from "../../images/down-sm-gray300@3x.png";
import { Label, MenuButton, Popup } from "@maintmark/shared-web/src/ui";
import { useRecommendedAssignees } from "@maintmark/shared-react";
import { ComboBox } from "src/ui/components";

interface Props {
  order: Order;
}

const MenuItem = (props: {
  item: Assignee;
  onUpdate: (assignee: Assignee) => void;
}) => {
  const { item, onUpdate } = props;

  return <MenuButton onClick={() => onUpdate(item)}>{item.name}</MenuButton>;
};

const List = (props: {
  onUpdate: (assignee: Assignee | undefined) => void;
  order: Order;
}) => {
  const { onUpdate, order } = props;
  const [term, setTerm] = React.useState("");

  const recos = useRecommendedAssignees(order, 5);

  const itemProps = React.useMemo(() => {
    let assignees = term !== "" ? data.users.all() : data.users.search(term);
    return assignees.map((item) => ({
      item,
      onUpdate,
    }));
  }, [term, recos, onUpdate]);

  return (
    <ComboBox
      ItemComponent={MenuItem}
      itemProps={itemProps}
      onSearch={setTerm}
      w100
    />
  );
};

interface AssigneeInputProps extends Props {
  onUpdate: (assignee: Assignee | undefined) => void;
}

export const AssigneeInput = (props: AssigneeInputProps & x.divProps) => {
  const { onUpdate, order, ...other } = props;
  const { assignedTo } = order;
  const [open, setOpen] = React.useState(false);

  const renderDrop = React.useCallback(
    (close: () => void) => {
      return (
        <List
          order={order}
          onUpdate={(asset) => {
            close();
            onUpdate(asset);
          }}
        />
      );
    },
    [onUpdate, order]
  );

  return (
    <x.div column {...other} minw0p>
      <Label active={open}>{t("Assignee")}</Label>

      <Popup renderContent={renderDrop} onToggle={setOpen} fullwidth>
        <x.div
          h40p
          f14
          p900
          bgray200
          bgray300={open}
          br6p
          ph1
          pointer
          row
          centers
          relative
          bgwhite
        >
          {assignedTo?.name}
          <x.div right mr1>
            <x.img src={DownImg} width={10} height={7} />
          </x.div>
        </x.div>
      </Popup>
    </x.div>
  );
};
