import React from "react";
import { x } from "@maintmark/shared-web/src/ui";
import { ModalProps, Portal } from "@maintmark/shared-react/src/ui/modals";
import { opt } from "@maintmark/shared/src/utils";
import { usePortal } from "./portal";
import { createEvent, emit } from "@maintmark/shared";
import cuid from "cuid";
import { useResizeObserver } from "../observable";
import styled from "styled-components";

const openPopup = createEvent<string>("openPopup");

interface ContentProps {
  node: Element;
  align?: "left" | "right";
  fullwidth?: boolean;
}

const Content = styled(
  (
    props: React.PropsWithChildren<ContentProps> &
      ModalProps<never> &
      React.HTMLAttributes<any>
  ) => {
    const {
      node,
      children,
      align = "left",
      fullwidth = false,
      ...other
    } = props;

    const getStyle = React.useCallback(() => {
      const rect = node.getBoundingClientRect();
      const style: React.CSSProperties = {
        position: "absolute",
        top: rect.bottom,
        [align]: align === "right" ? window.innerWidth - rect.right : rect.left,
        ...opt(fullwidth, {
          width: rect.width,
        }),
      };

      return style;
    }, [node, align]);

    const [style, setStyle] = React.useState<React.CSSProperties>(getStyle());

    useResizeObserver(() => {
      setStyle(getStyle());
    }, [getStyle]);

    return (
      <x.div fill {...other}>
        <x.div fill onClick={props.onClose} />
        <x.div className="content" style={style}>
          {children}
        </x.div>
      </x.div>
    );
  }
)`
  @keyframes example {
    0% {
      opacity: 0;
      transform: scaleY(0.5) translateY(-50%);
    }
    100% {
      opacity: 1;
      transform: scaleY(1) translateY(0%);
    }
  }

  z-index: 3;

  & .content {
    animation: 80ms ease-out 0s 1 example;
  }
`;

interface Props {
  renderContent: (close: () => void) => React.ReactElement;
  onToggle?: (active: boolean) => void;
  align?: "right" | "left";
  fullwidth?: boolean;
  portal?: Portal;
}

export const Popup = (props: React.PropsWithChildren<Props & x.divProps>) => {
  const {
    renderContent,
    onToggle,
    align,
    fullwidth,
    portal: propsPortal,
    ...other
  } = props;
  const portal = propsPortal || usePortal();
  const popupId = React.useMemo(() => cuid(), []);
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!node) {
        return;
      }

      emit(openPopup(popupId));

      if (onToggle) {
        onToggle(true);
      }

      setIsOpen(true);

      await portal.showModal(
        (props: { onClose: () => void }) => (
          <Content
            node={node}
            onClose={props.onClose}
            align={align}
            fullwidth={fullwidth}
          >
            {renderContent(props.onClose)}
          </Content>
        ),
        {}
      );

      setIsOpen(false);

      if (onToggle) {
        onToggle(false);
      }
    },
    [renderContent, portal, node, align, fullwidth, onToggle, popupId]
  );

  return (
    <x.div
      ref={setNode}
      onClick={open}
      {...other}
      className={isOpen ? "open" : ""}
    >
      {props.children}
    </x.div>
  );
};
