import React from "react";
import { useMouseMove, useMouseUp } from "./mouse";

export function useDrag(
  onDrag: (pos: number) => void,
  pos: number,
  deps: React.DependencyList
): [
  (ref: HTMLDivElement | null) => void,
  (event: React.MouseEvent<HTMLDivElement>) => void,
  boolean
] {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
  const [dragOffset, setDragOffset] = React.useState<null | number>(null);

  useMouseUp(
    window,
    () => {
      setDragOffset(null);
    },
    []
  );

  useMouseMove(
    window,
    (event) => {
      if (!dragOffset) {
        return;
      }

      onDrag(event.clientX - dragOffset);
    },
    [...deps, dragOffset]
  );

  const activate = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!ref) {
        return;
      }
      const { left } = ref.getBoundingClientRect();

      const mouseOffset = event.clientX - left;

      setDragOffset(left - pos + mouseOffset);
    },
    [ref, pos]
  );

  return [setRef, activate, dragOffset !== null];
}
