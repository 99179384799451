import { theme } from "@maintmark/shared";
import styled from "styled-components";

export const Spinner = styled((props: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <span className="bg" />
    <span className="spinner" />
  </div>
))`
  width: 24px;
  height: 24px;

  & span {
    width: 17px;
    height: 17px;
    border: 4px solid;
    border-radius: 50%;
    position: absolute;
  }

  & .bg {
    border-color: ${theme.colors.primary[900]};
    opacity: 20%;
  }

  & .spinner {
    border-color: ${theme.colors.primary[900]} transparent transparent;
    animation: rotation 500ms linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
