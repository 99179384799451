import React from "react";
import { Menu, MenuButton, Popup, x } from "@maintmark/shared-web/src/ui";
import { t } from "@maintmark/shared";
import CheckImg from "../images/check@3x.png";
import _ from "lodash";
import { data } from "@maintmark/shared-react";
import { createField } from "src/actions/fields";

const CheckBox = (props: x.divProps & { active: boolean }) => {
  return (
    <x.div w1 h1 bgray200 bgwhite br3p {...props}>
      <x.img src={CheckImg} width={16} height={16} hidden={!props.active} />
    </x.div>
  );
};

const Option = (
  props: x.divProps & { active: boolean; onToggle: (active: boolean) => void }
) => {
  const { active, onToggle } = props;
  return (
    <MenuButton onClick={() => onToggle(!active)}>
      <x.div row>
        <CheckBox mr10p active={active} />
        {props.children}
      </x.div>
    </MenuButton>
  );
};

const Button = (props: x.divProps) => {
  return (
    <MenuButton onClick={props.onClick}>
      <x.div row>
        <x.div w1 mr10p />
        {props.children}
      </x.div>
    </MenuButton>
  );
};

type Props = { orderId: string } & x.divProps;
type AreaProps = Props & { onClose: () => void };

const Fields = (props: AreaProps) => {
  const order = data.orders.useItem(props.orderId);
  const { fields } = order.fields;
  const defs = data.fieldDefs.useStore((store) => store.all(), []);
  const groups = _.groupBy(defs, (f) => f.group.id);
  const lookup = new Set(fields.map((f) => f.def.id));

  return (
    <Menu md>
      <x.div pv10p>
        {Object.keys(groups).map((key, index) => (
          <>
            <x.div ml1 mt1={index > 0} mb5p uppercase p900 opacity40 f10>
              {groups[key][0].group.name}
            </x.div>

            {groups[key].map((field) => (
              <Option
                active={lookup.has(field.id)}
                onToggle={(active) => {
                  data.orders.update(order.id, {
                    fields: {
                      type: "fields",
                      fields: !active
                        ? fields.filter((f) => f.def.id !== field.id)
                        : [
                            ...fields,
                            {
                              def: field,
                              value: "",
                            },
                          ],
                    },
                  });
                }}
              >
                {field.name}
              </Option>
            ))}
          </>
        ))}
      </x.div>
      <x.div h1p bggray200 />
      <x.div pv10p>
        <Button
          onClick={async () => {
            props.onClose();

            const def = await createField();

            if (def === "cancel") {
              return;
            }

            data.fieldDefs.insert(def);

            data.orders.update(props.orderId, (draft) =>
              draft.fields.fields.push({
                def,
                value: "",
              })
            );
          }}
        >
          {t("Add custom field")}
        </Button>
      </x.div>
    </Menu>
  );
};

const Tabs = (props: AreaProps) => {
  const order = data.orders.useItem(props.orderId);
  const { tabs } = order.tabs;
  const defs = data.tabDefs.useStore((store) => store.all(), []);
  const lookup = new Set(tabs.map((f) => f.def.id));

  return (
    <Menu md>
      <x.div pv10p>
        {defs.map((field) => (
          <Option
            active={lookup.has(field.id)}
            onToggle={(active) => {
              data.orders.update(order.id, {
                tabs: {
                  type: "tabs",
                  tabs: !active
                    ? tabs.filter((f) => f.def.id !== field.id)
                    : [
                        ...tabs,
                        {
                          def: field,
                          value: "",
                        },
                      ],
                },
              });
            }}
          >
            {field.name}
          </Option>
        ))}
      </x.div>
    </Menu>
  );
};

export const Customize = (props: { orderId: string } & x.divProps) => {
  const [open, setOpen] = React.useState(false);

  const renderContent = React.useCallback(
    (close: () => void) => {
      return (
        <Menu mt1 sm pv10p md>
          <MenuButton
            icon="arrow"
            renderChild={() => <Fields {...props} onClose={close} />}
          >
            {t("Fields")}
          </MenuButton>
          <MenuButton
            icon="arrow"
            renderChild={() => <Tabs {...props} onClose={close} />}
          >
            {t("Tabs")}
          </MenuButton>
        </Menu>
      );
    },
    [props]
  );

  return <Popup renderContent={renderContent}>{props.children}</Popup>;
};
