import React from "react";
import * as paper from "paper";
import { createEvent, theme } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";

export interface NodeBase<T extends string> {
  type: T;
}

export interface LineNode extends NodeBase<"line"> {
  from: Element;
  to: Element | "mouse";
}

type Node = LineNode;

interface DrawContext {
  register(node: Node): void;
}

function create() {
  const redrawEvent = createEvent<void>("redraw");
  let nodes: Node[] = [];
  let mouseCallbacks: ((point: paper.Point) => void)[] = [];

  function draw(node: Node) {
    switch (node.type) {
      case "line": {
        const p1 = node.from.getBoundingClientRect();

        let path: paper.Path.Line | null = null;

        const drawLine = (to: paper.Point) => {
          const from = new paper.Point(p1.left, p1.top);
          path = new paper.Path();
          path.strokeColor = new paper.Color(theme.colors.gray[200]);
          path.strokeWidth = 16;

          var vector = new paper.Point({
            angle: 0,
            length: Math.abs(from.subtract(to).length) * 0.3,
          });

          path.segments = [
            new paper.Segment(
              from,
              undefined,
              vector.rotate(0, new paper.Point(0, 0))
            ),
            new paper.Segment(
              to,
              vector.rotate(-180, new paper.Point(0, 0)),
              vector
            ),
          ];
        };

        if (node.to === "mouse") {
          const onMouseMove = (event: any) => {
            if (path) {
              path.remove();
            }
            drawLine(event.point);

            // if (path) {
            //   path.segments[1].point = event.point;
            // }
          };
          mouseCallbacks.push(onMouseMove);
          return () => {
            mouseCallbacks = mouseCallbacks.filter((cb) => cb !== onMouseMove);
          };
        } else {
          const p2 = node.to.getBoundingClientRect();
          var to = new paper.Point(p2.left, p2.right);
          drawLine(to);
          return () => path?.remove();
        }
      }
    }
  }

  const DrawSheet = () => {
    const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);

    React.useEffect(() => {
      if (canvas) {
        // paper.install(window);
        paper.setup(canvas);

        paper.view.onMouseMove = (event: any) => {
          mouseCallbacks.forEach((cb) => cb(event));
        };
      }
    }, [canvas]);

    return (
      <x.div fill relative noevents>
        <x.canvas
          fill
          w100
          // style={{
          //   position: "fixed",
          //   left: 0,
          //   top: 0,
          //   width: "100%",
          //   height: "100%",
          // }}
          ref={setCanvas}
        ></x.canvas>
      </x.div>
    );
  };

  const DrawElement = (props: { node: LineNode }) => {
    const { node } = props;

    React.useEffect(() => {
      return draw(node);
    }, []);

    return null;
  };

  return {
    DrawSheet,
    DrawElement,
  };
}

export const { DrawSheet, DrawElement } = create();
