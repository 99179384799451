import _ from "lodash";
import { Filter, time } from "@maintmark/shared";
import { OrderItem } from "../order/item/OrderItem";
import React from "react";
import { x } from "@maintmark/shared-web/src/ui";
import { MainPage } from "../main/MainPage";
import { WorkflowPhases } from "../kanban/WorkflowPhases";
import { FilterEdit } from "../main/FilterEdit";
import { useParam } from "../main/utils";
import { data } from "@maintmark/shared-react";

interface Props {
  onOpenChat: () => void;
}

export const Workflow = (props: Props) => {
  const [filter, setFilter] = React.useState<Filter | null>(null);
  const workflowId = useParam("/workflow", "id");
  const workflow = workflowId && data.workflows.useItem(workflowId);

  if (!workflow) {
    return null;
  }

  return (
    <MainPage onOpenChat={props.onOpenChat} filter={filter}>
      <x.div row ph48p mb1>
        <x.div flex />
        <FilterEdit onUpdate={setFilter} filter={filter} />
      </x.div>
      <x.div relative flex column>
        <WorkflowPhases
          filter={filter}
          ItemComponent={OrderItem}
          workflowId={workflow.id}
        />
      </x.div>
    </MainPage>
  );
};
