import * as dateFns from "date-fns";
import { t } from "./locale";
import en from "date-fns/locale/en-US";
import { round } from "./number";

function getLocale(): dateFns.Locale {
  return en;
}

export type InputDate = number | Date;

export const isYesterday = dateFns.isYesterday;
export const isToday = dateFns.isToday;
export const isTomorrow = dateFns.isTomorrow;
export const isSameWeek = dateFns.isSameWeek;

type Options = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

const defaultOptions: Options = {
  weekStartsOn: 1,
  locale: getLocale(),
};

function wrap<Args extends any[]>(func: (...args: Args) => Date) {
  return (...args: Args) => func(...args).valueOf();
}

function wrapOptions<T>(func: (date: InputDate, options: Options) => T) {
  return (date: InputDate) => func(date, defaultOptions);
}

function addDetails(
  text: string,
  date: number,
  options: {
    details?: boolean;
  } = {}
) {
  if (options.details) {
    return text + " (" + time.format(date, "P") + ")";
  }
  return text;
}

export const time = {
  timeAt(input: InputDate | string) {
    return new Date(input).valueOf();
  },
  parseISO(input: string) {
    return dateFns.parseISO(input).valueOf();
  },
  now() {
    return new Date().valueOf();
  },
  today() {
    return time.startOfDay(time.now()).valueOf();
  },
  days(count: number) {
    return time.hours(count * 24);
  },
  hours(count: number) {
    return time.minutes(count * 60);
  },
  minutes(count: number) {
    return time.seconds(60 * count);
  },
  seconds(count: number) {
    return count * 1000;
  },

  getWeeksInMonth: wrapOptions(dateFns.getWeeksInMonth),
  getMonth: wrapOptions(dateFns.getMonth),
  getWeek: wrapOptions(dateFns.getWeek),
  getYear: dateFns.getYear,
  getDay: dateFns.getDay,

  startOfDay: wrap(wrapOptions(dateFns.startOfDay)),
  startOfMonth: wrap(wrapOptions(dateFns.startOfMonth)),
  startOfWeek: wrap(wrapOptions(dateFns.startOfWeek)),
  startOfYear: wrap(wrapOptions(dateFns.startOfYear)),

  addDays: wrap(dateFns.addDays),
  addWeeks: wrap(dateFns.addWeeks),
  addMonths: wrap(dateFns.addMonths),
  addYears: wrap(dateFns.addYears),

  subMonths: wrap(dateFns.subMonths),

  isSameMonth: dateFns.isSameMonth,
  isSameDay: dateFns.isSameDay,

  isFirstDayOfMonth: dateFns.isFirstDayOfMonth,
  isFirstDayOfWeek: (date: InputDate) =>
    dateFns.isSameDay(date, dateFns.startOfWeek(date)),

  differenceInCalendarDays: (dateLeft: InputDate, dateRight: InputDate) =>
    dateFns.differenceInCalendarDays(dateLeft, dateRight),

  differenceInCalendarWeeks: (dateLeft: InputDate, dateRight: InputDate) =>
    dateFns.differenceInCalendarWeeks(dateLeft, dateRight, defaultOptions),

  differenceInCalendarMonths: (dateLeft: InputDate, dateRight: InputDate) =>
    dateFns.differenceInCalendarMonths(dateLeft, dateRight),

  format: (date: InputDate, format: string) =>
    dateFns.format(date, format, defaultOptions),

  formatShortDate(date: number) {
    return dateFns.format(date, "d/M");
  },

  formatTime(date: number) {
    return dateFns.format(date, "HH:mm");
  },

  formatRelativeDate(
    date: number,
    options: {
      details?: boolean;
    } = {}
  ) {
    if (isYesterday(date)) {
      return addDetails(t("Yesterday"), date, options);
    }

    if (isToday(date)) {
      return addDetails(t("Today"), date, options);
    }

    if (isTomorrow(date)) {
      return addDetails(t("Tomorrow"), date, options);
    }

    const now = new Date();

    if (isSameWeek(date, now)) {
      return addDetails(time.format(date, "EEEE"), date, options);
    }

    return time.format(date, "PP");
  },
  formatDuration(duration: number, short?: boolean) {
    const labels = [
      ["{count}hr", "{count}min", "{count}sec"],
      ["{count}h", "{count}m", "{count}s"],
    ][short ? 1 : 0];

    if (duration >= time.hours(1)) {
      return t(labels[0], { count: round(duration / time.hours(1), 1) });
    }

    if (duration >= time.minutes(1) || duration === 0) {
      return t(labels[1], { count: round(duration / time.minutes(1), 1) });
    }
    return t(labels[2], { count: round(duration / time.seconds(1), 1) });
  },
};
