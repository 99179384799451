import * as React from "react";
import { removeInPlaceIf } from "./utils";

export enum EventResult {
  Handled,
  NotHandled,
}

export type EventListener<T> = (event: T) => EventResult;

interface Event<T> {
  data: T;
  def: EventDef<T>;
}

export interface EventDef<T> {
  (data: T): Event<T>;
  eventName: string;
}

let listenersMap = new Map<EventDef<any>, EventListener<any>[]>();

export function createEvent<T>(name: string): EventDef<T> {
  const eventDef: EventDef<T> = ((data: T) => ({
    data,
    def: eventDef,
  })) as any;
  eventDef.eventName = name;
  return eventDef;
}

function getListeners<T>(def: EventDef<T>) {
  if (listenersMap.has(def)) {
    return listenersMap.get(def)!;
  }
  const listenersByDef: EventListener<T>[] = [];
  listenersMap.set(def, listenersByDef);
  return listenersByDef;
}

export function emit<T>(event: Event<T>) {
  getListeners(event.def)
    .reverse()
    .forEach((x) => x(event.data));
}

export function addListener<T>(
  eventDef: EventDef<T>,
  listener: EventListener<T>
) {
  getListeners(eventDef).push(listener);

  return () => {
    removeListener(eventDef, listener);
  };
}

export function removeListener<T>(
  eventDef: EventDef<T>,
  listener: EventListener<T>
) {
  const listeners = getListeners(eventDef);
  removeInPlaceIf(listeners, (l) => l === listener);
  if (listeners.length === 0) {
    listenersMap.delete(eventDef);
  }
}
