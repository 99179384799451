import _ from "lodash";
import React from "react";
import produce from "immer";
import { Component, ComponentType, queryComponent } from "@maintmark/shared";

export function useComponent<T extends Component["type"]>(
  components: Component[],
  type: T,
  update: (components: Component[]) => void,
  defaultValue: ComponentType<T>
): [ComponentType<T>, (cmp: ComponentType<T>) => void] {
  const component = React.useMemo(
    () => queryComponent(components, type, defaultValue),
    [components]
  );

  const _update = React.useCallback(
    (cmp: ComponentType<T>) => {
      let index = components.indexOf(component);
      if (index === -1) {
        index = components.length;
      }
      update(
        produce(components, (draft) => {
          draft[index] = cmp;
        })
      );
    },
    [components, component, update]
  );

  return [component, _update];
}
