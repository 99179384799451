export function startTimeout(cb: () => void, timeout: number) {
  const handle = setTimeout(cb, timeout);
  return () => {
    clearTimeout(handle);
  };
}

export async function wait(timeout: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, timeout));
}
