import { createEvent, emit, EventDef, Socket } from "@maintmark/shared";

export async function openSocket(url: string): Promise<Socket> {
  const onClose: EventDef<any> = createEvent("socket:close");
  const onMessage: EventDef<any> = createEvent("socket:message");

  console.log("Connecting to websocket at " + url);

  const socket = new WebSocket(url);

  return new Promise((resolve, reject) => {
    socket.addEventListener("open", () => {
      resolve({
        onClose,
        onMessage,
        send(data: any) {
          socket.send(data);
        },
      });

      console.log("WebSocket connection established");
    });

    socket.addEventListener("message", async (event) => {
      if (event.data instanceof Blob) {
        // Convert the Blob to a string using the appropriate encoding
        const text = await event.data.text();
        emit(onMessage(text));
        console.log("Received message:", text);
      } else {
        console.log("Received message:", event.data);
      }
    });

    socket.addEventListener("close", () => {
      console.log("WebSocket connection closed");
      emit(onClose({}));
    });
  });
}
