import React from "react";
import {
  Filter,
  getFilterResult,
  Group,
  isUserInGroup,
  Order,
  t,
  User,
} from "@maintmark/shared";
import _ from "lodash";
import { useContext } from "./context";
import { data } from "./data";

function getOrgFilters(group: Group, user: User): Filter[] {
  if (group.type !== "org") {
    return [];
  }

  if (!isUserInGroup(user, group.id)) {
    return [];
  }

  return [
    {
      title: t("Not scheduled"),
      id: "filter:notscheduled:" + group.id,
      query: `orders[startsAt=0 and closedAt=0]`,
      patches: [
        { op: "replace", path: ["startsAt"], value: 0 },
        {
          op: "replace",
          path: ["assignee"],
          value: group,
        },
      ],
    },
    {
      title: t("Unassigned"),
      id: "filter:unassigned:" + group.id,
      query: `orders[assignedTo[id="${group.id}"]]`,
      patches: [
        {
          op: "replace",
          path: ["assignedTo"],
          value: group,
        },
      ],
    },
    {
      title: t("My work orders"),
      id: "filter:mine:" + group.id,
      query: `orders[assignedTo[id="${user.id}"]]`,
      patches: [{ op: "replace", path: ["assignedTo"], value: user }],
    },
  ];
}

export function useFilters() {
  const { user } = useContext();

  const filters = data.groups.useStore(
    (store) => {
      const allGroups = store.all();
      const orgs = allGroups.filter((g) => g.type === "org");
      return _.flatten(orgs.map((org) => getOrgFilters(org, user)));
    },
    [user]
  );

  return filters;
}

export function useFilterItems(orders: Order[], filter: Filter | null) {
  return React.useMemo(() => {
    if (!filter) {
      return orders;
    }

    const items = getFilterResult(filter, orders);

    // const result = jsonQuery(filter.query, {
    //   data: {
    //     orders,
    //   },
    //   locals: {
    //     isStartingOnDay(item, date) {
    //       return time.isSameDay(item.startsAt, Number.parseInt(date));
    //     },
    //     isAssignedTo(item, userId) {
    //       return item.assignedTo.id === userId;
    //     },
    //   },
    // });

    // const items = result.value as AnyItem[];

    return _.orderBy(items, (x) => {
      if ("rank" in x && x.rank) {
        if (x.rank.filterId === filter.id) {
          return x.rank.value;
        }
      }
      return x.createdAt;
    });
  }, [orders, filter?.query]);
}
