import { colors, constants } from "@maintmark/shared/src/ui/theme";

export default {
  flex: {
    flex: 1,
  },
  flex33: {
    flex: 0.33333333,
  },
  flex80: {
    flex: 0.8,
  },
  flex60: {
    flex: 0.6,
  },
  flex40: {
    flex: 0.4,
  },
  wauto: {
    width: "auto",
  },
  centers: {
    alignItems: "center" as "center",
  },
  centerp: {
    justifyContent: "center" as "center",
  },
  starts: {
    alignItems: "flex-start" as "flex-start",
  },
  ends: {
    alignItems: "flex-end" as "flex-end",
  },
  endp: {
    justifyContent: "flex-end" as "flex-end",
  },
  spacearoundp: {
    justifyContent: "space-around" as "space-around",
  },
  spaceevenlyp: {
    justifyContent: "space-evenly" as "space-evenly",
  },
  spacebetweenp: {
    justifyContent: "space-between" as "space-between",
  },
  fill: {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  fillw: {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  bottom: {
    position: "absolute" as "absolute",
    bottom: 0,
  },
  right: {
    position: "absolute" as "absolute",
    right: 0,
  },
  top: {
    position: "absolute" as "absolute",
    top: 0,
  },
  top48p: {
    position: "absolute" as "absolute",
    top: 48,
  },
  /* Colors */
  dimmed: {
    opacity: 0.5,
  },
  opacity0: {
    opacity: 0,
  },
  opacity5: {
    opacity: 0.05,
  },
  opacity15: {
    opacity: 0.15,
  },
  opacity10: {
    opacity: 0.1,
  },
  opacity20: {
    opacity: 0.2,
  },
  opacity30: {
    opacity: 0.3,
  },
  opacity40: {
    opacity: 0.4,
  },
  opacity50: {
    opacity: 0.5,
  },
  opacity60: {
    opacity: 0.6,
  },
  opacity70: {
    opacity: 0.7,
  },
  opacity80: {
    opacity: 0.8,
  },
  opacity85: {
    opacity: 0.85,
  },
  opacity90: {
    opacity: 0.9,
  },
  opacity100: {
    opacity: 1,
  },
  bgmarker600: {
    backgroundColor: colors.marker[600],
  },
  bgp50: {
    backgroundColor: colors.primary[50],
  },
  bgp100: {
    backgroundColor: colors.primary[100],
  },
  bgp200: {
    backgroundColor: colors.primary[200],
  },
  bgp300: {
    backgroundColor: colors.primary[300],
  },
  bgp400: {
    backgroundColor: colors.primary[400],
  },
  bgp500: {
    backgroundColor: colors.primary[500],
  },
  bgp600: {
    backgroundColor: colors.primary[600],
  },
  bgp700: {
    backgroundColor: colors.primary[700],
  },
  bgp800: {
    backgroundColor: colors.primary[800],
  },
  bgp900: {
    backgroundColor: colors.primary[900],
  },
  bggray50: {
    backgroundColor: colors.gray[50],
  },
  bggray100: {
    backgroundColor: colors.gray[100],
  },
  bggray200: {
    backgroundColor: colors.gray[200],
  },
  bggray300: {
    backgroundColor: colors.gray[300],
  },
  bggray500: {
    backgroundColor: colors.gray[500],
  },
  bggray600: {
    backgroundColor: colors.gray[600],
  },
  bggray700: {
    backgroundColor: colors.gray[700],
  },
  bgalert600: {
    backgroundColor: colors.alert[600],
  },
  bgblack: {
    backgroundColor: colors.common.black,
  },
  bgwhite: {
    backgroundColor: colors.common.white,
  },
  overlay: {
    backgroundColor: colors.common.overlay,
  },
  /* Borders */
  bwhite: {
    borderColor: colors.common.white,
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp200: {
    borderColor: colors.primary[200],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp300: {
    borderColor: colors.primary[300],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp500: {
    borderColor: colors.primary[500],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp600: {
    borderColor: colors.primary[600],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp700: {
    borderColor: colors.primary[700],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp800: {
    borderColor: colors.primary[800],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bp900: {
    borderColor: colors.primary[900],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  btp700: {
    borderTopColor: colors.primary[700],
    borderTopWidth: 1,
    borderTopStyle: "solid" as "solid",
  },
  btp800: {
    borderTopColor: colors.primary[800],
    borderTopWidth: 1,
    borderTopStyle: "solid" as "solid",
  },
  btp900: {
    borderTopColor: colors.primary[900],
    borderTopWidth: 1,
    borderTopStyle: "solid" as "solid",
  },
  bbp700: {
    borderBottomColor: colors.primary[700],
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as "solid",
  },
  bbp800: {
    borderBottomColor: colors.primary[800],
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as "solid",
  },
  bbp900: {
    borderBottomColor: colors.primary[900],
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as "solid",
  },
  balert600: {
    borderColor: colors.alert[600],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bgray50: {
    borderColor: colors.gray[50],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bgray100: {
    borderColor: colors.gray[100],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bgray200: {
    borderColor: colors.gray[200],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bgray300: {
    borderColor: colors.gray[300],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bgray800: {
    borderColor: colors.gray[800],
    borderWidth: 1,
    borderStyle: "solid" as "solid",
  },
  bt0p: {
    borderTopWidth: 0,
  },
  bl0p: {
    borderLeftWidth: 0,
  },
  bb0p: {
    borderBottomWidth: 0,
  },
  btgray100: {
    borderTopColor: colors.gray[100],
    borderTopWidth: 1,
    borderTopStyle: "solid" as "solid",
  },
  btgray200: {
    borderTopColor: colors.gray[200],
    borderTopWidth: 1,
    borderTopStyle: "solid" as "solid",
  },
  btgray300: {
    borderTopColor: colors.gray[300],
    borderTopWidth: 1,
    borderTopStyle: "solid" as "solid",
  },
  bbgray100: {
    borderBottomColor: colors.gray[100],
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as "solid",
  },
  bbgray200: {
    borderBottomColor: colors.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as "solid",
  },
  bbgray300: {
    borderBottomColor: colors.gray[300],
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as "solid",
  },
  bb2pgray600: {
    borderBottomColor: colors.gray[600],
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as "solid",
  },
  bb3p800: {
    borderBottomColor: colors.primary[800],
    borderBottomWidth: 3,
    borderBottomStyle: "solid" as "solid",
  },
  brgray200: {
    borderRightColor: colors.gray[200],
    borderRightWidth: 1,
    borderRightStyle: "solid" as "solid",
  },
  brgray300: {
    borderRightColor: colors.gray[300],
    borderRightWidth: 1,
    borderRightStyle: "solid" as "solid",
  },
  brp900: {
    borderRightColor: colors.primary[900],
    borderRightWidth: 1,
    borderRightStyle: "solid" as "solid",
  },
  blp900: {
    borderLeftColor: colors.primary[900],
    borderLeftWidth: 1,
    borderLeftStyle: "solid" as "solid",
  },
  br0p: {
    borderRadius: 0,
  },
  br3p: {
    borderRadius: 3,
  },
  br6p: {
    borderRadius: 6,
  },
  br12p: {
    borderRadius: 12,
  },
  br16p: {
    borderRadius: 16,
  },
  br20p: {
    borderRadius: 20,
  },
  br24p: {
    borderRadius: 24,
  },
  brr0p: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  brr6p: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  brr12p: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  brl0p: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  brl6p: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  brl12p: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  brb0p: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  brt0p: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  brt6p: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  brt12p: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  /* Dimensions */
  h1: {
    height: constants.gridSize,
  },
  h2: {
    height: constants.gridSize * 2,
  },
  h3: {
    height: constants.gridSize * 3,
  },
  h4: {
    height: constants.gridSize * 4,
  },
  w1: {
    width: constants.gridSize,
  },
  w2: {
    width: constants.gridSize * 2,
  },
  w3: {
    width: constants.gridSize * 3,
  },
  w4: {
    width: constants.gridSize * 4,
  },
  w100: {
    width: "100%",
  },
  w1p: {
    width: 1,
  },
  w6p: {
    width: 6,
  },
  w10p: {
    width: 10,
  },
  w12p: {
    width: 12,
  },
  w16p: {
    width: 16,
  },
  w20p: {
    width: 20,
  },
  w22p: {
    width: 22,
  },
  w24p: {
    width: 24,
  },
  w32p: {
    width: 32,
  },
  w35p: {
    width: 35,
  },
  w40p: {
    width: 40,
  },
  w80p: {
    width: 80,
  },
  w90p: {
    width: 90,
  },
  w100p: {
    width: 100,
  },
  w120p: {
    width: 120,
  },
  w160p: {
    width: 160,
  },
  w200p: {
    width: 200,
  },
  w300p: {
    width: 300,
  },
  w400p: {
    width: 400,
  },
  w500p: {
    width: 500,
  },
  w800p: {
    width: 800,
  },
  h0p: {
    height: 0,
  },
  h3p: {
    height: 3,
  },
  h4p: {
    height: 4,
  },
  h6p: {
    height: 6,
  },
  h1p: {
    height: 1,
  },
  h16p: {
    height: 16,
  },
  h20p: {
    height: 20,
  },
  h22p: {
    height: 22,
  },
  h24p: {
    height: 24,
  },
  h30p: {
    height: 30,
  },
  h32p: {
    height: 32,
  },
  h33p: {
    height: 33,
  },
  h35p: {
    height: 35,
  },
  h40p: {
    height: 40,
  },
  h45p: {
    height: 45,
  },
  h48p: {
    height: 48,
  },
  h50p: {
    height: 50,
  },
  h55p: {
    height: 55,
  },
  h60p: {
    height: 60,
  },
  h72p: {
    height: 72,
  },
  h80p: {
    height: 80,
  },
  h95p: {
    height: 95,
  },
  h100p: {
    height: 100,
  },
  h100: {
    height: "100%",
  },
  maxw120p: {
    maxWidth: 120,
  },
  maxw260p: {
    maxWidth: 260,
  },
  maxw1200p: {
    maxWidth: 1200,
  },
  maxw992p: {
    maxWidth: 992,
  },
  maxw557p: {
    maxWidth: 557,
  },
  maxh30p: {
    maxHeight: 30,
  },
  maxh1000p: {
    maxHeight: "none",
  },
  minh2: {
    minHeight: constants.gridSize * 2,
  },
  minh3: {
    minHeight: constants.gridSize * 3,
  },
  minh6: {
    minHeight: constants.gridSize * 6,
  },
  /* Layout */
  column: {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
  },
  columnr: {
    display: "flex" as "flex",
    flexDirection: "column-reverse" as "column-reverse",
  },
  row: {
    display: "flex" as "flex",
    flexDirection: "row" as "row",
  },
  m1: {
    margin: constants.gridSize,
  },
  m2: {
    margin: constants.gridSize * 2,
  },
  mh1: {
    marginLeft: constants.gridSize,
    marginRight: constants.gridSize,
  },
  mh2: {
    marginLeft: constants.gridSize * 2,
    marginRight: constants.gridSize * 2,
  },
  mh4: {
    marginLeft: constants.gridSize * 4,
    marginRight: constants.gridSize * 4,
  },
  mh8p: {
    marginLeft: 8,
    marginRight: 8,
  },
  mh10p: {
    marginLeft: 10,
    marginRight: 10,
  },
  mt1: {
    marginTop: constants.gridSize,
  },
  mt2: {
    marginTop: constants.gridSize * 2,
  },
  mt3: {
    marginTop: constants.gridSize * 3,
  },
  mt4: {
    marginTop: constants.gridSize * 4,
  },
  mt5: {
    marginTop: constants.gridSize * 5,
  },
  mt8: {
    marginTop: constants.gridSize * 8,
  },
  mt10: {
    marginTop: constants.gridSize * 10,
  },
  ml1: {
    marginLeft: constants.gridSize,
  },
  ml2: {
    marginLeft: constants.gridSize * 2,
  },
  ml40p: {
    marginLeft: 40,
  },
  mb1: {
    marginBottom: constants.gridSize,
  },
  mr1: {
    marginRight: constants.gridSize,
  },
  mr2: {
    marginRight: constants.gridSize * 2,
  },
  mr3: {
    marginRight: constants.gridSize * 3,
  },
  mr2p: {
    marginRight: 2,
  },
  mr3p: {
    marginRight: 3,
  },
  mr4p: {
    marginRight: 4,
  },
  mr5p: {
    marginRight: 5,
  },
  mr8p: {
    marginRight: 8,
  },
  mr10p: {
    marginRight: 10,
  },
  mr20p: {
    marginRight: 20,
  },
  mr24p: {
    marginRight: 24,
  },
  ml2p: {
    marginLeft: 2,
  },
  ml3p: {
    marginLeft: 3,
  },
  ml4p: {
    marginLeft: 4,
  },
  ml5p: {
    marginLeft: 5,
  },
  ml8p: {
    marginLeft: 8,
  },
  ml10p: {
    marginLeft: 10,
  },
  ml12p: {
    marginLeft: 12,
  },
  ml17p: {
    marginLeft: 17,
  },
  ml20p: {
    marginLeft: 20,
  },
  mt1p: {
    marginTop: 1,
  },
  mt2p: {
    marginTop: 2,
  },
  mt3p: {
    marginTop: 3,
  },
  mt5p: {
    marginTop: 5,
  },
  mt8p: {
    marginTop: 8,
  },
  mt10p: {
    marginTop: 10,
  },
  mt20p: {
    marginTop: 20,
  },
  mb2: {
    marginBottom: constants.gridSize * 2,
  },
  mb3: {
    marginBottom: constants.gridSize * 3,
  },
  mb4: {
    marginBottom: constants.gridSize * 4,
  },
  mb6: {
    marginBottom: constants.gridSize * 6,
  },
  mb1p: {
    marginBottom: 1,
  },
  mb2p: {
    marginBottom: 2,
  },
  mb3p: {
    marginBottom: 3,
  },
  mb5p: {
    marginBottom: 5,
  },
  mb8p: {
    marginBottom: 8,
  },
  mb10p: {
    marginBottom: 10,
  },
  mb20p: {
    marginBottom: 20,
  },
  mb60p: {
    marginBottom: 60,
  },
  p1: {
    padding: constants.gridSize,
  },
  p2: {
    padding: constants.gridSize * 2,
  },
  p3: {
    padding: constants.gridSize * 3,
  },
  ph1: {
    paddingLeft: constants.gridSize,
    paddingRight: constants.gridSize,
  },
  ph2: {
    paddingLeft: constants.gridSize * 2,
    paddingRight: constants.gridSize * 2,
  },
  ph3: {
    paddingLeft: constants.gridSize * 3,
    paddingRight: constants.gridSize * 3,
  },
  ph5p: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  ph8p: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  ph10p: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  ph20p: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  ph24p: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  ph48p: {
    paddingLeft: 48,
    paddingRight: 48,
  },
  pv1: {
    paddingTop: constants.gridSize,
    paddingBottom: constants.gridSize,
  },
  pv2: {
    paddingTop: constants.gridSize * 2,
    paddingBottom: constants.gridSize * 2,
  },
  pv5p: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  pv10p: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  pv12p: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  pv20p: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  pv24p: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  pt1p: {
    paddingTop: 1,
  },
  pt1: {
    paddingTop: constants.gridSize,
  },
  pt2: {
    paddingTop: constants.gridSize * 2,
  },
  pt3: {
    paddingTop: constants.gridSize * 3,
  },
  pl17p: {
    paddingLeft: 17,
  },
  pl20p: {
    paddingLeft: 20,
  },
  pl48p: {
    paddingLeft: 48,
  },
  pl1: {
    paddingLeft: constants.gridSize,
  },
  pl2: {
    paddingLeft: constants.gridSize * 2,
  },
  pl3: {
    paddingLeft: constants.gridSize * 3,
  },
  pr1: {
    paddingRight: constants.gridSize,
  },
  pr2: {
    paddingRight: constants.gridSize * 2,
  },
  pr3p: {
    paddingRight: 3,
  },
  pr4p: {
    paddingRight: 4,
  },
  pr5p: {
    paddingRight: 5,
  },
  pr10p: {
    paddingRight: 10,
  },
  pr20p: {
    paddingRight: 20,
  },
  pb1: {
    paddingBottom: constants.gridSize,
  },
  pb2: {
    paddingBottom: constants.gridSize * 2,
  },
  pb3: {
    paddingBottom: constants.gridSize * 3,
  },
  pb5p: {
    paddingBottom: 5,
  },
  pb120p: {
    paddingBottom: 120,
  },
  mv1: {
    marginTop: constants.gridSize,
    marginBottom: constants.gridSize,
  },
  mv2: {
    marginTop: constants.gridSize * 2,
    marginBottom: constants.gridSize * 2,
  },
  mv5p: {
    marginTop: 5,
    marginBottom: 5,
  },
  mv8p: {
    marginTop: 8,
    marginBottom: 8,
  },
  mv12p: {
    marginTop: 12,
    marginBottom: 12,
  },
  /* Shapes */
  circle: {
    borderRadius: 1000,
  },
  mask: {
    overflow: "hidden" as "hidden",
  },
  // shadow100: {
  //   shadowColor: "#000",
  //   shadowOffset: {
  //     width: 0,
  //     height: 10,
  //   },
  //   shadowOpacity: 0.51,
  //   shadowRadius: 13.16,
  //   elevation: 20,
  // },
};
