import { Order, OrderUrgency, t, time } from "@maintmark/shared";
import styled from "styled-components";
import { Avatar } from "src/ui/components";
import React from "react";
import _ from "lodash";
import { x } from "@maintmark/shared-web/src/ui";

const Description = styled(x.div)`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

export const ListOrderItem = styled((props: { item: Order } & x.divProps) => {
  const { item, ...other } = props;
  const [popup, setPopup] = React.useState(false);
  const urgent = item.urgency === OrderUrgency.Urgent;

  return (
    <x.div
      bggray100="hover"
      column
      bgwhite
      noselect
      relative
      mask
      bbgray200
      {...other}
    >
      {urgent && (
        <x.div fill noevents>
          <x.div h100 w6p bgalert600 />
        </x.div>
      )}
      <x.div row pv24p ph24p>
        <x.div column flex mr10p>
          <x.div row mb5p>
            <x.div f13 medium p900 flex mr5p>
              {item.name}
            </x.div>
          </x.div>
          <Description f12 light p900 maxh30p>
            {item.description}
          </Description>
          {item.asset && (
            <x.div f12 p900 mt5p mr5p flex tail>
              {item.asset.name}
            </x.div>
          )}
        </x.div>
        <x.div row>
          <Avatar assignee={props.item.assignedTo} />
        </x.div>
      </x.div>
    </x.div>
  );
})`
  & {
    min-height: 80px;
  }
  .heatmap-hover &:active {
    opacity: 0.3;
  }
`;
