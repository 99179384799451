import React, { ReactNode } from "react";
import { x } from "@maintmark/shared-web/src/ui";
import _ from "lodash";

export const KanbanBoard = (props: { children: ReactNode[] }) => {
  return (
    <x.div row flex>
      {_.zip<ReactNode, ReactNode>(
        props.children,
        _.range(0, props.children.length - 1).map(() => <x.div w1 />)
      )}
    </x.div>
  );
};
