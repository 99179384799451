import { Filter, MenuItem, ui } from "@maintmark/shared";
import {
  data,
  MenuStore,
  useFilterItems,
  useMenuItems,
} from "@maintmark/shared-react";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import * as storage from "src/storage";
import React from "react";
import DownImg from "../images/down-sm-p800@3x.png";
import { Menu, Popup } from "@maintmark/shared-web/src/ui";

const MenuButton = styled(
  (props: { item: MenuItem; select: (item: MenuItem) => void }) => {
    const { item, select, ...other } = props;
    return (
      <x.div
        h32p
        ph2
        centers
        row
        pointer
        onClick={() => select(item)}
        {...other}
      >
        <x.div gray800 f14 medium>
          {props.item.title}
        </x.div>
      </x.div>
    );
  }
)`
  &:hover {
    background-color: ${ui.theme.colors.gray[100]};
  }
`;

export const menuStorage = storage.create<MenuStore>("menu", { activeId: "" });

export const ListSelector = (props: x.divProps & { filter: Filter }) => {
  const [active, menuItems, select] = useMenuItems(
    menuStorage,
    (items) => items[0]
  );

  const renderMenu = React.useCallback(
    () => (
      <Menu pv20p mt5p lg>
        {menuItems.map((item) => (
          <MenuButton item={item} select={select} />
        ))}
      </Menu>
    ),
    [select, menuItems]
  );

  const items = useFilterItems(
    data.orders.useStore((store) => store.all(), []),
    active.filter || null
  );

  return (
    <x.div {...props} noselect ml8p>
      <Popup renderContent={renderMenu} ml2>
        <x.div p900 pointer row>
          <x.div f16 tail flex>
            {active.title}
          </x.div>
          <x.div f14 ml5p mt1p>
            ({items.length})
          </x.div>
          <x.div>
            <x.img src={DownImg} mb2p ml8p width={9} height={7} />
          </x.div>
        </x.div>
      </Popup>
    </x.div>
  );
};
