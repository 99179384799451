import { ui } from "@maintmark/shared";
import styled from "styled-components";
import { ButtonProps } from "./Button";
import cn from "classnames";
import { x } from "@maintmark/shared-web/src/ui";

interface Props extends ButtonProps {
  active?: boolean;
  activeColor?: string;
}

export const ToggleButton = styled((props: Props) => {
  const { index = 0, total = 1, active } = props;

  return (
    <x.div
      h35p
      br6p
      bl0p={index > 0}
      brr0p={total > 0 && index < total - 1}
      brl0p={index > 0}
      w120p
      bgp900
      row
      centerp
      centers
      white
      f14
      semibold
      regular={!active}
      pointer
      noselect
      {...props}
      className={cn(props.className, { inactive: !active })}
    />
  );
})`
  background-color: ${(props) =>
    props.active
      ? props.activeColor || ui.theme.colors.primary[900]
      : ui.theme.colors.common.white} !important;

  &.inactive {
    &:hover {
      &:active {
        background-color: ${ui.theme.colors.gray[100]} !important;
      }
    }
  }
`;
