import {
  time,
  DateTime,
  data,
  EventResult,
  Filter,
  getFilterResult,
} from "@maintmark/shared";
import { ui } from "@maintmark/shared-react";
import _ from "lodash";
import styled from "styled-components";
import { GrabToScroll } from "../components/GrabToScroll";
import { Droppable } from "../components";
import React from "react";
import { colors } from "@maintmark/shared/src/ui/theme";
import { useDropTarget } from "../droppable";
import { useMousePosition } from "../mouse";
import cn from "classnames";
import { alpha } from "@maintmark/shared/src/ui";
import { createData, handleChange } from "./utils";
import { HeatmapData, MonthData, WeekData } from "./types";
import { x } from "@maintmark/shared-web/src/ui";

interface DayProps {
  index: number;
  startOfMonth: DateTime;
  startOfNextMonth: DateTime;
  startOfWeek: DateTime;
  date: DateTime;
  data: WeekData;
  selected: boolean;
}

const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  background: "transparent",
  // padding: grid,
});

const Day = React.memo(styled((props: DayProps & x.divProps) => {
  const isActive =
    props.date >= props.startOfMonth && props.date < props.startOfNextMonth;

  const { color, selected } = props.data[props.index] || {
    color: colors.common.white,
    selected: false,
  };

  const style = color
    ? {
        backgroundColor: color,
      }
    : undefined;

  return (
    <x.div
      data-date={props.date}
      h22p
      w22p
      bgwhite
      br3p
      className={cn(
        {
          day: isActive,
          selected,
        },
        props.className
      )}
      ml5p
      opacity0={!isActive}
      style={style}
      relative
    />
  );
})`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`);

interface WeekProps {
  index: number;
  startOfWeek: DateTime;
  startOfMonth: DateTime;
  startOfNextMonth: DateTime;
  data: MonthData;
}

const Week = React.memo((props: WeekProps & x.divProps) => {
  const days = props.data[props.index];
  const weekNum = time.format(props.startOfWeek, "w");
  const className = `week-${weekNum}`;

  return (
    <x.div
      row
      mt3p
      centers
      className={cn(className, props.className, "week")}
      data-week={props.startOfWeek}
      pointer
    >
      <x.div w12p f10 gray500 textright className="label">
        {weekNum}
      </x.div>
      <ui.calendar.Week DayComponent={Day} {...props} data={days} />
    </x.div>
  );
});

interface MonthProps {
  date: DateTime;
  data: MonthData;
}

const Month = styled((props: MonthProps & x.divProps) => {
  const { className, ...other } = props;

  return (
    <x.div className={className} mr1>
      <x.div ml17p f10 light p900 uppercase opacity90>
        {time.format(props.date, "MMMM")}
      </x.div>
      <ui.calendar.Month WeekComponent={Week} {...other} />
    </x.div>
  );
})`
  & .day {
    border: 0.3px solid ${alpha(colors.primary[900], 0.7)};
    &.selected {
      border: 2px solid ${colors.primary[900]};
    }
  }

  .dragging & .day {
    &:hover {
      background-color: ${colors.marker[600]} !important;
      z-index: 5001;
    }
  }
`;

const DroppableHeatmap = React.memo(
  styled(
    (
      props: {
        start: DateTime;
        hover?: DateTime;
        selection: DateTime;
        isDropDisabled: boolean;
        data: HeatmapData;
      } & x.divProps
    ) => {
      const [dropDate, setDropDate] = React.useState<DateTime | null>(null);

      React.useEffect(() => {
        return clearHovering;
      }, []);

      const setHovering = React.useCallback(() => {
        document.body.classList.add("heatmap-hover");
      }, []);

      const clearHovering = React.useCallback(() => {
        document.body.classList.remove("heatmap-hover");
      }, []);

      const encodeTarget = useDropTarget<"heatmap", void>(
        "heatmap",
        ({ style, setStyle }) => {
          const element = document.querySelector(".day:hover");
          const date = element && element.getAttribute("data-date");

          setDropDate(date ? Number.parseInt(date) : null);

          if (!date || !style || !("left" in style)) {
            return;
          }

          const { top, left } = element.getBoundingClientRect();

          const translate = `translate(${
            left - style.left - style.width / 2
          }px, ${top - style.top - style.height / 2}px)`;

          const scale = "scale(0)";

          setStyle({
            ...style,
            transform: `${translate} ${scale}`,
            opacity: 0,
            transition: `all ease-out 0.2s`,
          });
        },
        ({ itemId }) => {
          if (dropDate) {
            data.orders.update(itemId, (item) => (item.startsAt = dropDate));
          }
        },
        [dropDate]
      );

      return (
        <x.div className={props.className}>
          <Droppable
            target={encodeTarget()}
            isDropDisabled={props.isDropDisabled}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <GrabToScroll
                  onMouseOver={setHovering}
                  onMouseOut={clearHovering}
                >
                  <x.div row pb1 noselect>
                    {_.range(0, 12).map((index) => {
                      const date = time.addMonths(props.start, index);
                      return (
                        <Month
                          key={date}
                          date={date}
                          data={props.data.months[index]}
                        />
                      );
                    })}
                  </x.div>
                </GrabToScroll>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </x.div>
      );
    }
  )`
    & .week-${(p) => (p.hover ? time.getWeek(p.hover) : "")} {
      .day {
        border: 1px solid ${colors.primary[900]} !important;
      }
      .label {
        color: ${colors.primary[900]};
        font-family: "Neometric-ExtraBold";
      }
    }

    & .week-${(p) => time.getWeek(p.selection)} {
      .day {
        border: 2px solid ${colors.primary[900]} !important;
      }
      .label {
        color: ${colors.primary[900]};
        font-family: "Neometric-ExtraBold";
      }
    }
  `
);

const WrappedHeatmap = React.memo(
  (props: {
    start: DateTime;
    data: HeatmapData;
    selection: DateTime;
    onSelectDate: (time: DateTime) => void;
  }) => {
    // TODO: Optimize!
    useMousePosition();

    const dragging = document.querySelector(".dragging");

    const hoverDate = document
      .querySelector(".day:hover")
      ?.getAttribute("data-date");

    const hover = document
      .querySelector(".week:hover")
      ?.getAttribute("data-week");

    const hoverWeek = hover && parseInt(hover);

    return (
      <x.div
        onClick={(e) => {
          if (hoverWeek && !dragging) {
            props.onSelectDate(hoverWeek);
          }
        }}
      >
        <DroppableHeatmap
          isDropDisabled={!hoverDate}
          start={props.start}
          data={props.data}
          selection={props.selection}
          hover={hoverWeek}
        />
      </x.div>
    );
  }
);

interface Props {
  selected: DateTime;
  onSelectDate: (time: DateTime) => void;
  filter: Filter | null;
}

export const Heatmap = (props: Props & x.divProps) => {
  const { selected, onSelectDate, filter } = props;

  const startOfYear = time.startOfYear(selected);
  const [heatmapData, setHeatmapData] = React.useState<HeatmapData>(() =>
    createData([], startOfYear)
  );

  React.useEffect(() => {
    const allOrders = data.orders.all();
    const orders = filter ? getFilterResult(filter, allOrders) : allOrders;
    setHeatmapData(createData(orders, startOfYear));
  }, [startOfYear, filter]);

  React.useEffect(() => {
    return data.orders.subscribe((event) => {
      if ("change" in event) {
        // TODO: Filter change
        setHeatmapData(handleChange(heatmapData, event, startOfYear));
      }
      return EventResult.Handled;
    });
  }, [heatmapData, startOfYear]);

  return (
    <x.div style={{ height: 200 }} relative ml40p mt2>
      <x.div {...props} fillw>
        <WrappedHeatmap
          start={startOfYear}
          data={heatmapData}
          selection={selected}
          onSelectDate={onSelectDate}
        />
      </x.div>
    </x.div>
  );
};
