import { addListener, EventResult, Socket } from "@maintmark/shared";
import React from "react";

export function useMouseTracking(socket: Socket | null) {
  React.useEffect(() => {
    if (!socket) {
      return;
    }
    return addListener(socket.onMessage, (msg) => {
      return EventResult.Handled;
    });
  });
}
