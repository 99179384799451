import {
  ChartAggregate,
  ChartDataSource,
  FieldDef,
  LineChart,
  t,
  XYChart,
} from "@maintmark/shared";
import { data } from "@maintmark/shared-react";
import { DropdownList, TextInput, x } from "@maintmark/shared-web/src/ui";
import React from "react";
import { getAggregateLabel, useAggregateItems } from "./aggregate";
import { useFields } from "./fields";

export const EditXYChart = (props: {
  chart: XYChart<any>;
  onUpdate: (chart: XYChart<any>) => void;
}) => {
  const { chart, onUpdate } = props;

  const xField = data.fieldDefs.useItem(chart.xAxis.fieldId);
  const yField = data.fieldDefs.useItem(chart.yAxis.fieldId);
  const fields = data.fieldDefs.useStore(
    (store) => store.filter((item) => item.itemType === chart.source),
    [chart]
  );

  const getFields = (key: "xAxis" | "yAxis") =>
    useFields(
      chart,
      React.useCallback(
        (field: FieldDef<any>) => {
          onUpdate({
            ...chart,
            [key]: {
              ...chart[key],
              fieldId: field.id,
            },
          });
        },
        [onUpdate, chart]
      )
    );

  const aggregates = useAggregateItems(
    React.useCallback(
      (aggregate: ChartAggregate) => {
        onUpdate({
          ...chart,
          yAxis: {
            ...chart.yAxis,
            aggregate,
          },
        });
      },
      [onUpdate, chart]
    )
  );

  return (
    <x.div>
      <x.div btgray200 mt1 pt1 ph2>
        <x.div ph5p f12 bold p900 opacity60>
          {t("X Axis configuration")}
        </x.div>
      </x.div>

      <x.div ph2 mt1 mb2>
        <x.div row gap1>
          <DropdownList
            name={t("Field")}
            text={xField.name}
            hidden
            flex
            items={getFields("xAxis")}
          />
          <x.div flex />
        </x.div>
      </x.div>

      <x.div btgray200 mt1 pt1 ph2>
        <x.div ph5p f12 bold p900 opacity60>
          {t("Y Axis configuration")}
        </x.div>
      </x.div>

      <x.div ph2 mt1 mb2>
        <x.div row gap1>
          <DropdownList
            name={t("Field")}
            text={yField.name}
            hidden
            flex
            items={getFields("yAxis")}
          />

          <DropdownList
            name={t("Aggregate")}
            text={getAggregateLabel(chart.yAxis.aggregate)}
            hidden
            flex
            items={aggregates}
          />
        </x.div>
      </x.div>
    </x.div>
  );
};
