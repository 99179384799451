import React from "react";
import styled from "styled-components";
import { useKeyDown } from "../keyboard";
import { x } from "@maintmark/shared-web/src/ui";
import { DemoNotice, TextNotice } from "./types";
import cn from "classnames";
import Voice0 from "./audio/0.mp3";
import Voice1 from "./audio/1.mp3";
import Voice2 from "./audio/2.mp3";
import Voice3 from "./audio/3.mp3";
import Voice4 from "./audio/4.mp3";
import Voice5 from "./audio/5.mp3";
import Voice6 from "./audio/6.mp3";
import Voice7 from "./audio/7.mp3";
import Voice8 from "./audio/8.mp3";
import Music from "./audio/music.mp3";
import { t } from "@maintmark/shared";

const Notice = (
  props: x.divProps & { onClose: () => void; notice: DemoNotice<TextNotice> }
) => {
  const {
    notice: { content },
    onClose,
    ...other
  } = props;
  React.useEffect(() => {
    setTimeout(() => {
      props.onClose();
    }, 4000);
  }, [props.onClose]);

  if (!content.text || !content.title) {
    return null;
  }

  return (
    <x.div br12p bgp300 z1 bp800 className="notice" shadowPopup>
      <x.div black f20>
        {content.title}
      </x.div>
      <x.div f20 mt10p light>
        {content.text}
      </x.div>
    </x.div>
  );
};

// English (en-US, Male, Neural) - J

const notices: DemoNotice<any>[] = [
  {
    content: null,
  },
  {
    content: {
      title: "Welcome to MaintMark",
      text: "A user friendly tool to organize work orders for you, your teams and your projects.",
    },
    // Welcome to Maintmark. A user friendly tool that will help you organise your work orders.
    voice: Voice0,
  },
  {
    content: {
      title: "Excellent overview",
      text: "Get a quick understanding of how much has been scheduled and done for the days and weeks throughout the year.",
    },
    // With Maintmark, you can easily get a quick understanding on how much has been scheduled and done for the days and weeks throughout the year.
    voice: Voice1,
  },
  {
    content: {
      title: "Scheduling made easy",
      text: "With MaintMark, you can drag and drop your work orders in order to schedule it for a particular day.",
    },
    // We've made it very easy for you to schedule work orders, you only have to drag and drop the work order on the particular day where you want to it to be done. The color code on the yearly overview will give you an idea of how much work is scheduled for that particular day.
    voice: Voice2,
  },
  {
    content: {
      title: "Rank orders",
      text: "Rank orders by dragging them. Makes it easy for the worker to see what needs to be done first.",
    },
    // Move your work orders up or down in order to rank them. This makes it clear on how work should be prioritised, starting from the top of the list and moving on to the next one.
    voice: Voice3,
  },
  {
    content: {
      title: "View recent changes",
      text: "See what has changed recently, and undo any change.",
    },
    // Maintmark keeps track of every change and you can easily undoo your actions from the recent changes list.
    voice: Voice4,
  },
  {
    content: {
      title: "Assign orders by two clicks",
      text: "Assign orders to groups or individuals with just two clicks.",
    },
    // Assign work orders to groups or individuals with just two clicks.... You can also assign your work orders to external service suppliers or similar, and easily track the progress on these orders... It's never been easier to collaborate with external organisations.
    voice: Voice5,
  },
  {
    content: {
      title: "Find things fast",
      text: "You can easily search by title, description, assignee, asset or just about anything you want.",
    },
    // MaintMark has a powerful search feature where you can easily find your work orders based on description, associated assets or just about anything you want.
    voice: Voice6,
  },
  {
    content: null,
    // Are you ready for a tool that is really user friendly and will keep you in control?!?! Great! Get things done... with Maintmark...
    voice: Voice7,
  },
  {
    content: "banner",
    // Are you ready for a tool that is really user friendly and will keep you in control?!?! Great! Get things done... with Maintmark...
    voice: Voice8,
  },
];

export const DemoNotices = styled((props) => {
  const [index, setIndex] = React.useState(-1);
  const [show, setShow] = React.useState(false);
  const notice = React.useMemo(() => notices[index], [index]);
  const voice = React.useRef<HTMLAudioElement | null>(null);

  useKeyDown(
    (event) => {
      if (event.key === "§") {
        const newIndex = Math.min(index + 1, notices.length);

        if (!show) {
          if (index === -1) {
            const music = new Audio(Music);
            music.volume = 0.12;
            music.play();
          }

          setIndex(newIndex);

          if (voice.current) {
            voice.current.pause();
            voice.current.currentTime = 0;
            voice.current.remove();
          }

          if (newIndex >= 0) {
            if (notices[newIndex].voice) {
              voice.current = new Audio(notices[newIndex].voice);
              voice.current.play();
            }
          }
        }

        setShow(!show);
      }

      if (event.key === " ") {
        setIndex(-1);
        setShow(false);
      }
    },
    [show, index]
  );

  if (!notice) {
    return null;
  }

  return (
    <x.div
      fill
      z1
      noevents
      className={cn(props.className, {
        out: !show,
        in: show,
      })}
    >
      {typeof notice.content === "object" && notice.content !== null ? (
        <Notice notice={notice} onClose={() => {}} />
      ) : notice.content === "banner" ? (
        <x.div fill bgp900 column centers centerp className="banner">
          <x.div white f50 row ml17p>
            <x.div bold>maint</x.div>
            <x.div medium>mark</x.div>
            <x.div f16 mt10p ml5p>
              &trade;
            </x.div>
          </x.div>
          <x.div white f16 row p300 mt8p>
            {t("Keep your work oders in order")}
          </x.div>
        </x.div>
      ) : null}
    </x.div>
  );
})`
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes in {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes out {
    from {
      opacity: 1;
      transform: translateX(0%);
    }
    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  overflow: hidden;

  & .notice {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 24px;
    max-width: 400px;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.27, 0, 0, 1);
    animation-delay: 0s;
    line-height: 1.65em;
  }

  &.in {
    .notice {
      animation-name: in;
    }
  }

  &.out {
    .notice {
      animation-name: out;
    }
  }

  & .banner {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-timing-function: ease-out;
    animation-delay: 1700ms;
    animation-name: fade-in;
  }
`;
