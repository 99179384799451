import { ui } from "@maintmark/shared";
import { colors, constants } from "@maintmark/shared/src/ui/theme";
import React, { CSSProperties } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { dispatchDrop, DropTarget, encodeDraggableId } from "../droppable";
import { x } from "@maintmark/shared-web/src/ui";
import { Droppable } from "./Droppable";
import cuid from "cuid";

interface DroppableItem {
  id: string;
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver
    ? ui.alpha(ui.theme.colors.primary[800], 0.1)
    : "transparent",
  borderRadius: isDraggingOver ? 6 : 0,
  minHeight: "100%",
  // background: "transparent",
  // padding: grid,
});

const grid = constants.gridSize;

function getStyle(
  style: CSSProperties | DraggingStyle | NotDraggingStyle | undefined,
  itemStyle?: CSSProperties
) {
  return {
    ...style,
    ...itemStyle,
  };
}

const DraggableItem = <T extends DroppableItem>(props: {
  item: T;
  index: number;
  ItemComponent: React.ComponentType<{
    item: T;
  }>;
  itemStyle?: CSSProperties;
  listId: string;
}) => {
  const { item, index, ItemComponent, itemStyle, listId } = props;
  const dropStyle = React.useRef<CSSProperties | undefined>();
  const droppableId = React.useRef<string | undefined>();

  const renderItem = React.useCallback(
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      if (snapshot.isDropAnimating && snapshot.draggingOver) {
        if (droppableId.current !== snapshot.draggingOver) {
          droppableId.current = snapshot.draggingOver;
          console.log(">>>>>>>>>>>> drop", item.id);
          dispatchDrop({
            droppableId: droppableId.current,
            itemId: item.id,
            setStyle: (style) => {
              dropStyle.current = style;
            },
            style: provided.draggableProps.style,
          });
        }
      } else {
        droppableId.current = undefined;
        dropStyle.current = undefined;
      }

      return (
        <x.div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(
            dropStyle.current || provided.draggableProps.style,
            itemStyle
          )}
        >
          <ItemComponent item={item} />
        </x.div>
      );
    },
    [ItemComponent, item]
  );

  return (
    <Draggable
      key={item.id}
      draggableId={encodeDraggableId({ itemId: item.id, listId })}
      index={index}
    >
      {renderItem}
    </Draggable>
  );
};

interface Props<T extends DroppableItem> {
  items: T[];
  ItemComponent: React.ComponentType<{
    item: T;
  }>;
  target: DropTarget<any>;
  itemStyle?: CSSProperties;
}

export const DroppableList = <T extends DroppableItem>(props: Props<T>) => {
  const { target, items, ItemComponent, itemStyle, ...other } = props;

  const id = React.useMemo(() => cuid(), []);

  return (
    <x.div {...other} flex br6p>
      <Droppable target={target}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <DraggableItem<T>
                listId={id}
                index={index}
                key={item.id}
                item={item}
                ItemComponent={ItemComponent}
                itemStyle={itemStyle}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </x.div>
  );
};
