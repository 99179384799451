import { time } from "@maintmark/shared";
import { data } from "@maintmark/shared-react";
import { x } from "@maintmark/shared-web/src/ui";
import _ from "lodash";
import React from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { TypePredicateKind } from "typescript";
import { TimeNavigator } from "../components";
import { NavBar } from "../main/NavBar";
import { ChartView } from "./ChartView";
import { TimeSelector } from "./TimeSelector";
import GridLayout from "react-grid-layout";
import { useParam } from "../main/utils";

const GridLayoutss = WidthProvider(GridLayout);

interface Props {
  onOpenChat: () => void;
}

const Charts = () => {
  const boardId = useParam("/dashboard", "id");
  const board = boardId && data.dashboards.useItem(boardId);

  if (!board) {
    return null;
  }

  const { charts } = board;

  const layout = charts.map((c) => ({
    i: c.id,
    isDraggable: true,
    isResizable: true,
    ...c.layout,
  }));

  const onLayoutChange = React.useCallback(
    (layout: Layout[]) => {
      layout.map((l) => {
        data.dashboards.update(board.id, (board) => {
          board.charts.some((chart) => {
            if (chart.id !== l.i) {
              return false;
            }
            _.assign(chart.layout, l);
            return true;
          });
        });
      });
    },
    [board]
  );

  return (
    <GridLayoutss
      // {...this.props}
      cols={12}
      layout={layout}
      // onBreakpointChange={this.onBreakpointChange}
      onLayoutChange={onLayoutChange}
      compactType="vertical"
      preventCollision={false}
      // onDrop={this.onDrop}
      // WidthProvider option
      measureBeforeMount={true}
      draggableHandle=".drag"
      // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
      // and set `measureBeforeMount={true}`.
      // useCSSTransforms={this.state.mounted}
      // compactType={this.state.compactType}
      // preventCollision={!this.state.compactType}
    >
      {charts.map((chart) => (
        <div key={chart.id}>
          <ChartView
            key={chart.id}
            chart={chart}
            onUpdate={(chart) => {
              // data.charts.update(chart.id, chart);
            }}
          />
        </div>
      ))}
    </GridLayoutss>
  );
};

export const Dashboard = (props: Props) => {
  const [period, setPeriod] = React.useState(() => {
    const start = time.startOfMonth(time.now());
    return {
      start: time.startOfMonth(time.now()),
      length: time.addMonths(start, 1) - time.startOfMonth(time.now()),
    };
  });

  return (
    <x.div fill bgp50 column>
      <NavBar onOpenChat={props.onOpenChat} />
      <x.div ph3 mb2 row gap2>
        <TimeSelector period={period} onUpdate={setPeriod} />
        <TimeNavigator flex initial={period} onUpdate={setPeriod} />
      </x.div>
      <x.div row ph3 pb3 flex gap2 sflex>
        <x.div relative flex>
          {/* <x.div column w400p gap2 sflex>
          <ChartView size="sm" />
          <x.div row flex gap1>
            <ChartView size="sm" />
            <ChartView size="sm" />
          </x.div>
          <ChartView size="sm" />
        </x.div> */}
          <Charts />

          {/* <ChartView size="md" /> */}
        </x.div>
      </x.div>
    </x.div>
  );
};
