import { Config } from "../types";

const config: Config = {
  api: {
    uri: "http://localhost:8082",
    secure: false,
  },
  web: {
    uri: "https://localhost",
  },
  events: {
    // uri: "wss://app.maintmark.com:8083",
    uri: "ws://localhost:8083",
  },
};

export default config;
