import {
  addListener,
  createEvent,
  emit,
  EventResult,
  wait,
} from "@maintmark/shared";
import { ui } from "@maintmark/shared-react";
import React from "react";
import { Container } from "./Container";
import { SidePanelContent } from "./types";

export const [Provider, , usePortal, portal] = ui.modals.createPortal();

function createSidePanel(): [
  React.ComponentType<{}>,
  <P>(Component: React.ComponentType<P>, props: P) => void
] {
  const showEvent = createEvent<SidePanelContent<any>>("show-sidepanel");
  const Surface = () => {
    const [content, setContent] = React.useState<SidePanelContent<any> | null>(
      null
    );

    const [isOpen, setIsOpen] = React.useState(false);
    const [pendingClose, setPendingClose] = React.useState<null | ReturnType<
      typeof setTimeout
    >>(null);

    const close = React.useCallback(async () => {
      setIsOpen(false);
      setPendingClose(
        setTimeout(() => {
          setContent(null);
          setPendingClose(null);
        }, 300)
      );
    }, []);

    React.useEffect(() => {
      return addListener(showEvent, (content) => {
        if (pendingClose) {
          clearTimeout(pendingClose);
        }
        setContent(content);
        setIsOpen(true);
        return EventResult.Handled;
      });
    }, [pendingClose]);

    return React.createElement(Container, {
      content,
      onClose: close,
      isOpen,
    });
  };

  return [
    Surface,
    <P>(Component: React.ComponentType<P>, props: P) => {
      emit(showEvent({ Component, props }));
    },
  ];
}

export const [SidePanelSurface, showSidePanel] = createSidePanel();

// export const sidePanel = {
//   show<P>(Component: React.ComponentType<P>, props: P) {
//     portal.show({
//       Component: Container,
//       props: {
//         children: React.createElement(Component as any, props as any),
//       },
//     });
//   },
// };
