import {
  Asset,
  Group,
  sortCreatedAt,
  t,
  User,
  Filter,
} from "@maintmark/shared";
import React from "react";
import { Avatar, HoverItem, SearchBox, SearchResults } from "../components";
import { x } from "@maintmark/shared-web/src/ui";
import PlusImg from "./images/plus@3x.png";
import CrossImg from "./images/cross@3x.png";
import StarImg from "./images/star@3x.png";
import StarFilledImg from "./images/star-filled@3x.png";
import { Popup } from "@maintmark/shared-web/src/ui";
import { data } from "@maintmark/shared-react";

type FilterItemType = User | Group | Asset;

function createFilter(items: FilterItemType[]): Filter {
  const sorted = items.sort(sortCreatedAt("asc"));

  return {
    __typename: "Filter",
    id: sorted.map((item) => item.id).join(","),
    patches: [],
    query: `orders[assignedTo[${items
      .map((item) => `id="${item.id}"`)
      .join(" and ")}]]`,
  };
}

const Favourite = (props: x.imageProps & { filter: Filter }) => {
  const { filter, ...other } = props;

  const exists = data.filters.useStore(
    (store) => store.has(filter.id),
    [filter.id]
  );

  const click = React.useCallback(() => {
    if (exists) {
      data.filters.delete(filter.id);
    } else {
      data.filters.insert(filter);
    }
  }, [exists, filter.id]);

  return (
    <x.img
      src={exists ? StarFilledImg : StarImg}
      width={24}
      height={24}
      onClick={click}
      pointer
      events
      {...other}
    />
  );
};

const FilterItem = (props: { item: User; onRemove: () => void }) => {
  return (
    <x.div
      h30p
      ph1
      row
      centers
      br20p
      bp900
      bwidth80
      pointer
      noselect
      mr10p
      opacity70
    >
      <x.div mr8p p900 bold f12>
        {props.item.name}
      </x.div>
      <x.img
        events
        src={CrossImg}
        width={10}
        height={10}
        mt1p
        onClick={props.onRemove}
      />
    </x.div>
  );
};

const PersonItem = (props: { item: User; onSelect: (item: User) => void }) => {
  const { item, onSelect } = props;

  const click = React.useCallback(() => {
    onSelect(item);
  }, [item, onSelect]);

  return (
    <HoverItem p900 row centers pl17p pl1 h40p f14 pointer mb5p onClick={click}>
      <Avatar assignee={item} mr10p />
      {item.name}
    </HoverItem>
  );
};

interface Props {
  filter: Filter | null;
  onUpdate: (filter: Filter | null) => void;
}

export const FilterEdit = (props: Props) => {
  const [items, setItems] = React.useState<User[]>([]);

  const renderFilter = React.useCallback((close: () => void) => {
    return (
      <SearchBox
        minw500p
        mt5p
        ph1
        pv1
        groups={[
          {
            title: t("People"),
            ResultsCmp: () => {
              const [term, setTerm] = React.useState("");
              const users = data.users.useStore(
                (store) => store.search(term),
                [term]
              );

              return (
                <SearchResults
                  onSelect={(item) => {
                    setItems((items) => [...items, item]);
                    close();
                  }}
                  onSearch={setTerm}
                  ItemComponent={PersonItem}
                  items={users}
                  header={term === "" ? t("Suggestions") : ""}
                />
              );
            },
          },
          {
            title: t("Groups"),
            ResultsCmp: () => {
              return <x.div></x.div>;
            },
          },
          {
            title: t("Companies"),
            ResultsCmp: () => {
              return <x.div></x.div>;
            },
          },
          {
            title: t("Projects"),
            ResultsCmp: () => {
              return <x.div></x.div>;
            },
          },
          {
            title: t("Assets"),
            ResultsCmp: () => {
              return <x.div></x.div>;
            },
          },
        ]}
        tabProps={{
          sm: true,
        }}
      />
    );
  }, []);

  const { filter } = props;

  React.useEffect(() => {
    props.onUpdate(items.length > 0 ? createFilter(items) : null);
  }, [items]);

  return (
    <x.div>
      <x.div row centers>
        {items.map((item) => (
          <FilterItem
            item={item}
            onRemove={() =>
              setItems((items) => items.filter((x) => x !== item))
            }
          />
        ))}
        <Popup renderContent={renderFilter} align="right">
          <x.div
            h30p
            ph1
            row
            centers
            br20p
            pointer
            noselect
            bp900
            relative
            events
            opacity60
            opacity100="hover"
            bdashed
          >
            <x.img src={PlusImg} width={8} height={8} />
            <x.div ml4p f12>
              {t("Add filter")}
            </x.div>
          </x.div>
        </Popup>
        {filter ? (
          <Favourite ml1 filter={filter} />
        ) : (
          <x.img ml1 opacity30 src={StarImg} width={24} height={24} />
        )}
      </x.div>
    </x.div>
  );
};
