import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../../../x/extend";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const div = extend<
  HTMLDivElement,
  CSSProperties | undefined,
  React.HTMLAttributes<HTMLDivElement>,
  typeof styles,
  typeof props
>("div", styles, {}, props);

export type divProps = ExtractPropType<typeof div>;
