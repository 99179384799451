import {
  ItemsComponent,
  Order,
  OrderItem,
  queryComponent,
  t,
} from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import { UpdateArg } from "@maintmark/shared/src/store";
import cuid from "cuid";
import produce from "immer";
import { useComponent } from "src/component";

interface ItemsProps {
  onUpdate: (component: ItemsComponent) => void;
  component: ItemsComponent;
}

interface Props {
  onUpdate: (arg: UpdateArg<Order>) => void;
  order: Order;
}

interface Part {
  number: string;
  name: string;
}

interface PartRow {
  allocated: number;
  issued: number;
  returned: number;
  part: Part;
}

const mocked = [
  {
    number: "3274-332",
    name: "Fallskyddsnät 10 x 2m",
  },
  {
    number: "44321-332",
    name: "Förankringsrör",
  },
  {
    number: "9821-112",
    name: "Stålrör 3m",
  },
  {
    number: "55123-995",
    name: "Säkerhetslås",
  },
  {
    number: "77001-223",
    name: "Justerbar stödben",
  },
];
const Cell = (props: x.divProps) => {
  return <x.div {...props} />;
};

const Action = (props: x.divProps) => {
  return (
    <x.div regular bgray200 ph2 pv10p f12 br6p pointer bgwhite {...props} />
  );
};

const Actions = (props: ItemsProps) => {
  return (
    <x.div ph20p pv10p row sflex bbgray200 medium endp>
      <Action>{t("Print")}</Action>
      <Action
        ml1
        onClick={() =>
          props.onUpdate(
            produce(props.component, (draft) => {
              draft.items.push({
                allocated: 10,
                issued: 0,
                returned: 0,
                type: {
                  __typename: "Item",
                  id: cuid(),
                  components: [],
                  ...mocked[Math.min(mocked.length - 1, draft.items.length)],
                },
              });
            })
          )
        }
      >
        {t("Add")}
      </Action>
    </x.div>
  );
};

const Header = () => {
  return (
    <x.div ph2 pv20p row sflex bbgray200 f11>
      <Cell bold flex60>
        {t("Name")}
      </Cell>
      <Cell bold flex33 textright>
        {t("Art.nr.")}
      </Cell>
      <Cell bold flex33 textright>
        {t("Returned qty.")}
      </Cell>
      <Cell bold flex33 textright>
        {t("Issued qty.")}
      </Cell>
      <Cell bold flex33 textright>
        {t("Allocated qty.")}
      </Cell>
    </x.div>
  );
};

const Row = (props: { row: OrderItem }) => {
  const { row } = props;
  return (
    <x.div ph2 pv20p row sflex bbgray100 f12 bggray50="hover" pointer>
      <Cell flex60>{row.type.name}</Cell>
      <Cell flex33 textright>
        {row.type.number}
      </Cell>
      <Cell flex33 textright>
        {row.returned}
      </Cell>
      <Cell flex33 textright>
        {row.issued}
      </Cell>
      <Cell flex33 textright>
        {row.allocated}
      </Cell>
    </x.div>
  );
};

export const Items = (props: Props) => {
  const { order } = props;

  const [component, update] = useComponent(
    order.components,
    "items",
    (components) => {
      props.onUpdate({
        components,
      });
    },
    {
      type: "items",
      items: [],
    }
  );

  return (
    <x.div flex column btgray200 bgwhite gray900>
      <Actions component={component} onUpdate={update} />
      <Header />
      {component.items.map((p, index) => (
        <Row row={p} key={index} />
      ))}
    </x.div>
  );
};
