import {
  Chart,
  ChartAggregate,
  ChartDataSource,
  ChartType,
  FieldDef,
  t,
  time,
  User,
} from "@maintmark/shared";
import { data, useContext } from "@maintmark/shared-react";
import { ModalProps } from "@maintmark/shared-react/src/ui/modals";
import { DropdownList, TextInput, x } from "@maintmark/shared-web/src/ui";
import cuid from "cuid";
import React from "react";
import { EditXYChart } from "./EditXYChart";
import { getDataSourceLabel, useDataSources } from "./sources";

export interface EditChartProps {
  chart: Chart;
  onUpdate: (chart: Chart) => void;
}

function renderChart(chart: Chart, onUpdate: (chart: Chart) => void) {
  switch (chart.type) {
    case "line": {
      return <EditXYChart chart={chart} onUpdate={onUpdate} />;
    }
    case "bar": {
      return <EditXYChart chart={chart} onUpdate={onUpdate} />;
    }
  }
  return null;
}

function getChartTypeLabel(type: ChartType) {
  switch (type) {
    case "bar": {
      return t("Bar chart");
    }
    case "line": {
      return t("Line chart");
    }
    case "pie": {
      return t("Pie chart");
    }
  }
}

const chartTypes: ChartType[] = ["bar", "line", "pie"];

function createChart(
  type: ChartType,
  user: User,
  fields: FieldDef<any>[],
  previous?: Chart
): Chart {
  const base = {
    __typename: "Chart" as "Chart",
    id: previous ? previous.id : cuid(),
    name: previous?.name || "",
    createdAt: time.now(),
    createdBy: user,
    source: "Order" as "Order",
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 12,
    },
  };

  const defaultFieldId = fields.filter((f) => f.itemType === "Order")[0].id;

  switch (type) {
    case "pie": {
      return {
        type,
        ...base,
      };
    }
    case "line":
    case "bar": {
      return {
        type,
        ...base,
        xAxis: {
          fieldId: defaultFieldId,
        },
        yAxis: {
          aggregate: ChartAggregate.Average,
          fieldId: defaultFieldId,
        },
      };
    }
  }
}

export const EditChartForm = (props: EditChartProps) => {
  const { user } = useContext();
  const fields = data.fieldDefs.useStore((store) => store.all(), []);
  const { chart, onUpdate } = props;

  const typeItems = React.useMemo(
    () =>
      chartTypes.map((type) => ({
        children: getChartTypeLabel(type),
        onClick: () => onUpdate(createChart(type, user, fields, chart)),
      })),
    [chart, user]
  );

  const sources = useDataSources(
    React.useCallback(
      (source: ChartDataSource) => {
        onUpdate({
          ...chart,
          source,
        });
      },
      [chart]
    )
  );

  return (
    <x.div fill column>
      <x.div pv1>
        <x.div ph2 pt1 mb1>
          <x.div row gap1>
            <TextInput
              name={t("Name")}
              value={chart.name}
              onUpdate={(name) =>
                onUpdate({
                  ...chart,
                  name,
                })
              }
              flex
              autoFocus
            />

            <DropdownList
              name={t("Data source")}
              text={getDataSourceLabel(chart.source)}
              hidden
              flex
              items={sources}
            />
          </x.div>
        </x.div>

        <x.div ph2>
          <DropdownList
            name={t("Type")}
            text={getChartTypeLabel(chart.type)}
            hidden
            flex
            items={typeItems}
          />
        </x.div>

        {renderChart(chart, onUpdate)}
      </x.div>
    </x.div>
  );
};
