import { ui } from "@maintmark/shared";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle<typeof ui.theme>`
  html,
  body {
    min-height: 100%;
  }

  #root {
    width: 100vw;
    height: 100vh;

    margin: 0;
    padding: 0;
  }

  body {
    overflow: overlay;
    margin: 0;
    font-family: "Neometric-Regular";
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  ul, li {
    margin:0;
    padding: 0;
    text-indent: 0;
    list-style-type: 0;
  }

  input, textarea {
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  textarea::placeholder, input::placeholder {
    color: ${({ colors }) => colors.gray[300]};
  }

  input, textarea {
    padding: 0;
    outline-width: 0;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  /* Remove up/down buttons from input[type="number"] */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none; /*remove the resize handle on the bottom right*/  
  }

  div.hover-bgray300:hover {
    box-shadow:inset 0px 0px 0px 1px ${({ colors }) => colors.gray[300]};
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes blinkIn {
    0% { opacity: 0; }
    25% { opacity: 1; }
    75% { opacity: 0; }
    100% { opacity: 1; }
  }

  /* img {
    image-rendering: pixelated;
  } */
`;
