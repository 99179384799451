import { createStore } from "../store";
import * as mocked from "./mocked";

export const groups = createStore("Group", {
  userId: mocked.userId,
  initial: mocked.groups,
  search: (term, users) => {
    return users.filter(
      (user) => user.name.toLowerCase().indexOf(term.toLowerCase()) >= 0
    );
  },
});
