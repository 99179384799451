import React from "react";
import { data, Order, OrderUrgency, t, time } from "@maintmark/shared";
import { useContext, useRecommendedTimeReport } from "@maintmark/shared-react";
import { MenuButton, MenuButtonProps, x } from "@maintmark/shared-web/src/ui";
import * as actions from "src/actions/order";
import { ComboBox } from "src/ui/components";
import cuid from "cuid";

export interface MenuItemGroup {
  children: string;
  subItems: MenuItem[];
  md?: boolean;
}

export type MenuSeparator = "separator";

export type MenuItem = MenuItemGroup | MenuButtonProps | MenuSeparator;

function orderTitle(order: Order) {
  if (order.asset) {
    return t("{name} ({asset})", {
      name: order.name,
      asset: order.asset.name,
    });
  } else {
    return order.name;
  }
}

export function useMenuItems(
  item: Order,
  sendNotification: (body: { title: string; message: string }) => void
) {
  const timeReports = useRecommendedTimeReport(item, 4);

  const openComments = React.useCallback(() => {
    actions.edit(item.id, "comments");
  }, [item.id]);

  const { user } = useContext();

  return React.useCallback(
    (closeMenu: () => void) => {
      const items: MenuItem[] = [];

      if (!item.closedAt) {
        items.push({
          onClick: () => {
            data.orders.update(item.id, (draft) => {
              draft.closedAt = time.now();
            });
            closeMenu();
          },
          children: t("Mark as done"),
        });

        items.push({
          children: t("Assign to"),
          subItems: [
            ...data.users.all().map((u) => ({
              children: u.name,
              onClick: () => {
                data.orders.update(item.id, {
                  assignedTo: u,
                });
                closeMenu();
              },
            })),
            {
              children: t("Other..."),
              onClick: () => {},
              dimmed: true,
              icon: "arrow",
            },
          ],
        });

        items.push({
          children: t("Report time"),
          subItems: [
            ...timeReports.map((report) => ({
              children: time.formatDuration(report.duration, false),
              onClick: () => {
                data.orders.update(item.id, (draft) => {
                  draft.reportedTime.push(report);
                });
                closeMenu();
              },
            })),
            {
              children: t("Other..."),
              onClick: () => {},
              dimmed: true,
              icon: "arrow",
            },
          ],
        });

        items.push({
          children:
            item.urgency === OrderUrgency.Urgent
              ? t("Set as normal prio")
              : t("Set as urgent"),
          onClick: () => {
            data.orders.update(item.id, {
              urgency:
                item.urgency === OrderUrgency.Urgent
                  ? OrderUrgency.Normal
                  : OrderUrgency.Urgent,
            });
            closeMenu();
          },
        });

        items.push({
          icon: "arrow",
          children: t("Add sub work order"),
          renderChild: () => (
            <x.div br6p mask shadowPopup>
              <ComboBox
                onSearch={() => {}}
                ItemComponent={MenuButton}
                itemProps={data.orders
                  .all()
                  .slice(0, 5)
                  .map((option) => ({
                    children: option.name,
                    onClick: () => {
                      data.orders.update(item.id, (draft) => {
                        draft.subOrders.push(option);
                      });
                      closeMenu();
                    },
                  }))}
              />
            </x.div>
          ),
        });

        items.push({
          onClick: () => {
            openComments();
            closeMenu();
          },
          children: t("Add comment"),
        });

        if (item.assignedTo) {
          const params = {
            title: orderTitle(item),
            priority:
              item.urgency === OrderUrgency.Urgent
                ? t("Urgent priority")
                : t("Normal priority"),
            dueDate: item.startsAt
              ? t("due date set to {date}", {
                  date: time.formatShortDate(item.startsAt),
                })
              : t("no due date set"),
          };

          items.push("separator");

          items.push({
            children: t("Send notification"),
            md: true,
            subItems: [
              {
                children: t("Notify about changes"),
                onClick: () => {
                  sendNotification({
                    message: t(
                      "Work order has been updated: {title}. {priority} with {dueDate}. Please review the details.",
                      params
                    ),
                    title: t("Work order updated: {title}", params),
                  });
                  closeMenu();
                },
              },
              {
                children: t("Send a reminder"),
                onClick: () => {
                  sendNotification({
                    message: t(
                      "This is a reminder about work order: {title}. {priority} with {dueDate}. Please review the details.",
                      params
                    ),
                    title: t("Work order reminder: {title}", params),
                  });
                  closeMenu();
                },
              },
              {
                children: t("Request feedback"),
                onClick: () => {
                  sendNotification({
                    message: t(
                      "Your feedback is requested on work order {title}. Please provide feedback as a comment on this issue as soon as possible.",
                      params
                    ),
                    title: t("Feedback requested: {title}", params),
                  });
                  closeMenu();
                },
              },
            ],
          });
        }
      } else {
        items.push({
          onClick: () => {
            data.orders.update(item.id, (draft) => {
              draft.closedAt = undefined;
            });
            closeMenu();
          },
          children: t("Reopen"),
        });
      }

      items.push("separator");

      items.push({
        onClick: () => {
          // data.orders.delete(item.id);

          data.templates.insert({
            id: cuid(),
            __typename: "Template",
            createdAt: time.now(),
            createdBy: user,
            order: item,
          });

          closeMenu();
        },
        children: t("Convert to template"),
      });

      items.push({
        onClick: () => {
          data.orders.delete(item.id);
          closeMenu();
        },
        children: t("Delete"),
      });

      return items;
    },
    [item]
  );
}
