import { Pos } from "@maintmark/shared";
import React, { DependencyList } from "react";

let _mousePos: Pos = { x: 0, y: 0 };
const _mouseContext = React.createContext(_mousePos);

export function useMouseMove(
  target: Element | Window | null,
  cb: (event: MouseEvent) => void,
  deps: DependencyList
) {
  React.useEffect(() => {
    if (!target) {
      return;
    }
    target.addEventListener("mousemove", cb as (event: Event) => void);
    return () => {
      target.removeEventListener("mousemove", cb as (event: Event) => void);
    };
  }, [...deps, target]);
}

export function useMouseUp(
  target: Element | Window | null,
  cb: (event: MouseEvent) => void,
  deps: DependencyList
) {
  React.useEffect(() => {
    if (!target) {
      return;
    }
    target.addEventListener("mouseup", cb as (event: Event) => void);
    return () => {
      target.removeEventListener("mouseup", cb as (event: Event) => void);
    };
  }, [...deps, target]);
}

export function useMouseWheel(
  target: Element | Window | null,
  cb: () => void,
  deps: DependencyList[]
) {
  React.useEffect(() => {
    if (!target) {
      return;
    }
    target.addEventListener("wheel", cb);
    return () => {
      target.removeEventListener("wheel", cb);
    };
  }, [...deps, target]);
}

export const MouseProvider = (props: React.PropsWithChildren<{}>) => {
  const [mousePosition, setMousePosition] = React.useState<Pos>(_mousePos);

  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      _mousePos = { x: ev.clientX, y: ev.clientY };
      setMousePosition(_mousePos);
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return React.createElement(_mouseContext.Provider, {
    value: mousePosition,
    children: props.children,
  });
};

export function useMousePosition() {
  return React.useContext(_mouseContext);
}

export function getMousePos() {
  return _mousePos;
}
