import React from "react";
import { x } from "@maintmark/shared-web/src/ui";

export interface TabProps {
  sm?: boolean;
  active?: boolean;
  onClick: () => void;
}

export const Tab = (props: React.PropsWithChildren<TabProps>) => {
  const { active, sm, children, ...other } = props;
  return (
    <x.div
      mr20p
      pointer
      row
      centers
      f16={!sm}
      f14={sm}
      p900
      opacity40={!active}
      pv1
      relative
      {...other}
    >
      {children}
      <x.div h3p bgp900 bottom w100 opacity0={!active} />
    </x.div>
  );
};

type Content<T> = {
  [P in keyof T]: (elements: React.ReactNode) => React.ReactNode;
};

export function useTabs<T extends { [key: string]: string }>(
  data: T,
  tabProps?: Partial<TabProps>
): {
  tabs: React.ReactNode;
  tabsContent: Content<T>;
} {
  const keys = Object.keys(data);
  const [active, setActive] = React.useState<keyof T>(keys[0]);

  return {
    tabs: (
      <>
        {keys.map((key) => (
          <Tab
            active={active === key}
            onClick={() => setActive(key)}
            {...tabProps}
          >
            {data[key]}
          </Tab>
        ))}
      </>
    ),
    tabsContent: keys.reduce(
      (m, k) => ({
        ...m,
        [k]: (content) => (active === k ? content : null),
      }),
      {} as Content<T>
    ),
  };
}
