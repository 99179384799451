import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../extend2";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const canvas = extend<
  HTMLCanvasElement,
  CSSProperties | undefined,
  React.HTMLAttributes<HTMLCanvasElement>,
  typeof styles,
  typeof props
>("canvas", styles, {}, props);

export type canvasProps = ExtractPropType<typeof canvas>;
