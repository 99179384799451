import { data as baseData } from "@maintmark/shared";
import { withReact } from "../store";

const data = {
  orders: withReact(baseData.orders),
  users: withReact(baseData.users),
  assets: withReact(baseData.assets),
  groups: withReact(baseData.groups),
  filters: withReact(baseData.filters),
  fieldDefs: withReact(baseData.fieldDefs),
  tabDefs: withReact(baseData.tabDefs),
  workflows: withReact(baseData.workflows),
  prompts: withReact(baseData.prompts),
  messages: withReact(baseData.messages),
  templates: withReact(baseData.templates),
  dashboards: withReact(baseData.dashboards),
};

export { data };
