import React from "react";
import { t } from "@maintmark/shared";
import SearchImg from "./images/search.png";
import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";
import { alpha } from "@maintmark/shared/src/ui";
import { colors } from "@maintmark/shared/src/ui/theme";

const Input = styled((props: x.inputProps & { placeholderColor: string }) => {
  const { placeholderColor, ...other } = props;
  return <x.input {...other} />;
})`
  & {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${(props) => props.placeholderColor};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${(props) => props.placeholderColor};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${(props) => props.placeholderColor};
    }
  }
`;

export const Search = React.memo(
  (
    props: Omit<x.divProps, "onChange"> & {
      placeholderColor?: string;
      onChange: (term: string) => void;
      autoFocus?: x.inputProps["autoFocus"];
    }
  ) => {
    const {
      onChange,
      placeholderColor = colors.primary[900],
      autoFocus,
      ...other
    } = props;

    const [focus, setFocus] = React.useState(false);
    const [value, setValue] = React.useState("");

    return (
      <x.div
        h40p
        br24p
        row
        centers
        relative
        bwidth50
        mask
        w160p={!focus}
        w200p={focus}
        transition200
        {...other}
      >
        <x.div fill bgwhite opacity70={!focus} />
        <x.div fill h100 row centers ml1 noevents>
          <x.img src={SearchImg} />
        </x.div>
        <Input
          fill
          value={value}
          ph2
          spellCheck={false}
          ml8p
          f14
          placeholder={t("Search...")}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholderColor={alpha(placeholderColor, 0.5)}
          autoFocus={autoFocus}
          flex
          onFocus={() => setFocus(true)}
          onBlur={() => {
            props.onChange("");
            setValue("");
            setFocus(false);
          }}
          light={!focus}
        />
      </x.div>
    );
  }
);
