import axios, { AxiosRequestConfig } from "axios";

function getHeaders(token?: string) {
  let headers: AxiosRequestConfig<any>["headers"] = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return headers;
}

export async function post<T>(url: string, body: any, token?: string) {
  console.log(">>>>>>>>>>>> headers", getHeaders(token));
  const response = await axios.post<T>(url, body, {
    headers: getHeaders(token),
  });

  return response.data;
}

export async function get<T>(url: string, token?: string) {
  const response = await axios.get<T>(url, {
    headers: getHeaders(token),
  });

  return response.data;
}
