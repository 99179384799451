import React, { CSSProperties } from "react";
import {
  Filter,
  WorkflowPhase,
  t,
  Workflow,
  assignTo,
  getFilterResult,
} from "@maintmark/shared";
import _ from "lodash";
import { Menu, MenuButton, Popup, x } from "@maintmark/shared-web/src/ui";
import { KanbanBoard } from "./KanbanBoard";
import { KanbanItem } from "./types";
import { DropTarget, useDropTarget } from "../droppable";
import { data, useCreateOrder, useFilterItems } from "@maintmark/shared-react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import cuid from "cuid";
import styled from "styled-components";
import { DroppableList } from "../components";
import { constants } from "@maintmark/shared/src/ui/theme";
import MenuImg from "./images/menu@3x.png";
import { useCreate } from "src/actions/order";

interface Props<T extends KanbanItem> {
  ItemComponent: React.ComponentType<{
    item: T;
    dragProps: DraggableProvidedDragHandleProps;
  }>;
  filter: Filter | null;
  workflowId: string;
}

interface LaneProps<T extends KanbanItem> {
  phase: WorkflowPhase;
  workflow: Workflow;
  ItemComponent: React.ComponentType<{
    item: T;
  }>;
  items: T[];
  target: DropTarget<"kanban">;
}

const grid = constants.gridSize;

const itemStyle: CSSProperties = {
  marginBottom: `${grid}px`,
};

function addPhase(workflow: Workflow) {
  data.workflows.update(workflow.id, {
    phases: [
      ...workflow.phases,
      {
        id: cuid(),
        name: "",
      },
    ],
  });
}

function deletePhase(workflow: Workflow, phase: WorkflowPhase) {
  data.workflows.update(workflow.id, (draft) => {
    draft.phases = draft.phases.filter((p) => p.id !== phase.id);
  });
}

function updateName(workflow: Workflow, phase: WorkflowPhase, name: string) {
  data.workflows.update(workflow.id, (draft) => {
    draft.phases.forEach((p) => {
      if (p.id !== phase.id) {
        return;
      }
      p.name = name;
    });
  });
}

const Lane = styled(
  <T extends KanbanItem>(props: LaneProps<T> & x.divProps) => {
    const { phase, workflow, target, ...other } = props;
    const [active, setActive] = React.useState(false);
    const [edit, setEdit] = React.useState(phase.name.length === 0);

    const renderMenu = React.useCallback(
      (close: () => void) => {
        React.useEffect(() => {
          setActive(true);
          return () => setActive(false);
        }, []);
        return (
          <Menu sm pv10p>
            <MenuButton
              onClick={() => {
                addPhase(workflow);
                close();
              }}
            >
              {t("Add phase")}
            </MenuButton>
            <MenuButton
              onClick={() => {
                setEdit(true);
                close();
              }}
            >
              {t("Edit name")}
            </MenuButton>
            <MenuButton
              onClick={() => {
                deletePhase(workflow, phase);
                close();
              }}
            >
              {t("Delete")}
            </MenuButton>
          </Menu>
        );
      },
      [workflow, phase]
    );

    const createOrder = useCreate();

    return (
      <x.div flex {...other} minw0p column>
        <x.div
          centers
          centerp
          white
          ph1
          mb10p
          p900
          bbp800
          noselect
          row
          className="header"
        >
          {edit ? (
            <x.input
              placeholder={t("Enter name...")}
              flex
              mr1
              opacity90
              medium
              f16
              autoFocus
              value={phase.name}
              onChange={(event) =>
                updateName(workflow, phase, event.target.value)
              }
              onBlur={() => setEdit(phase.name.length === 0)}
            />
          ) : (
            <x.div flex mr1 opacity90 medium f16>
              {phase.name}
            </x.div>
          )}

          <Popup renderContent={renderMenu} align="right">
            <x.div w1 row centerp pointer>
              <x.img
                className="wf-menu"
                src={MenuImg}
                width={6}
                height={20}
                pv10p
                opacity0
                opacity100={active}
              />
            </x.div>
          </Popup>
        </x.div>
        <x.div
          br6p
          bp900
          bdashed
          f12
          row
          centers
          centerp
          pv10p
          mb10p
          opacity40
          opacity100="hover"
          pointer
          light
          p900
          onClick={() => {
            const order = createOrder();
            data.orders.update(order.id, (draft) => {
              assignTo(draft, getFilter(phase), 0);
            });
          }}
        >
          {t("Create new")}
        </x.div>

        <DroppableList
          target={target}
          ItemComponent={props.ItemComponent}
          items={props.items}
          itemStyle={itemStyle}
        />
      </x.div>
    );
  }
)`
  max-width: 260px;

  & .header:hover {
    & .wf-menu {
      opacity: 1;
    }
  }
`;

const NullState = (props: { onAdd: () => void }) => {
  return (
    <x.div
      onClick={props.onAdd}
      pointer
      bp900
      bdashed
      pv1
      row
      w200p
      centers
      centerp
      br12p
      opacity60
      f12
    >
      {t("Create the first phase")}
    </x.div>
  );
};

function getFilter(phase: WorkflowPhase) {
  const id = `phase###${phase.id}`;
  return {
    id,
    query: `orders[rank.filterId="${id}"]`,
    title: "",
    patches: [],
  };
}

export const WorkflowPhases = <T extends KanbanItem>(props: Props<T>) => {
  const workflow = data.workflows.useItem(props.workflowId);
  const { phases } = workflow;

  const orders = useFilterItems(
    data.orders.useStore((store) => store.all(), []),
    props.filter
  );

  const encodeTarget = useDropTarget<"kanban", Filter>(
    "kanban",
    ({ style, setStyle }) => {
      if (style) {
        setStyle(style);
      }
    },
    ({ target, itemId, index }) => {
      data.orders.update(itemId, (draft) => {
        assignTo(draft, target, index);
      });
    },
    []
  );
  return (
    <x.div flex column ph48p relative>
      {phases.length === 0 ? (
        <NullState onAdd={() => addPhase(workflow)} />
      ) : (
        <KanbanBoard>
          {phases.map((phase, index) => {
            const filter = getFilter(phase);
            return (
              <Lane
                key={index}
                items={getFilterResult(filter, orders)}
                ItemComponent={props.ItemComponent as any}
                target={encodeTarget(filter)}
                phase={phase}
                workflow={workflow}
              />
            );
          })}
          {/* <x.div
          br6p
          bp900
          bdashed
          h2
          row
          centers
          centerp
          ph2
          f12
          pointer
          onClick={() => {
            data.workflows.update(workflow.id, {
              phases: [
                ...workflow.phases,
                {
                  id: cuid(),
                  name: "Phase",
                },
              ],
            });
          }}
        >
          {t("+ Create phase")}
        </x.div> */}
        </KanbanBoard>
      )}
    </x.div>
  );
};
