import { Asset, data, t } from "@maintmark/shared";
import React from "react";
import { ComboBox } from "../../components";
import { x } from "@maintmark/shared-web/src/ui";
import DownImg from "../../images/down-sm-gray300@3x.png";
import { Label, MenuButton, Popup } from "@maintmark/shared-web/src/ui";
import { useRecommendedAssets } from "@maintmark/shared-react";

interface Props {
  asset: Asset | undefined;
}

const MenuItem = (props: { item: Asset; onUpdate: (asset: Asset) => void }) => {
  const { item, onUpdate } = props;

  return <MenuButton onClick={() => onUpdate(item)}>{item.name}</MenuButton>;
};

const List = (props: { onUpdate: (asset: Asset | undefined) => void }) => {
  const { onUpdate } = props;
  const [term, setTerm] = React.useState("");

  const recos = useRecommendedAssets(10);

  const itemProps = React.useMemo(() => {
    let assets = term !== "" ? data.assets.all() : data.assets.search(term);
    return assets.map((item) => ({
      item,
      onUpdate,
    }));
  }, [term, recos, onUpdate]);

  return (
    <ComboBox
      ItemComponent={MenuItem}
      itemProps={itemProps}
      onSearch={setTerm}
      w100
    />
  );
};

interface AssetInputProps extends Props {
  onUpdate: (asset: Asset | undefined) => void;
}

export const AssetInput = (props: AssetInputProps & x.divProps) => {
  const { asset, onUpdate, ...other } = props;
  const [open, setOpen] = React.useState(false);

  const renderDrop = React.useCallback(
    (close: () => void) => {
      return (
        <List
          onUpdate={(asset) => {
            close();
            onUpdate(asset);
          }}
        />
      );
    },
    [onUpdate]
  );

  return (
    <x.div column {...other} minw0p>
      <Label active={open}>{t("Asset")}</Label>

      <Popup renderContent={renderDrop} onToggle={setOpen} fullwidth>
        <x.div
          h40p
          f14
          p900
          bgray200
          bgray300={open}
          br6p
          ph1
          pointer
          row
          centers
          relative
          bgwhite
        >
          {asset?.name}
          <x.div right mr1>
            <x.img src={DownImg} width={10} height={7} />
          </x.div>
        </x.div>
      </Popup>
    </x.div>
  );
};
