import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../extend";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const textarea = extend<
  HTMLTextAreaElement,
  CSSProperties | undefined,
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  typeof styles,
  typeof props
>("textarea", styles, {}, props);

export type textareaProps = ExtractPropType<typeof textarea>;
