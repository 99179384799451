import { colors } from "@maintmark/shared/src/ui/theme";
import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";

export const HoverItem = styled((props: x.divProps) => {
  return <x.div {...props} />;
})`
  &:hover {
    background-color: ${colors.gray[100]};
  }
`;
