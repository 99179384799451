import { Assignee } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import GroupImg from "./images/group@3x.png";

export const Avatar = styled((props: { assignee: Assignee } & x.divProps) => {
  const { assignee, ...other } = props;

  return (
    <x.div circle bgp600 w32p h32p centers centerp column {...other}>
      {props.assignee.__typename === "User" ? (
        <x.div f12 black white center className="name">
          {props.assignee.short}
        </x.div>
      ) : (
        <x.img src={GroupImg} width={20} height={13} mb3p />
      )}
    </x.div>
  );
})`
  & .name {
    letter-spacing: 1px;
  }
`;
