import React from "react";

type ScrollPosition = "bottom" | "top";

export function useScroll<T extends Element = Element>(options?: {
  initial?: ScrollPosition;
}): [React.MutableRefObject<T | null>, (pos: ScrollPosition) => void] {
  const { initial } = options || {};

  const scrollRef = React.useRef<T | null>(null);

  const scrollTo = React.useCallback((pos: ScrollPosition) => {
    const element = scrollRef.current;
    if (!element) {
      return;
    }

    switch (initial) {
      case "bottom": {
        element.scrollTop = element.scrollHeight;
        // setTimeout(() => {
        //   scrollRef.current!.scrollIntoView({
        //     behavior: "smooth",
        //     block: "end",
        //     inline: "nearest",
        //   });
        // }, 1000);
        break;
      }
      case "top": {
        element.scrollTop = 0;
        break;
      }
    }
  }, []);

  React.useEffect(() => {
    if (scrollRef.current && initial) {
      scrollTo(initial);
    }
  }, [scrollRef.current, initial]);

  return [scrollRef, scrollTo];
}
