import React from "react";
import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";
import cn from "classnames";
import { SidePanelContent } from "./types";

const width = 660;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  content: SidePanelContent<any> | null;
}

export const Container = styled((props: x.divProps & Props) => {
  const { isOpen, content } = props;
  return (
    <x.div className={cn(props.className, { open: isOpen })}>
      <x.div
        className="outside"
        onClick={() => props.onClose()}
        bgblack={isOpen}
        opacity20
        transition300
      />
      <x.div className="panel" shadowPopup bggray100 column transition300>
        {content ? (
          <content.Component {...content.props} onClose={props.onClose} />
        ) : null}
      </x.div>
    </x.div>
  );
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  & .outside {
    position: absolute;
    top: 0;
    left: 0;
    right 0;
    bottom: 0;
  }

  & .panel {
    position: absolute;
    top: 0;
    right: ${-width}px;
    width: ${width}px;
    bottom: 0;
  }

  &.open {
    pointer-events: auto;

    .panel {
      right: 0px;
    }
  }
`;
