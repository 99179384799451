import * as _ from "lodash";
import { addListener, emit, EventResult } from "../events";
import { t } from "../locale";
import { Order } from "../types";
import { getSummaries, PatchOperations } from "../patch";
import { createStore, Operation } from "../store";
import * as mocked from "./mocked";
import { noticeEvent } from "../notifications";
import { time } from "../time";
import { users } from "./users";
import cuid from "cuid";
import { match } from "../search";

export const ops: PatchOperations<Order> = {
  assignedTo: {
    replace: (assignee) => t("Assigned to {value}", { value: assignee.name }),
  },
  startsAt: {
    replace: (date) =>
      date
        ? t("Start date {value}", {
            value: time.formatRelativeDate(date),
          })
        : t("Removed start date"),
  },
  closedAt: {
    add: () => t("Marked as done"),
    replace: (value) => (value ? t("Marked as done") : t("Reopened")),
  },
  deletedAt: {
    add: () => t("Deleted"),
    replace: () => t("Deleted"),
  },
  reportedTime: {
    add: (report) =>
      t("Reported {duration} of work", {
        duration: time.formatDuration(report.duration),
      }),
  },
};

export const orders = createStore("Order", {
  userId: mocked.userId,
  initial: mocked.orders,
  onUpdate: (store, draft) => {
    if (!draft.number) {
      // TODO: Fix me!
      draft.number = "83882";

      // (
      //   (_.max(store.all().map((item) => parseInt(item.number) || 0)) || 0) + 1
      // ).toString();
    }
    return draft;
  },
  search: (term, orders) => {
    return orders.filter(
      (o) =>
        match(o, term, ["description", "name", "number"]) ||
        match(o.assignedTo, term, ["name"]) ||
        (o.asset && match(o.asset, term, ["name", "number"]))
    );
  },
});

addListener(orders.changeEvent, (event) => {
  switch (event.change.op) {
    case Operation.Update: {
      const item = event.store.get(event.change.itemId);
      const { fwd } = event.change;
      const summaries = getSummaries(fwd, ops);
      if (summaries.length > 0) {
        emit(
          noticeEvent({
            id: cuid(),
            title: item.name,
            item,
            text: summaries[0],
            createdAt: event.change.createdAt,
            createdBy: users.get(event.change.createdById).name,
            undo: () => {
              orders.revert(event.change);
            },
          })
        );
      }
      return EventResult.Handled;
    }
  }
  return EventResult.NotHandled;
});
