import { Chart, ChartAggregate, t } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import { usePortal } from "@maintmark/shared-web/src/ui/popup/portal";
import cuid from "cuid";
import React from "react";
import styled from "styled-components";
import { showSidePanel } from "src/ui/sidepanel";
import { EditChartForm } from "./form/EditChartForm";
import PenImg from "./images/pen@3x.png";
import DragImg from "./images/drag@3x.png";
import { BarChartView } from "./BarChartView";
import { LineChartView } from "./LineChartView";
import { PieChartView } from "./PieChartView";
import _ from "lodash";

interface Props {
  chart: Chart;
  onUpdate: (chart: Chart) => void;
}

function renderChart(size: "md" | "sm", chart: Chart) {
  switch (chart.type) {
    case "bar": {
      return <BarChartView chart={chart} size={size} />;
    }
    case "line": {
      return <LineChartView chart={chart} size={size} />;
    }
    case "pie": {
      return <PieChartView size={size} />;
    }
  }
}

export const ChartView = styled((props: Props & x.divProps) => {
  const { children, chart, ...other } = props;
  const size = chart.layout.w > 4 ? "md" : "sm";
  const sm = size === "sm";
  const md = size === "md";

  const click = React.useCallback(async () => {
    showSidePanel(EditChartForm, {
      onUpdate: () => {},
      chart,
    });

    // if (result === "cancel") {
    //   return;
    // }

    // setChart(result);
  }, [chart]);

  return (
    <x.div bgwhite br12p column h100 {...other}>
      <x.div row pv20p={sm} ph20p={sm} p2={md}>
        <x.div p900 f24={md} f16={sm} flex noselect>
          {chart.name}
        </x.div>
        <x.div row>
          <x.div className="drag" opacity20 opacity100="hover" pointer mr5p>
            <x.img src={DragImg} w24p={md} h24p={md} w16p={sm} h16p={sm} />
          </x.div>
          <x.div onClick={click} opacity20 opacity100="hover" pointer>
            <x.img src={PenImg} w24p={md} h24p={md} w16p={sm} h16p={sm} />
          </x.div>
        </x.div>
      </x.div>
      <x.div flex sflex minh0p>
        {renderChart(size, chart)}
      </x.div>
    </x.div>
  );
})``;
