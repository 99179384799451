import { getToken } from "../auth";
import { post } from "../http";
import { apiUrl } from "./utils";

export async function notify(body: { title: string; message: string }) {
  const token = await getToken();
  const result = await post<{ status: string }>(apiUrl("notify"), body, token);
  if (result.status !== "ok") {
    throw new Error("Error while calling notify.");
  }
}
