import { Chart, FieldDef, XYChart } from "@maintmark/shared";
import { data } from "@maintmark/shared-react";
import React from "react";

export function useFields(
  chart: XYChart<any>,
  onSelect: (field: FieldDef<any>) => void
) {
  const fields = data.fieldDefs.useStore(
    (store) => store.filter((item) => item.itemType === chart.source),
    [chart]
  );

  return React.useMemo(
    () =>
      fields.map((field) => ({
        children: field.name,
        onClick: () => onSelect(field),
      })),
    [fields, onSelect]
  );
}
