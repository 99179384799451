import { DateTime } from "./types";

interface Item {
  createdAt?: DateTime;
}

function defaultTime(dt?: DateTime) {
  return dt || 0;
}

type Order = "desc" | "asc";

export function sortCreatedAt(order: Order) {
  return (...args: Item[]) => {
    const [a, b] = order === "asc" ? args : [args[1], args[0]];
    return defaultTime(a.createdAt) - defaultTime(b.createdAt);
  };
}
