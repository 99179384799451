import * as React from "react";
import { Storage } from "@maintmark/shared";
import { removeInPlaceIf } from "@maintmark/shared/src/utils";

export async function getItem<T extends object>(
  name: string,
  defaultValue: T
): Promise<T> {
  const data = await localStorage.getItem(name);

  console.log(">>>>>>>>>>>>> get item", name, data);
  if (data === null) {
    return defaultValue;
  }
  return {
    ...defaultValue,
    ...JSON.parse(data),
  };
}

export function setItem<T extends object>(name: string, value: T) {
  localStorage.setItem(name, JSON.stringify(value));
}

export function create<T extends object>(
  name: string,
  defaultValue: T
): Storage<T> {
  type Listener = (value: T) => void;
  let listeners: Listener[] = [];

  return {
    get() {
      return getItem(name, defaultValue);
    },
    set(value: T) {
      setItem(name, value);
      listeners.forEach((l) => l(value));
    },
    use() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [value, setValue] = React.useState(defaultValue);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [loaded, setLoaded] = React.useState(false);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        getItem(name, defaultValue).then((value) => {
          setValue(value);
          setLoaded(true);
        });

        listeners.push(setValue);
        return () => {
          removeInPlaceIf(listeners, (item) => item === setValue);
        };
      }, []);

      return [value, loaded];
    },
  };
}
