import { colors } from "@maintmark/shared/src/ui/theme";
import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";

const distance = 6;
const size = 11;

export const Spinner = styled((props: x.divProps) => {
  return (
    <x.div {...props}>
      <x.div />
      <x.div />
      <x.div />
      <x.div />
    </x.div>
  );
})`
  display: inline-block;
  position: relative;
  width: ${distance * 6 + size}px;
  height: ${size}px;
  & div {
    position: absolute;
    top: 0px;
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    background: ${colors.primary[100]};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: 0px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 0px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
    left: ${distance * 3}px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
    left: ${distance * 6}px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(${distance * 3}px, 0);
    }
  }
`;
