import { orders } from "./orders";
import { users } from "./users";
import { assets } from "./assets";
import { groups } from "./groups";
import { filters } from "./filters";
import { fieldDefs } from "./fields";
import { prompts } from "./prompts";
import { messages } from "./messages";
import { tabDefs } from "./tabs";
import { workflows } from "./workflows";
import { templates } from "./templates";
import { dashboards } from "./dashboards";

const data = {
  orders,
  users,
  assets,
  groups,
  filters,
  fieldDefs,
  tabDefs,
  workflows,
  prompts,
  messages,
  templates,
  dashboards,
};
export { data };
