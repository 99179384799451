import { Diff } from "deep-diff";
import _ from "lodash";
import { t } from "./locale";

type Interpolator<P> = (value: P) => string;

const lookup: { [P in Diff<any, any>["kind"]]: "replace" | "add" | "remove" } =
  {
    A: "replace",
    D: "remove",
    E: "replace",
    N: "add",
  };

type Value<T, P extends keyof T> = T[P] extends Array<any> ? T[P][0] : T[P];

export type PatchOperations<T> = {
  [P in keyof T]?: {
    replace?: Interpolator<Value<T, P>>;
    add?: Interpolator<Value<T, P>>;
    remove?: Interpolator<Value<T, P>>;
  };
};

function getValue<T, P extends keyof T>(patch: Diff<T, T>): Value<T, P> {
  switch (patch.kind) {
    // indicates a change occurred within an array
    case "A": {
      return patch.item as Value<T, P>;
    }
    // indicates a property/element was deleted
    case "D": {
      return patch.lhs as Value<T, P>;
    }
    // indicates a property/element was edited
    case "E": {
      return patch.rhs as Value<T, P>;
    }
    // indicates a newly added property/element
    case "N": {
      return patch.rhs as Value<T, P>;
    }
  }
}

export function getSummaries<T>(
  patches: Diff<T, T>[],
  ops: PatchOperations<T>
) {
  const summaries: string[] = [];
  for (const patch of patches) {
    // TODO: Change this to an algorithm that identify exactly what
    // property has changed (like ["assignedTo", "id"]) and produces
    // a result based on that.
    if (!patch.path || patch.path.length !== 1) {
      continue;
    }

    const path = patch.path[0] as keyof T;
    const op = ops[path];
    if (!op) {
      continue;
    }

    const template = op[lookup[patch.kind]];
    if (!template) {
      continue;
    }

    summaries.push(template(getValue(patch)));
  }

  return summaries;
}
