import { useContext } from "@maintmark/shared-react";
import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";
import { ChatMessage } from "@maintmark/shared";

interface Props {
  message: ChatMessage;
}

export const MessageItem = styled((props: Props) => {
  const { message, ...other } = props;
  const { user } = useContext();
  const isSelf = user.id === message.senderUserId;

  return (
    <x.div endp={isSelf} row {...other}>
      <x.div
        pv10p
        ph1
        // bgray200={isSelf}
        // bgray300={!isSelf}
        bgray300
        bggray200={!isSelf}
        bgwhite={isSelf}
        p900={isSelf}
        gray900={!isSelf}
        mb1
        br12p
        f14
        className="item"
      >
        {message.text}
      </x.div>
    </x.div>
  );
})`
  & .item {
    max-width: 245px;
  }
`;
