import { x } from "@maintmark/shared-react/src/ui/web";
import React from "react";
import { Menu, MenuItem, renderMenuItem } from "../../components";
import { Dropdown, DropdownProps } from "./Dropdown";

interface Props {
  items: MenuItem[];
}

export const DropdownList = (
  props: Props & Omit<DropdownProps, "renderContent"> & x.divProps
) => {
  const { items, ...other } = props;
  const renderMenu = React.useCallback(
    (close: () => void) => {
      return (
        <Menu mt3p pv10p onItemClick={close}>
          {items.map((item) => renderMenuItem(item))}
        </Menu>
      );
    },
    [items]
  );

  return <Dropdown {...other} renderContent={renderMenu} />;
};
