import React from "react";
import { alpha, DateTime, Pos, t, theme, time } from "@maintmark/shared";
import _ from "lodash";
import { useResizeObserver } from "@maintmark/shared-web/src/ui";
import { useDrag } from "@maintmark/shared-react/src/ui";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";

const HEIGHT = 30;
const NARROW_THRESHOLD = 100;
const HANDLE_WIDTH = 12;

const Background = React.memo(
  styled(
    (
      props: {
        width: number;
        majorDelta: number;
        minorDelta: number;
        offset: number;
        getLabel: (value: number) => string | null;
        isMajor: (minorIndex: number) => boolean;
      } & x.divProps
    ) => {
      const { width, minorDelta, offset, className, getLabel, isMajor } = props;
      const minorStartIndex = Math.ceil(offset / minorDelta);
      const start = minorStartIndex * minorDelta - offset;

      return (
        <x.div fill className={className} bgwhite>
          {_.range(start, width, minorDelta).map((left, index) => {
            const minorIndex = index + minorStartIndex;
            const label = getLabel(minorIndex);
            return (
              <x.div>
                <x.div
                  h100p
                  bgp900
                  className={isMajor(minorIndex) ? "major" : "minor"}
                  style={{
                    left,
                  }}
                >
                  {label && <Label>{label}</Label>}
                </x.div>
              </x.div>
            );
          })}
        </x.div>
      );
    }
  )`
    .major {
      position: absolute;
      width: 1px;
      top: 5px;
      height: ${HEIGHT - 10}px;
      background-color: ${alpha(theme.colors.primary[900], 0.5)};
    }
    .minor {
      position: absolute;
      width: 1px;
      top: 12px;
      height: ${HEIGHT - 24}px;
      background-color: ${alpha(theme.colors.primary[900], 0.4)};
    }
  `
);

const Dimmer = React.memo((props: { x1: number; x2: number } & x.divProps) => {
  const { x1, x2, ...other } = props;
  return (
    <x.div
      style={{
        position: "absolute",
        left: x1,
        width: x2 - x1,
        height: HEIGHT,
        background: alpha(theme.colors.primary[50]!, 0.65),
      }}
      {...other}
    />
  );
});

const Handle = React.memo(
  styled(
    (
      props: {
        onDragHandle: (pos: number) => void;
        pos: number;
        right?: boolean;
        getLabel: (minorIndex: number) => string;
      } & x.divProps
    ) => {
      const { onDragHandle, pos, className, getLabel } = props;
      const [ref, activate, dragging] = useDrag(onDragHandle, pos, [
        onDragHandle,
      ]);

      return (
        <x.div className={className}>
          <x.div
            className="handle"
            pointer
            ref={ref}
            column
            centerp
            onMouseDown={activate}
          >
            <x.div h16p bgwhite br12p bp900 row centers centerp>
              <x.div bgp900 w1p h6p mr2p />
              <x.div bgp900 w1p h6p />
            </x.div>
          </x.div>
          {dragging && (
            <x.div noselect className="label" f11 medium white bgp900 br6p pv5p>
              {getLabel(pos)}
            </x.div>
          )}
        </x.div>
      );
    }
  )`
    .handle {
      position: absolute;
      left: ${(p) => p.pos}px;
      height: ${HEIGHT}px;
      width: ${HANDLE_WIDTH}px;
    }

    .label {
      position: absolute;
      left: ${(p) => p.pos - 45 + HANDLE_WIDTH / 2}px;
      top: -24px;
      width: 90px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      white-space: nowrap;
    }
  `
);

const Label = styled((props: x.divProps) => {
  return <x.div noselect f10 ml5p p900 opacity80 {...props} />;
})`
  margin-top: 12px;
  white-space: nowrap;
`;

const Selection = styled(
  (
    props: {
      x1: number;
      x2: number;
      onDragSelection: (x1: number) => void;
    } & x.divProps
  ) => {
    const { x1, x2, onDragSelection, ...other } = props;
    const [ref, activate] = useDrag(onDragSelection, x1, []);

    return <x.div pointer ref={ref} onMouseDown={activate} {...other}></x.div>;
  }
)`
  position: absolute;
  left: ${(p) => p.x1}px;
  width: ${(p) => p.x2}px;
  height: ${HEIGHT}px;
`;

interface Period {
  start: number;
  length: number;
}

function getOffsetAndMinorDelta(range: Period, width: number) {
  const minorPixelDelta = width / ((range.length / time.days(1)) * 3);
  return {
    offset: ((range.start - length) / time.days(1)) * minorPixelDelta,
    minorPixelDelta: minorPixelDelta,
  };
}

function getTimeByMinorIndex(minorIndex: number) {
  return minorIndex * time.days(1);
}

function getTimeByPixel(pos: number, offset: number, minorPixelDelta: number) {
  const minorIndex = Math.round((offset + pos) / minorPixelDelta);
  return getTimeByMinorIndex(minorIndex);
}

function getPixelByTime(tm: number, offset: number, minorPixelDelta: number) {
  const minorIndex = tm / time.days(1);
  return minorPixelDelta * minorIndex - offset;
}

const Window = React.memo(styled(
  (
    props: {
      initial: Period;
      onUpdate: (range: Period) => void;
    } & x.divProps
  ) => {
    const [minorPixelDelta, setMinorPixelDelta] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [x1, setX1] = React.useState(0);
    const [x2, setX2] = React.useState(100);
    const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
    const [width, setWidth] = React.useState<null | number>(null);
    const min = 0;
    const max = width;

    React.useEffect(() => {
      if (minorPixelDelta <= 0) {
        return;
      }

      const start = getTimeByPixel(x1, offset, minorPixelDelta);
      const length = getTimeByPixel(x2, offset, minorPixelDelta) - start;

      props.onUpdate({
        start,
        length,
      });
    }, [x1, x2, offset, minorPixelDelta, props.onUpdate]);

    React.useEffect(() => {
      const { initial } = props;
      if (width && minorPixelDelta === 0) {
        const data = getOffsetAndMinorDelta(initial, width);
        setMinorPixelDelta(data.minorPixelDelta);
        setOffset(data.offset);
        const xx1 = getPixelByTime(
          initial.start,
          data.offset,
          data.minorPixelDelta
        );
        const xx2 = getPixelByTime(
          initial.start + initial.length,
          data.offset,
          data.minorPixelDelta
        );
        setX1(xx1);
        setX2(xx2);
      }
    }, [width, minorPixelDelta]);

    React.useEffect(() => {
      if (ref) {
        setWidth(ref.clientWidth);
      }
    }, [ref]);

    useResizeObserver(() => {
      if (ref) {
        setWidth(ref.clientWidth);
      }
    }, [ref]);

    const createClamp = (
      min: number,
      max: number,
      set: (pos: number) => void
    ) =>
      React.useCallback(
        (pos: number) => set(_.clamp(pos, min, max)),
        [min, max]
      );

    const minmax = React.useMemo(
      () => ({
        x1: {
          min,
          max: Math.min(max || 0, x2) - HANDLE_WIDTH,
        },
        x2: {
          min: Math.max(min, x1) + HANDLE_WIDTH,
          max: (max || 0) - HANDLE_WIDTH,
        },
      }),
      [x1, x2, min, max]
    );

    const clampX1 = createClamp(minmax.x1.min, minmax.x1.max, setX1);
    const clampX2 = createClamp(minmax.x2.min, minmax.x2.max, setX2);

    const selectionWidth = React.useMemo(() => x2 - x1, [x1, x2]);

    const dragX1 = React.useCallback(
      (pos: number) => {
        if (pos < 10) {
          setMinorPixelDelta((d) => d * 0.99999);
        } else if (selectionWidth < 100) {
          setMinorPixelDelta((d) => d * 1.00001);
        }
        clampX1(pos);
      },
      [clampX1, selectionWidth]
    );

    const dragX2 = React.useCallback(
      (pos: number) => {
        if (minmax.x2.max - pos < 10) {
          setMinorPixelDelta((d) => d * 0.985);
        } else if (selectionWidth < 100) {
          setMinorPixelDelta((d) => d * 1.015);
        }
        clampX2(pos);
      },
      [clampX2, minmax.x2.max, selectionWidth]
    );

    const containerStyle = React.useMemo(
      () => ({
        width: width || 0,
        height: HEIGHT,
        backgroundColor: alpha(theme.colors.primary[900], 0.03),
      }),
      [width]
    );

    const dragSelection = React.useCallback(
      (pos: number) => {
        if (pos < minmax.x1.min) {
          pos = minmax.x1.min;
        } else if (pos + selectionWidth > minmax.x2.max) {
          pos = minmax.x2.max - selectionWidth;
        }

        let ux1 = Math.max(minmax.x1.min, pos);
        let ux2 = Math.min(minmax.x2.max, ux1 + selectionWidth);

        setX1(ux1);
        setX2(ux2);
      },
      [selectionWidth, minmax]
    );

    const majorScale = React.useMemo(() => {
      if (minorPixelDelta < 5) {
        return "month";
      } else {
        return "week";
      }
    }, [minorPixelDelta]);

    const getTime = React.useCallback((minorIndex: number) => {
      return minorIndex * time.days(1);
    }, []);

    const isMajor = React.useCallback(
      (minorIndex: number) => {
        const tm = getTime(minorIndex);
        switch (majorScale) {
          case "week": {
            return time.isFirstDayOfWeek(tm);
          }
          case "month": {
            return time.isFirstDayOfMonth(tm);
          }
        }
      },
      [majorScale, getTime]
    );

    const getMajorLabel = React.useCallback(
      (minorIndex: number) => {
        const tm = getTime(minorIndex);
        switch (majorScale) {
          case "week": {
            if (time.isFirstDayOfWeek(tm)) {
              return time.format(tm, "E dd/MM");
            }
            break;
          }
          case "month": {
            if (time.isFirstDayOfMonth(tm)) {
              return time.format(tm, "MMM");
            }
          }
        }
      },
      [majorScale]
    );

    const getLabelByPixel = React.useCallback(
      (pos: number) => {
        if (minorPixelDelta <= 0) {
          return "";
        }
        const tm = getTimeByPixel(pos, offset, minorPixelDelta);
        return time.format(tm, "yyyy-MM-dd");
      },
      [getTimeByPixel, offset, minorPixelDelta]
    );

    return (
      <x.div {...props} relative ref={setRef} flex br12p bgray200 shadowLight>
        {max && (
          <x.div row relative style={containerStyle}>
            <Background
              width={max}
              minorDelta={minorPixelDelta}
              offset={offset}
              isMajor={isMajor}
              getLabel={getMajorLabel}
            />
            <Dimmer x1={0} x2={x1 + HANDLE_WIDTH / 2} brp900 brl6p />
            <Dimmer x1={x2 + HANDLE_WIDTH / 2} x2={max} blp900 brr6p />
            <Selection x1={x1} x2={x2} onDragSelection={dragSelection} />
            <Handle
              onDragHandle={dragX1}
              pos={x1}
              right
              getLabel={getLabelByPixel}
            />
            <Handle
              onDragHandle={dragX2}
              pos={x2}
              right={false}
              getLabel={getLabelByPixel}
            />
          </x.div>
        )}
      </x.div>
    );
  }
)`
  height: ${HEIGHT}px;
  .container {
    width: max;
    height: HEIGHT;
    backgroundcolor: ${alpha(theme.colors.primary[900], 0.03)};
  }
`);

interface Props {
  initial: Period;
  onUpdate: (period: Period) => void;
}

export const TimeNavigator = (props: Props & x.divProps) => {
  const { onUpdate, ...other } = props;

  const initial = React.useMemo(() => props.initial, []);

  return (
    <x.div row {...other}>
      <Window initial={initial} onUpdate={onUpdate} />
    </x.div>
  );
};
