import React from "react";
import {
  time,
  kanbans,
  Filter,
  DateTime,
  assignTo,
  Order,
} from "@maintmark/shared";
import _ from "lodash";
import { x } from "@maintmark/shared-web/src/ui";
import { KanbanBoard } from "./KanbanBoard";
import { KanbanLane } from "./KanbanLane";
import { KanbanItem } from "./types";
import { DropTarget, useDropTarget } from "../droppable";
import { data, useFilterItems } from "@maintmark/shared-react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

interface Props<T extends KanbanItem> {
  date: number | Date;
  getItems: (date: number | Date, items: T[]) => T[];
  ItemComponent: React.ComponentType<{
    item: T;
    dragProps: DraggableProvidedDragHandleProps;
  }>;
  filter: Filter | null;
}

const Lane = <T extends KanbanItem>(props: {
  target: DropTarget<"kanban">;
  filter: Filter;
  orders: Order[];
  ItemComponent: React.ComponentType<{ item: T }>;
  date: DateTime;
}) => {
  const { target, date, filter, orders } = props;
  const items = useFilterItems(orders, filter);

  return (
    <KanbanLane
      key={filter.id}
      target={target}
      title={filter.title}
      subTitle={time.format(date, "d")}
      items={items}
      ItemComponent={props.ItemComponent as any}
    />
  );
};

export const WeekKanban = <T extends KanbanItem>(props: Props<T>) => {
  const start = time.startOfWeek(props.date);

  const lanes = kanbans.getLaneFilters({
    __typename: "WeekKanban",
    id: `WeekKanban:${start}`,
    startOfWeek: start,
  });

  const encodeTarget = useDropTarget<"kanban", Filter>(
    "kanban",
    ({ style, setStyle }) => {
      if (style) {
        setStyle(style);
      }
    },
    ({ target, itemId, index }) => {
      data.orders.update(itemId, (draft) => {
        assignTo(draft, target, index);
      });
    },
    []
  );

  const orders = useFilterItems(
    data.orders.useStore((store) => store.all(), []),
    props.filter
  );

  return (
    <x.div flex column ph48p relative>
      <KanbanBoard>
        {lanes.map((lane, offset) => {
          const date = time.addDays(start, offset);
          return (
            <Lane
              key={lane.id}
              orders={orders}
              target={encodeTarget(lane)}
              filter={lane}
              date={date}
              ItemComponent={props.ItemComponent as any}
            />
          );
        })}
      </KanbanBoard>
    </x.div>
  );
};
