import { t } from "@maintmark/shared";
import React from "react";
import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";
import { ui } from "@maintmark/shared-web";
import { Header } from "./Header";

const Search = (props: { onSearch: (term: string) => void }) => {
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSearch(event.target.value);
    },
    [props.onSearch]
  );

  return (
    <x.div row sflex ph1 mb5p bbgray200>
      <x.input
        h32p
        f12
        onChange={onChange}
        autoFocus
        placeholder={t("Search...")}
        flex
        gray800
      />
    </x.div>
  );
};

interface Props<T> {
  onSearch: (term: string) => void;
  ItemComponent: React.ComponentType<T>;
  itemProps: T[];
  title?: string;
}

const List = styled(x.ul)`
  height: 150px;
`;

export const ComboBox = <T,>(props: Props<T> & x.divProps) => {
  const { itemProps, ItemComponent, onSearch, title, ...other } = props;

  return (
    <ui.Menu pb5p {...other} brt0p={!title}>
      {title && <Header title={title} />}
      <Search onSearch={onSearch} />
      <List vscroll>
        {itemProps.map((p) => (
          <x.li>
            <ItemComponent {...(p as any)} />
          </x.li>
        ))}
      </List>
    </ui.Menu>
  );
};
