import { DateTime, t, time } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import React from "react";
import styled from "styled-components";

interface Period {
  start: DateTime;
  length: number;
}

interface Props {
  period: Period;
  onUpdate: (period: Period) => void;
}

export const TimeSelector = styled((props: Props & x.divProps) => {
  const { period } = props;
  return (
    <x.div
      f12
      p900
      opacity80
      opacity100="hover"
      pointer
      noselect
      medium
      w200p
      className={props.className}
    >
      <x.div f24 p900 light regular minw220p>
        {t("{month} {startDate}-{endDate}, {year}", {
          month: time.format(period.start, "MMM"),
          year: time.format(period.start, "yyyy"),
          startDate: time.format(period.start, "d"),
          endDate: time.format(period.start + period.length, "d"),
        })}
      </x.div>
    </x.div>
  );
})`
  .label {
    width: 75px;
  }
`;
