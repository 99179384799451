import {
  api,
  data,
  Order,
  OrderUrgency,
  t,
  time,
  wait,
} from "@maintmark/shared";
import styled from "styled-components";
import React from "react";
import MenuImg from "./images/menu@3x.png";
import _ from "lodash";
import { Menu, Popup, useHover, x } from "@maintmark/shared-web/src/ui";
import { Details } from "./Details";
import * as actions from "src/actions/order";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { MenuItem, useMenuItems } from "./menuitems";
import ArrowRightImg from "./images/right.png";

const Description = styled(x.div)`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

export const OrderItem = styled(
  (
    props: {
      item: Order;
      dragProps: DraggableProvidedDragHandleProps;
    } & x.divProps
  ) => {
    const { item, dragProps, ...other } = props;
    const urgent = item.urgency === OrderUrgency.Urgent;
    const completed = !!item.closedAt;

    const open = React.useCallback(() => {
      actions.edit(item.id);
    }, [item.id]);

    const [notification, setNotification] = React.useState<string | null>(null);

    async function sendNotification(body: { title: string; message: string }) {
      setNotification(t("Sending..."));
      try {
        await api.notify(body);
        setNotification(t("Done!"));
      } catch {
        setNotification(t("Error"));
      }

      await wait(2000);
      setNotification(null);
    }

    const createMenuItems = useMenuItems(item, sendNotification);

    const _renderMenu = React.useCallback(
      (close: () => void) => {
        return <Menu items={createMenuItems(close)} />;
      },
      [createMenuItems]
    );

    const [connectNode, setConectNode] = React.useState<HTMLDivElement | null>(
      null
    );

    const isConnectHovered = useHover({ node: connectNode }, []);
    const extraProps = isConnectHovered ? {} : dragProps;

    const [subVisible, setSubVisible] = React.useState(false);
    const toggleSubOrders = React.useCallback((event: React.MouseEvent) => {
      setSubVisible((v) => !v);
      event.stopPropagation();
    }, []);

    return (
      <x.div
        shadowPopup="hover"
        bgwhite={!completed}
        bggray200={completed}
        bgray300={completed}
        opacity60={completed}
        column
        br6p
        noselect
        relative
        mask
        pointer
        onClick={open}
        {...extraProps}
        {...other}
      >
        <x.div
          bgp600
          className="notification"
          h20p={!!notification}
          h0p={!notification}
          row
          centers
          ph20p
          white
          f12
          bold
          mask
        >
          {notification}
        </x.div>
        <x.div row mt1 ph20p pb1>
          <x.div column>
            <x.div row mb8p={item.description.length > 0}>
              <x.div f14 medium p900 flex mr5p>
                {item.name}
              </x.div>
            </x.div>
            <Description f12 light p900 maxh30p opacity70>
              {item.description}
            </Description>
          </x.div>
        </x.div>

        {item.asset && (
          <x.div h2 relative bbgray100 btgray100 row ph20p centers>
            <x.div f12 p900 mr5p flex tail>
              {item.asset.name}
            </x.div>
          </x.div>
        )}

        {item.subOrders.length > 0 && (
          <x.div
            bbgray100
            column
            bggray50={subVisible}
            transition300
            pv10p
            onClick={toggleSubOrders}
          >
            <x.div ph20p row centers pointer opacity70 opacity100="hover">
              <x.div uppercase f10 light flex>
                {t("Sub orders ({count})", { count: item.subOrders.length })}
              </x.div>
              <x.img
                src={ArrowRightImg}
                width={5}
                height={10}
                rotate90={subVisible}
                transition50
              />
            </x.div>

            <x.div
              maxh0p={!subVisible}
              maxh1000p={subVisible}
              transition300
              mask
            >
              {item.subOrders.slice(0, 4).map((item, index) => (
                <x.div
                  mt10p={index === 0}
                  key={item.id}
                  f12
                  medium
                  ph20p
                  opacity70
                  opacity100="hover"
                  pointer
                  pv5p
                  bggray100="hover"
                  onClick={(event) => {
                    actions.edit(item.id);
                    event.stopPropagation();
                  }}
                >
                  {item.name}
                </x.div>
              ))}
            </x.div>
          </x.div>
        )}
        <Details pl20p pr1 order={item} />

        <x.div right mr4p mt8p>
          <Popup align="right" renderContent={_renderMenu}>
            <x.div className="menu" w32p h32p row centers centerp pointer>
              <x.div fill bggray100 circle className="circle" />
              <x.div fill row centers centerp>
                <x.img src={MenuImg} width={6} height={20} />
              </x.div>
            </x.div>
          </Popup>
        </x.div>

        {urgent && (
          <x.div fill noevents>
            <x.div h100 w6p bgalert600 />
          </x.div>
        )}
      </x.div>
    );
  }
)`
  & .connector {
    position: absolute;
    opacity: 0;
    top: calc(50% - 8px);
    right: -5px;
  }

  &:hover {
    .connector {
      opacity: 1;
    }
  }

  .connector.connected {
    opacity: 1;
  }

  & .menu {
    opacity: 0;

    .circle {
      opacity: 0;
    }
  }

  &:hover .menu {
    opacity: 0.6;

    &:hover {
      opacity: 1;

      .circle {
        opacity: 1;
      }
    }
  }

  & .notification {
    transition: height 200ms ease;
  }

  .open > .menu {
    opacity: 1;

    .circle {
      opacity: 1;
    }
  }

  .heatmap-hover &:active {
    opacity: 0.3;
  }
`;
