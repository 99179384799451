import { DateTime, Filter, t, time } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import LeftImg from "./images/left@3x.png";
import RightImg from "./images/right@3x.png";

interface Props {
  date: DateTime;
  filter: Filter | null;
  onChangeDate: (date: DateTime) => void;
  onChangeFilter: (filter: Filter | null) => void;
}

export const TimePeriodEdit = styled(
  (props: { date: DateTime; onChange: (date: DateTime) => void }) => {
    const { date, onChange, ...other } = props;
    const weekBegin = time.startOfWeek(date);
    const weekEnd = time.addDays(weekBegin, 7);

    return (
      <x.div row centers opacity100 pointer noselect {...other}>
        <x.div f24 light p900 minw220p>
          {t("{month} {startDate}-{endDate}, {year}", {
            month: time.format(date, "MMM"),
            year: time.format(date, "yyyy"),
            startDate: time.format(weekBegin, "d"),
            endDate: time.format(weekEnd, "d"),
          })}
        </x.div>

        <x.div row className="controls" opacity0>
          <x.div
            opacity20
            opacity100="hover"
            sflex
            ph8p
            column
            centerp
            pointer="hover"
            onClick={() => onChange(time.addWeeks(weekBegin, -1))}
          >
            <x.img src={LeftImg} width={8} height={14} />
          </x.div>
          <x.div
            opacity20
            opacity100="hover"
            sflex
            ph8p
            column
            centerp
            pointer="hover"
            onClick={() => onChange(time.addWeeks(weekBegin, 1))}
          >
            <x.img src={RightImg} width={8} height={14} />
          </x.div>
        </x.div>
      </x.div>
    );
  }
)`
  &:hover {
    .controls {
      opacity: 1 !important;
    }
  }
`;
