import _ from "lodash";
import * as React from "react";

function addListener<T>(
  node: Node | Window,
  event: string,
  listener: EventListenerOrEventListenerObject
) {
  node.addEventListener(event, listener);
  return () => node.removeEventListener(event, listener);
}

export function useResizeObserver(
  callback: () => void,
  deps: React.DependencyList
) {
  React.useEffect(() => {
    return addListener(window, "resize", callback);
  }, deps);
}

const throttle = (fn: () => void, delay: number) => {
  let timer: null | ReturnType<typeof setTimeout> = null;
  return [
    () => {
      if (timer) {
        return;
      }
      timer = setTimeout(() => {
        timer = null;
        fn();
      }, delay);
    },
    () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    },
  ];
};

export function useHover(
  options: {
    delay?: number;
    node: Node | null;
  },
  deps: React.DependencyList
) {
  const [isOver, setIsOver] = React.useState(false);
  const { node, delay } = options;

  const [mouseOver, mouseLeave] = React.useMemo(() => {
    const over = () => {
      setIsOver(true);
    };
    const leave = () => setIsOver(false);
    if (delay && delay > 0) {
      console.log(">>>>>>>>> delayed");
      const [throttledOver, cancel] = throttle(over, delay);
      return [
        () => {
          throttledOver();
          console.log(">>>>>>>>>> haasdasd");
        },
        () => {
          cancel();
          leave();
        },
      ];
    } else {
      return [over, leave];
    }
  }, []);

  React.useEffect(() => {
    if (!node) {
      return;
    }
    return addListener(node, "mouseover", () => mouseOver());
  }, [...deps, node, mouseOver]);

  React.useEffect(() => {
    if (!node) {
      return;
    }
    return addListener(node, "mouseleave", mouseLeave);
  }, [...deps, node, mouseLeave]);

  return isOver;
}

export function useFocus(): [boolean, () => void, () => void] {
  const [focus, setFocus] = React.useState(false);

  const setFocused = React.useCallback(() => {
    setFocus(true);
  }, []);

  const setBlurred = React.useCallback(() => {
    setFocus(false);
  }, []);

  return [focus, setFocused, setBlurred];
}
