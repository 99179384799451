import pupa from "pupa";
import { strings } from "./agroflow";

export function t(text: string, data: { [key: string]: any } | null = null) {
  const translated = strings[text] || text;
  if (data) {
    return pupa(translated, data);
  }
  return translated;
}
