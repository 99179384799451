import React from "react";
// import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./ui/App";
import reportWebVitals from "./reportWebVitals";
import { enablePatches } from "immer";
import { render } from "react-dom";
import { Environment, setEnv } from "@maintmark/shared";

setEnv(process.env.NODE_ENV as Environment);

enablePatches();

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
