import styled from "styled-components";
import { x } from "@maintmark/shared-web/src/ui";

export const BusyItem = styled((props: x.divProps) => {
  return (
    <x.div row mb1 {...props}>
      <x.div bggray100 row ph1 br12p h35p centers>
        <x.div className="typing__dot"></x.div>
        <x.div className="typing__dot"></x.div>
        <x.div className="typing__dot"></x.div>
      </x.div>
    </x.div>
  );
})`
  .typing__dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
`;
