import { data, lookupify, Prompt, time } from "@maintmark/shared";
import cuid from "cuid";
import { Bot, Context } from "./types";

function buildCommand(cmd: string, bots: Bot[]) {
  let result = `Today is ${time.format(
    new Date(),
    "yyyy-MM-dd"
  )}. Answer the following questions as best you can. You have access to the following tools:\n\n`;

  result += `${bots
    .map((bot) => `${bot.name.toLowerCase()}: ${bot.description}`)
    .join("\n")}\n\n\n`;

  result += `Question: the input question you must answer
Thought: you should always think about what to do
Action: the action to take, should be one of [${bots.map((bot) =>
    bot.name.toLowerCase()
  )}]
Action Input: the input to the action
Observation: the result of the action (should be used in favour of the readable representation in subsequent actions)
Readable: a rewrite of the Observation that is short (preferably 1-3 words) and readable, dates in ISO8601 format
... (this Thought/Action/Action Input/Observation/Readable can repeat N times)
Thought: I now know the final answer
Final Answer: the final answer to the original input question

Begin!

Question: ${cmd}`;
  // Thought:`;

  return result;
}

function getAnswer(text: string): null | string {
  const regex = /Final Answer: (.*)/;

  if (!regex.test(text)) {
    return null;
  }

  return text.match(regex)![1];
}

export function createContext(
  main: Bot,
  other: Bot[],
  log: (text: string, args?: any) => void
): Context {
  const lookup = lookupify(other, (b) => b.name.toLowerCase());

  return {
    log(text: string, args?: any) {
      if (args) {
        log(text, args);
      } else {
        log(text);
      }
    },
    async run(command: string) {
      const pmpt: Prompt = {
        cmd: command,
        actions: [],
        id: cuid(),
      };

      data.prompts.insert(pmpt);

      const cmd = buildCommand(command, other);

      let prompt = "";
      let maxIterations = 5;

      function add(text: string) {
        log(text);
        prompt += text;
      }

      log(cmd);

      let index = -1;

      for (let i = 0; i < maxIterations; i++) {
        try {
          // Get instructions
          const result = await main.run(cmd + prompt, this);

          console.log(">>>>>>>>>>>>", cmd + prompt, result);

          if (!result) {
            return null;
          }

          if (index >= 0) {
            const [, observation] = result.match(/Readable: (.*?)\n/)!;

            data.prompts.update(pmpt, (draft) => {
              draft.actions[index] = {
                ...draft.actions[index],
                result: observation || "error",
              };
            });
          }

          const answer = getAnswer(result);

          if (answer) {
            add(result);
            return answer;
          }

          add(result);

          const [, thought] = result.match(/Thought: (.*?)\n/)!;

          data.prompts.update(pmpt, (draft) => {
            index = draft.actions.length;
            draft.actions.push({
              thought,
            });
          });

          const [, action, input] = result.match(
            /Action: (.*?)\nAction Input: (.*)/
          )!;

          // Run action
          const actionResult = await lookup[action.toLowerCase()].run(
            input,
            this
          );

          add(`Observation: ${actionResult}\n`);
        } catch (error) {
          console.log("An error occured", error);
          add("Action could not be completed.");
        }
      }

      return null;
    },
    getBot(description: string) {
      return main;
    },
  };
}
