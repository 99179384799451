import { data, Order, OrderUrgency, time } from "@maintmark/shared";
import { useContext, useDefaultAssignTo } from "@maintmark/shared-react";
import { orderFields, tabDefs } from "@maintmark/shared/src/data/mocked";
import cuid from "cuid";
import React from "react";

export function useCreateOrder() {
  const defaultAssignment = useDefaultAssignTo();
  const { user } = useContext();

  return React.useCallback((data?: Partial<Order>) => {
    const order: Order = {
      createdAt: time.now(),
      createdBy: user,
      description: "",
      name: "",
      number: "",
      urgency: OrderUrgency.Normal,
      __typename: "Order",
      comments: [],
      plannedTime: 0,
      reportedTime: [],
      assignedTo: defaultAssignment,
      subOrders: [],
      components: [],
      fields: {
        type: "fields",
        fields: [
          {
            def: orderFields.startsAt,
            value: "",
          },
          {
            def: orderFields.plannedTime,
            value: "",
          },
          {
            def: orderFields.reportedTime,
            value: "",
          },
        ],
      },
      tabs: {
        type: "tabs",
        tabs: [
          {
            def: tabDefs.description,
            value: "",
          },
        ],
      },
      ...data,
      id: (data && data.id) || cuid(),
    };
    return order;
  }, []);
}
