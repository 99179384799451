import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../extend";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const ul = extend<
  HTMLUListElement,
  CSSProperties | undefined,
  React.HTMLAttributes<HTMLUListElement>,
  typeof styles,
  typeof props
>("ul", styles, {}, props);

export type ulProps = ExtractPropType<typeof ul>;
