import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../../../x/extend";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const li = extend<
  HTMLLIElement,
  CSSProperties | undefined,
  React.HTMLAttributes<HTMLLIElement>,
  typeof styles,
  typeof props
>("li", styles, {}, props);

export type liProps = ExtractPropType<typeof li>;
