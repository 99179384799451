import { x } from "@maintmark/shared-web/src/ui";
import { ActionView } from "./ActionView";
import CloseImg from "../images/task-close@3x.png";
import { data, Prompt } from "@maintmark/shared";

interface Props {
  prompt: Prompt;
}

export const PromptView = (props: Props) => {
  const { prompt } = props;
  return (
    <x.div shadowPopup br6p mask>
      <x.div row pv12p bgwhite>
        <x.div ml20p lh140 medium f14 flex>
          {prompt.cmd}
        </x.div>
        <x.img
          ml1
          mt2p
          mr1
          src={CloseImg}
          width={16}
          height={16}
          opacity60
          opacity100="hover"
          pointer
          onClick={() => {
            data.prompts.delete(prompt.id);
          }}
        />
      </x.div>
      {prompt.actions.map((action) => (
        <ActionView action={action} />
      ))}
    </x.div>
  );
};
