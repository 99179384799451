import {
  EventResult,
  Notice,
  noticeEvent,
  t,
  time,
  ui,
} from "@maintmark/shared";
import React from "react";
import styled from "styled-components";
import { produce } from "immer";
import BellImg from "./images/bell@3x.png";
import cn from "classnames";
import { useMouseMove, useMouseWheel } from "../mouse";
import { x, useHover } from "@maintmark/shared-web/src/ui";
import { useEvent } from "@maintmark/shared-react";

const height = 60;
const width = 370;

const easing = "cubic-bezier(0.27, 0, 0, 1)";
const duration = 300;

const HoverScroll = (props: x.divProps & { cooldown: boolean }) => {
  const { cooldown, ...other } = props;
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);
  const [showScrollbar, setShowScrollbar] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [canScroll, setCanScroll] = React.useState(true);

  const touch = React.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => setShowScrollbar(false), 300);

    setShowScrollbar(true);
  }, []);

  useMouseMove(node, touch, []);
  useMouseWheel(node, touch, []);

  React.useEffect(() => {
    if (cooldown) {
      setCanScroll(false);
      setTimeout(() => {
        setCanScroll(true);
      }, 500);
    }
  }, [cooldown]);

  return (
    <x.div
      ref={setNode}
      {...other}
      className={cn(other.className, {
        showScrollbar: canScroll && showScrollbar,
      })}
    />
  );
};

const NoticeItem = styled(
  (
    props: x.divProps & {
      notice: Notice;
      canUndo: boolean;
      onUndo: (notice: Notice) => void;
    }
  ) => {
    const { notice, canUndo, onUndo, ...other } = props;
    const undo = React.useCallback(() => {
      notice.undo!();
      props.onUndo(notice);
    }, [notice]);

    return (
      <x.div row centers {...other}>
        <x.div column ml20p p900 centers>
          <x.div f16 semibold>
            {time.formatShortDate(notice.createdAt)}
          </x.div>
          <x.div f12>{time.formatTime(notice.createdAt)}</x.div>
        </x.div>
        <x.div column ml1 flex f12 p900>
          <x.div semibold>{notice.title}</x.div>
          <x.div>{notice.text}</x.div>
        </x.div>
        {canUndo && notice.undo ? (
          <x.div
            gray700
            pl2
            pr20p
            f12
            semibold
            uppercase
            pointer
            onClick={undo}
          >
            {t("Undo")}
          </x.div>
        ) : (
          <x.div h2 mr20p f12 p900>
            {notice.createdBy}
          </x.div>
        )}
      </x.div>
    );
  }
)`
  height: ${height}px;
  min-height: ${height}px;
  width: ${width}px;
`;

export const Notifications = styled((props: x.divProps) => {
  const [notice, setNotice] = React.useState<Notice | null>(null);
  const [notices, setNotices] = React.useState<Notice[]>([]);
  const [open, setOpen] = React.useState(false);
  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);

  const undo = React.useCallback((notice: Notice) => {
    setNotices((notices) => notices.filter((n) => n !== notice));
  }, []);

  useEvent(
    noticeEvent,
    (notice) => {
      setNotice(notice);
      setNotices((notices) =>
        produce(notices, (draft) => {
          draft.push(notice);
        })
      );
      return EventResult.Handled;
    },
    []
  );

  const isHover = useHover({ node }, []);

  React.useEffect(() => {
    if (!notice) {
      return;
    }
    setOpen(true);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setOpen(false);
      timeout.current = null;
    }, 3000);
  }, [notice]);

  return (
    <x.div
      ref={setNode}
      noselect
      bgwhite
      shadow100
      bgray200
      {...props}
      className={cn("circle", props.className, {
        open,
      })}
    >
      <x.div className="bell" row centers centerp>
        <x.div>
          <x.img src={BellImg} width={22} height={24} />
        </x.div>
      </x.div>

      {notices.length > 0 ? (
        <HoverScroll className="notices" fill columnr cooldown={isHover}>
          {notices
            .slice()
            .reverse()
            .map((notice, index) => (
              <NoticeItem
                key={notice.id}
                notice={notice}
                bbgray200
                canUndo={index === 0}
                onUndo={undo}
              />
            ))}
        </HoverScroll>
      ) : (
        <x.div
          className="notices"
          fill
          row
          centerp
          centers
          uppercase
          f12
          p900
          medium
        >
          {t("No notices")}
        </x.div>
      )}
    </x.div>
  );
})`
  position: fixed;
  bottom: ${ui.theme.constants.gridSize * 2}px;
  right: ${ui.theme.constants.gridSize * 2}px;
  width: ${height}px;
  height: ${height}px;
  border-radius: ${height / 2}px;
  transition: all ${duration}ms ${easing};
  overflow: hidden;

  .bell {
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${height}px;
    height: ${height}px;
    transition: all ${duration}ms ${easing};
  }

  .notices {
    opacity: 0;
    transition: all ${duration}ms ${easing};
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 7px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 6px;
      box-shadow: none;
      -webkit-border-radius: 0;
      -webkit-box-shadow: none;
    }
  }

  .notice {
    opacity: 0;
    transition: all ${duration}ms ${easing};
    pointer-events: none;
    position: absolute;
    bottom: 0px;
  }

  &.open,
  :hover {
    width: ${width}px;
    border-radius: 6px;

    .bell {
      opacity: 0;
    }
  }

  &.open {
    .notices {
      opacity: 1;
    }
  }

  &:hover {
    height: 400px;

    .notices {
      opacity: 1;
      overflow-y: auto;

      &.showScrollbar {
        overflow-y: auto;

        ::-webkit-scrollbar-thumb {
          background: ${ui.theme.colors.gray[200]};
        }
      }
    }
  }
`;
