import React from "react";
import { Navigate, Outlet } from "react-router";
import { tokenStorage } from "src/token";
import { Notifications } from "../notifications";

export const ProtectedRoute = (props: React.PropsWithChildren<{}>) => {
  const [{ token }, loaded] = tokenStorage.use();

  if (!loaded) {
    return null;
  }

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Outlet />
      <Notifications />
    </>
  );
};
