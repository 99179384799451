import * as React from "react";
import { CSSObject } from "./types";
import * as css from "./css";
import cn from "classnames";
import { hash } from "@maintmark/shared";
import { generateAlphabeticName } from "./name";
import _ from "lodash";

function getWidthBreak(maxWidth: number, type: "min" | "max") {
  return `@media (${type}-width: ${maxWidth}px)`;
}

const constraintsLookup = {
  "max-xs": (rule: CSSObject) => ({
    [getWidthBreak(576, "max")]: rule,
  }),
  "max-sm": (rule: CSSObject) => ({
    [getWidthBreak(767, "max")]: rule,
  }),
  "max-md": (rule: CSSObject) => ({
    [getWidthBreak(991, "max")]: rule,
  }),
  "max-lg": (rule: CSSObject) => ({
    [getWidthBreak(1199, "max")]: rule,
  }),
  "max-xl": (rule: CSSObject) => ({
    [getWidthBreak(1399, "max")]: rule,
  }),

  "min-xs": (rule: CSSObject) => ({
    [getWidthBreak(577, "min")]: rule,
  }),
  "min-sm": (rule: CSSObject) => ({
    [getWidthBreak(768, "min")]: rule,
  }),
  "min-md": (rule: CSSObject) => ({
    [getWidthBreak(992, "min")]: rule,
  }),
  "min-lg": (rule: CSSObject) => ({
    [getWidthBreak(1200, "min")]: rule,
  }),
  "min-xl": (rule: CSSObject) => ({
    [getWidthBreak(1400, "min")]: rule,
  }),

  hover: (rule: CSSObject) => {
    return Object.keys(rule).reduce(
      (r, key) => ({
        ...r,
        [key + ":hover"]: rule[key],
      }),
      {}
    );
  },
};

type ConstaintKeys = keyof typeof constraintsLookup;

type Props<X> = { [_ in keyof X]?: boolean | keyof typeof constraintsLookup };

export type ExtractPropType<Type> = Type extends React.ComponentType<infer X>
  ? X
  : never;

function getConstraints<P extends Props<any>>(props: P, key: keyof P) {
  const value = props[key];

  if (typeof value !== "string") {
    return [];
  }

  return value
    .split(",")
    .map((name) => (constraintsLookup as any)[name])
    .filter((c) => c);
}

export function extend<
  R,
  S,
  P extends { style?: S },
  XS extends { [key: string]: CSSObject },
  XP extends { [key: string]: Partial<P> } = {}
>(
  Component: React.ComponentType<P> | string,
  styles: XS,
  defaultProps?: P,
  extendedProps?: XP
) {
  return React.memo(
    React.forwardRef(
      (props: Props<XS & XP> & P, ref: React.ForwardedRef<R>) => {
        const classCheckRef = React.useRef("");
        const className = React.useRef("");
        const insertRules = css.useStyleRules();

        let classKeys: string[] = [];

        let outProps: Partial<P> = { ...defaultProps } as Partial<P>;
        let outStyle: Partial<S> = { ...defaultProps?.style };

        const keys = Object.keys(props) as (keyof P)[];

        keys.forEach((key) => {
          if (key === "children") {
            outProps[key] = props[key];
            return;
          }

          const pickedStyle = styles[key as string];
          const pickedProps = extendedProps && extendedProps[key as string];

          if (pickedStyle === undefined && pickedProps === undefined) {
            outProps[key] = props[key];
            return;
          }

          if (props[key] === undefined || props[key] === false) {
            return;
          }

          if (pickedStyle) {
            if (typeof pickedStyle === "object") {
              classKeys.push(key as string);
            } else {
              outStyle = {
                ...outStyle,
                ...(pickedStyle as any),
              };
            }
          }

          if (pickedProps) {
            outProps = {
              ...outProps,
              ...pickedProps,
            };
          }
        });

        const contentString = classKeys.map((x) => x + props[x]).join("&");

        if (classCheckRef.current !== contentString) {
          classCheckRef.current = contentString;

          const contentHash = generateAlphabeticName(hash(contentString));

          className.current = contentHash;

          const rules = classKeys.map((key) => {
            const style = styles[key];

            let rule = Object.keys(style).reduce((s, k) => {
              if (typeof style[k] === "object") {
                return {
                  ...s,
                  [`.${contentHash}${k}`]: {
                    ...(style[k] as any),
                    ...(s[`.${contentHash}${k}`] as any),
                  },
                };
              } else {
                return {
                  ...s,
                  [`.${contentHash}`]: {
                    [k]: style[k],
                    ...(s[`.${contentHash}`] as any),
                  },
                };
              }
            }, {} as CSSObject);

            for (let constraint of getConstraints(props, key)) {
              rule = constraint(rule);
            }

            return rule;
          });

          insertRules(contentHash, rules);
        }

        return React.createElement(Component, {
          ...(outProps as P),
          style: { ...outStyle, ...props.style },
          className: cn(className.current, props.className),
          ref,
        });
      }
    )
  );
}
