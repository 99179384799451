import { colors } from "@maintmark/shared/src/ui/theme";

export default {
  white: {
    color: colors.common.white,
  },
  gray200: {
    color: colors.gray[200],
  },
  gray300: {
    color: colors.gray[300],
  },
  gray400: {
    color: colors.gray[400],
  },
  gray500: {
    color: colors.gray[500],
  },
  gray600: {
    color: colors.gray[600],
  },
  gray700: {
    color: colors.gray[700],
  },
  gray800: {
    color: colors.gray[800],
  },
  gray900: {
    color: colors.gray[800],
  },
  p100: {
    color: colors.primary[100],
  },
  p200: {
    color: colors.primary[200],
  },
  p300: {
    color: colors.primary[300],
  },
  p500: {
    color: colors.primary[500],
  },
  p600: {
    color: colors.primary[600],
  },
  p700: {
    color: colors.primary[700],
  },
  p800: {
    color: colors.primary[800],
  },
  p900: {
    color: colors.primary[900],
  },
  alert600: {
    color: colors.alert[600],
  },
  mono: {
    fontFamily: "Courier",
  },
  f10: {
    fontSize: 10,
  },
  f11: {
    fontSize: 11,
  },
  f12: {
    fontSize: 12,
  },
  f13: {
    fontSize: 13,
  },
  f14: {
    fontSize: 14,
  },
  f15: {
    fontSize: 15,
  },
  f16: {
    fontSize: 16,
  },
  f18: {
    fontSize: 18,
  },
  f20: {
    fontSize: 20,
  },
  f24: {
    fontSize: 24,
  },
  f28: {
    fontSize: 28,
  },
  f36: {
    fontSize: 36,
  },
  f48: {
    fontSize: 48,
  },
  f50: {
    fontSize: 50,
  },
  f64: {
    fontSize: 64,
  },
  uppercase: {
    textTransform: "uppercase" as "uppercase",
  },
  center: {
    textAlign: "center" as "center",
  },
  textright: {
    textAlign: "right" as "right",
  },
};
