import React from "react";
import { useContext } from "./context";
import { data } from "./data";
import cuid from "cuid";
import { Order, time, TimeReport } from "@maintmark/shared";

export function useRecommendedAssignees(order: Order, count: number) {
  const groups = data.groups.useStore((store) => store.take(count), []);
  const users = data.users.useStore((store) => store.take(count), []);
  return React.useMemo(
    () =>
      [...groups, ...users]
        .filter((items) => items.id !== order.assignedTo.id)
        .slice(0, count),
    [groups, users, order.assignedTo.id]
  );
}

export function useRecommendedStartsAt(order: Order, count: number) {
  const today = time.today();
  return [
    today,
    time.addDays(today, 1),
    time.addDays(today, 2),
    time.addDays(time.startOfWeek(today), 7),
  ];
}

export function useRecommendedAssets(count: number) {
  return data.assets.useStore((store) => store.take(count), []);
}

export function useDefaultAssignTo() {
  const { user } = useContext();
  return user.groups[0] || user;
}

export function useRecommendedTimeReport(
  order: Order,
  count: number
): TimeReport[] {
  const shortcuts = [
    time.minutes(15),
    time.minutes(30),
    time.hours(1),
    time.hours(2),
  ];

  return shortcuts.slice(0, count).map((duration) => ({
    id: cuid(),
    __typename: "TimeReport",
    duration,
  }));
}
