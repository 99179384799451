import _ from "lodash";
import { WeekKanban } from "../kanban/WeekKanban";
import { Filter, time } from "@maintmark/shared";
import { OrderItem } from "../order/item/OrderItem";
import { Heatmap } from "../heatmap/Heatmap";
import React from "react";
import { x } from "@maintmark/shared-web/src/ui";
import { MainPage } from "../main/MainPage";
import { TimePeriodEdit } from "../main/TimePeriodEdit";
import { FilterEdit } from "../main/FilterEdit";

interface Props {
  onOpenChat: () => void;
}

export const Scheduler = (props: Props) => {
  const [filter, setFilter] = React.useState<Filter | null>(null);
  const [date, setDate] = React.useState(time.startOfWeek(new Date()));

  return (
    <MainPage onOpenChat={props.onOpenChat} filter={filter}>
      <x.div row ph48p>
        <TimePeriodEdit date={date} onChange={setDate} />
        <x.div flex />
        <FilterEdit onUpdate={setFilter} filter={filter} />
      </x.div>
      <x.div relative flex column>
        <Heatmap selected={date} onSelectDate={setDate} filter={filter} />
        <WeekKanban
          date={date}
          filter={filter}
          ItemComponent={OrderItem}
          getItems={(date, orders) =>
            orders.filter(
              (item) => item.startsAt && time.isSameDay(date, item.startsAt)
            )
          }
        />
      </x.div>
    </MainPage>
  );
};
