import * as _ from "lodash";
import { faker } from "@faker-js/faker";
import {
  Asset,
  Chart,
  ChartAggregate,
  Dashboard,
  FieldDef,
  FieldType,
  Filter,
  Group,
  Order,
  OrderUrgency,
  User,
  Workflow,
} from "../../types";
import { time } from "../../time";
import { t } from "../../locale";

faker.seed(3);

export const userId = "CA373F26-4745-4703-B460-64BA62A58F93";

export const fieldGroups = {
  builtIn: {
    id: "built-in",
    name: t("Built-in"),
  },
  custom: {
    id: "custom",
    name: t("Custom"),
  },
};

export let orderFields: { [key: string]: FieldDef<Order> } = {
  startsAt: {
    id: "startsAt",
    name: t("Start date"),
    type: FieldType.Date,
    group: fieldGroups.builtIn,
    itemType: "Order",
  },
  plannedTime: {
    id: "plannedTime",
    name: t("Estimated time"),
    type: FieldType.Duration,
    group: fieldGroups.builtIn,
    itemType: "Order",
  },
  reportedTime: {
    id: "reportedTime",
    name: t("Reported time"),
    type: FieldType.Duration,
    group: fieldGroups.builtIn,
    itemType: "Order",
  },
};

export let tabDefs = {
  description: {
    id: "description",
    name: t("Description"),
  },
  comments: {
    id: "comments",
    name: t("Comments"),
  },
  images: {
    id: "images",
    name: t("Images"),
  },
  changes: {
    id: "changes",
    name: t("Changes"),
  },
  attachments: {
    id: "attachments",
    name: t("Attachments"),
  },
  items: {
    id: "items",
    name: t("Items"),
  },
  skills: {
    id: "skills",
    name: t("Skills"),
  },
};

function randItem<T>(items: T[]) {
  return items[faker.datatype.number(items.length - 1)];
}

export const groups: Group[] = _.range(0, 3)
  .map(() => {
    return {
      __typename: "Group" as "Group",
      id: faker.datatype.uuid(),
      name: faker.company.name(),
      type: "org" as "org",
      children: [],
    };
  })
  .sort((a, b) => a.name.localeCompare(b.name));

const names: string[][] = [
  ["Lars", "Svensson"],
  ["Niklas", "Bengtsson"],
  ["Emma", "Johansson"],
  ["Anna", "Andersson"],
  ["David", "Eriksson"],
  ["Maria", "Lindgren"],
];

export const users: User[] = names
  .map(([firstName, lastName], index) => {
    const name = [firstName, lastName].join(" ");

    return {
      __typename: "User" as "User",
      id: index === 0 ? userId : faker.datatype.uuid(),
      firstName,
      lastName,
      name,
      short: firstName[0] + lastName[0],
      groups: [randItem(groups)],
    };
  })
  .sort((a, b) => a.name.localeCompare(b.name));

export const workflows: Workflow[] = [
  {
    __typename: "Workflow",
    id: faker.datatype.uuid(),
    name: "JUF Planering",
    phases: [
      {
        id: faker.datatype.uuid(),
        name: t("Att planera"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Att montera"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Hos kund"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Nedmonteras"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Klar"),
      },
    ],
  },
  {
    __typename: "Workflow",
    id: faker.datatype.uuid(),
    name: "Support",
    phases: [
      {
        id: faker.datatype.uuid(),
        name: t("Inbox"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Mottaget"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Pågår JUF"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Hos kund"),
      },
      {
        id: faker.datatype.uuid(),
        name: t("Åtgärdat"),
      },
    ],
  },
];

const assetData = [
  "Fence 2",
  "Fence 1",
  "Planting Machine 5",
  "Water Pipeline 3",
  "Sawmill 2",
  "Sewage System 1",
  "Milking Machine 4",
  "Feeder 2",
  "Planting Machine 1",
  "Western Lawn",
  "Tractor 3",
  "Cleaning Machine 2",
  "Machine 1",
  "Fence 2",
  "Water Supply 1",
  "Harvester 4",
  "Machine 3",
  "Barn 1",
  "Meadow C",
  "Barn",
  "Forest Area",
  "Garden B",
  "Shrubs and Hedges",
  "Buildings or Outdoor Lighting",
  "Northern Power Fence",
];

export const assets: Asset[] = assetData
  .map((name) => ({
    id: faker.datatype.uuid(),
    name,
    number: faker.random.numeric(4).toString(),
  }))
  .sort((a, b) => a.name.localeCompare(b.name));

const assignTo = [...users, ...groups];

const yearStart = time.startOfYear(time.now());
const yearDays = time.differenceInCalendarDays(
  time.addYears(yearStart, 1),
  yearStart
);

export let orders: Order[] = [];

const demoWeek = time.timeAt(new Date("2023-02-06"));
const demoDay = time.timeAt(new Date("2023-02-08"));

const mockedItems = [
  {
    number: "3274-332",
    name: "Fallskyddsnät 10 x 2m",
  },
  {
    number: "44321-332",
    name: "Förankringsrör",
  },
  {
    number: "9821-112",
    name: "Stålrör 3m",
  },
  {
    number: "55123-995",
    name: "Säkerhetslås",
  },
  {
    number: "77001-223",
    name: "Justerbar stödben",
  },
];
const titles: [string, string, string][] = [
  ["Fix Gates", "Repair or adjust gates on the farm.", "Fence 2"],
  ["Paint Fence", "Paint or stain the fence with new color.", "Fence 1"],
  ["Sow Seeds", "Sow seeds to cultivate crops or grass.", "Planting Machine 5"],
  [
    "Replace Hose",
    "Replace a broken hose on the water pipeline or other equipment.",
    "Water Pipeline 3",
  ],
  ["Cut Wood", "Cut pieces of wood to desired length or size.", "Sawmill 2"],
  [
    "Check Sewage",
    "Inspect the sewage system and clear any blockages.",
    "Sewage System 1",
  ],
  [
    "Milk Inspection",
    "Perform inspections and routine work in milk production.",
    "Milking Machine 4",
  ],
  [
    "Feed Cows",
    "Provide food and feed cows according to the correct schedule.",
    "Feeder 2",
  ],
  [
    "Plant Vegetables",
    "Plant vegetables in the vegetable garden or garden.",
    "Planting Machine 1",
  ],
  [
    "Mow Grass",
    "Mow the grass in fields, gardens, or other green areas.",
    "Western Lawn",
  ],
  [
    "Fix Tractor",
    "Repair or maintain the tractor to ensure its functionality.",
    "Tractor 3",
  ],
  [
    "Clear Ditch",
    "Clear debris and obstructions from ditches or drainage systems.",
    "Cleaning Machine 2",
  ],
  [
    "Change Filters",
    "Replace old or dirty filters in machines or vehicle engines.",
    "Machine 1",
  ],
  [
    "Repair Fences",
    "Repair damaged or broken fences to keep animals in place.",
    "Fence 2",
  ],
  [
    "Check Water",
    "Check the water supply and the quality of the water.",
    "Water Supply 1",
  ],
  [
    "Harvest Wheat",
    "Harvest mature wheat grains from the fields.",
    "Harvester 4",
  ],
  [
    "Clean Machine",
    "Clean machines and equipment to remove dirt and debris.",
    "Machine 3",
  ],
  [
    "Renovate Barn",
    "Perform renovation work on the barn to improve its structure or appearance.",
    "Barn 1",
  ],
  ["Mow Hay", "Harvest hay from Meadow C.", "Meadow C"],
  [
    "Clean Barn",
    "Clean and organize the barn to keep it clean and safe.",
    "Barn",
  ],
  [
    "Plant Trees",
    "Plant trees or young forest for forestry or landscaping purposes.",
    "Forest Area",
  ],
  ["Weed Garden", "Remove weeds from Garden B.", "Garden B"],
  [
    "Trim Hedges",
    "Trim and shape shrubs and hedges for a neat and well-maintained garden.",
    "Shrubs and Hedges",
  ],
  [
    "Replace Bulb",
    "Replace broken or burnt-out bulbs in buildings or outdoor lighting.",
    "Buildings or Outdoor Lighting",
  ],
  [
    "Check Electric Fence",
    "Inspect and repair electric fences to keep animals safe and within the designated area.",
    "Northern Power Fence",
  ],
];

_.range(0, 600).forEach((index) => {
  const startsAt = faker.datatype.boolean()
    ? time.addDays(yearStart, faker.datatype.number(yearDays - 1))
    : undefined;
  const history = startsAt && demoDay > startsAt;

  const [name, description, asset] = randItem(titles);

  orders.push({
    __typename: "Order" as "Order",
    name,
    description,
    id: faker.datatype.uuid(),
    createdAt: time.now(),
    createdBy: randItem(users),
    // name: _.capitalize(faker.lorem.words(faker.datatype.number(4) + 1)),
    assignedTo: randItem(users),
    asset: assets.filter((a) => a.name === asset)[0],
    number: faker.random.numeric(4).toString(),
    // description: faker.lorem.paragraph(),
    urgency: OrderUrgency.Normal,
    comments: _.range(0, faker.datatype.number(3)).map(() => ({
      __typename: "Comment" as "Comment",
      id: faker.datatype.uuid(),
      createdBy: randItem(users),
      createdAt:
        new Date().valueOf() - faker.datatype.number(14 * 24 * 60 * 1000),
      message: faker.lorem.sentence(10),
    })),
    closedAt: history ? startsAt : 0,
    deletedAt: 0,
    reportedTime: [],
    plannedTime: time.hours(24) * faker.datatype.number(1),
    startsAt,
    subOrders: [],
    components: [
      // {
      //   type: "items",
      //   items: mockedItems.map((item) => ({
      //     allocated: 10,
      //     issued: 0,
      //     returned: 0,
      //     type: {
      //       __typename: "Item",
      //       id: faker.datatype.uuid(),
      //       components: [],
      //       ...item,
      //     },
      //   })),
      // },
    ],
    fields: {
      type: "fields",
      fields: [
        {
          def: orderFields.plannedTime,
          value: "",
        },
        {
          def: orderFields.reportedTime,
          value: "",
        },
        {
          def: orderFields.startsAt,
          value: "",
        },
      ],
    },
    tabs: {
      type: "tabs",
      tabs: [
        {
          def: tabDefs.description,
          value: "",
        },
        {
          def: tabDefs.changes,
          value: "",
        },
        {
          def: tabDefs.attachments,
          value: "",
        },
        {
          def: tabDefs.items,
          value: "",
        },
      ],
    },
  });
});

// _.range(0, 15).forEach((index) => {
//   const sourceData = data[index % (data.length - 1)];
//   const startsAt = time.addDays(demoWeek, faker.datatype.number(7));
//   const history = demoDay > startsAt;

//   orders.push({
//     __typename: "Order" as "Order",
//     id: faker.datatype.uuid(),
//     createdAt: time.now(),
//     createdBy: randItem(users),
//     // name: _.capitalize(faker.lorem.words(faker.datatype.number(4) + 1)),
//     assignedTo: randItem(
//       assignTo.filter((a) => history || a.__typename === "Group")
//     ),
//     asset: randItem(assets),
//     number: faker.random.numeric(4).toString(),
//     // description: faker.lorem.paragraph(),
//     urgency: OrderUrgency.Normal,
//     comments: _.range(0, faker.datatype.number(3)).map(() => ({
//       __typename: "Comment" as "Comment",
//       id: faker.datatype.uuid(),
//       createdBy: randItem(users),
//       createdAt:
//         new Date().valueOf() - faker.datatype.number(14 * 24 * 60 * 1000),
//       message: faker.lorem.sentence(10),
//     })),
//     closedAt: history ? startsAt : 0,
//     deletedAt: 0,
//     reportedTime: [],
//     plannedTime: 0,
//     startsAt,
//     ...sourceData,
//   });
// });

// if (typeof this === "object") {
//   orders.push({
//     createdAt: 0,
//     assignedTo: users.find((u) => u.firstName === "Clayton")!,
//     comments: [],
//     id: faker.datatype.uuid(),
//     name: "Clean window",
//     description: "Use a cloth and window cleaner to clean the window",
//     number: "83882",
//     plannedTime: 0,
//     reportedTime: [],
//     urgency: OrderUrgency.Normal,
//     __typename: "Order",
//     startsAt: time.timeAt(new Date("2023-02-10")),
//     closedAt: 0,
//     deletedAt: 0,
//   });
// }

// orders
//   .filter((a) => a.name.indexOf("Error") === -1)
//   .sort((a, b) => a.name.localeCompare(b.name));

export const filters: Filter[] = [];

export const dashboards: Dashboard[] = [
  {
    id: faker.datatype.uuid(),
    name: "Overview",
    __typename: "Dashboard",
    charts: [
      {
        id: faker.datatype.uuid(),
        type: "pie",
        name: "Planned time",
        source: "Order",
        layout: {
          x: 0,
          y: 0,
          h: 2,
          w: 2,
        },
      },
      {
        id: faker.datatype.uuid(),
        type: "line",
        name: "Accumulated planned time",
        source: "Order",
        xAxis: {
          fieldId: "startsAt",
        },
        yAxis: {
          fieldId: "plannedTime",
          aggregate: ChartAggregate.Accumulate,
        },
        layout: {
          x: 0,
          y: 2,
          h: 1,
          w: 2,
        },
      },
      {
        id: faker.datatype.uuid(),
        type: "line",
        name: "Work orders per person",
        source: "Order",
        xAxis: {
          fieldId: "startsAt",
        },
        yAxis: {
          fieldId: "plannedTime",
          aggregate: ChartAggregate.Sum,
        },
        layout: {
          x: 2,
          y: 0,
          h: 3,
          w: 10,
        },
      },
      {
        id: faker.datatype.uuid(),
        type: "line",
        name: "Planned time",
        source: "Order",
        xAxis: {
          fieldId: "startsAt",
        },
        yAxis: {
          fieldId: "plannedTime",
          aggregate: ChartAggregate.Accumulate,
        },
        layout: {
          x: 0,
          y: 4,
          h: 2,
          w: 12,
        },
      },
    ],
  },
];
