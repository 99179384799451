import { ChartAggregate, t } from "@maintmark/shared";
import { MenuItem } from "@maintmark/shared-web/src/ui";
import React from "react";

export function getAggregateLabel(agg: ChartAggregate) {
  switch (agg) {
    case ChartAggregate.Average: {
      return t("Average");
    }
    case ChartAggregate.Count: {
      return t("Count");
    }
    case ChartAggregate.Max: {
      return t("Max");
    }
    case ChartAggregate.Min: {
      return t("Min");
    }
    case ChartAggregate.Sum: {
      return t("Sum");
    }
    case ChartAggregate.Accumulate: {
      return t("Accumulate");
    }
  }
}

export function useAggregateItems(
  onSelect: (agg: ChartAggregate) => void
): MenuItem[] {
  return React.useMemo(
    () =>
      [
        ChartAggregate.Average,
        ChartAggregate.Count,
        ChartAggregate.Max,
        ChartAggregate.Min,
        ChartAggregate.Sum,
        ChartAggregate.Accumulate,
      ].map((agg) => ({
        children: getAggregateLabel(agg),
        onClick: () => onSelect(agg),
      })),
    [onSelect]
  );
}
