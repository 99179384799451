import { FieldDef } from "../types";
import { createStore } from "../store";
import * as mocked from "./mocked";

export const fieldDefs = createStore("FieldDef", {
  userId: mocked.userId,
  initial: Object.keys(mocked.orderFields).map(
    (key) => mocked.orderFields[key as keyof typeof mocked.orderFields]
  ) as FieldDef<any>[],
});
