import React from "react";
import { x } from "@maintmark/shared-web/src/ui";

export const Label = (props: React.PropsWithChildren<{ active?: boolean }>) => {
  return (
    <x.div
      f12
      p900
      light
      opacity80
      opacity100={props.active}
      mb5p
      ml5p
      noselect
      {...props}
    />
  );
};
