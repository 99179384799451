import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../../../x/extend";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const input = extend<
  HTMLInputElement,
  CSSProperties | undefined,
  React.InputHTMLAttributes<HTMLInputElement>,
  typeof styles,
  typeof props
>("input", styles, {}, props);

export type inputProps = ExtractPropType<typeof input>;
