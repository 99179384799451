type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: any;
};

export function match<T>(
  item: T,
  value: string,
  props: KeyOfType<T, string>[]
) {
  const lowerValue = value.toLowerCase();
  return props.some((prop) => {
    return (item[prop] as string).toLowerCase().indexOf(lowerValue) >= 0;
  });
}
