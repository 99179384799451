import { colors } from "@maintmark/shared/src/ui/theme";
import React from "react";
import { x } from "@maintmark/shared-web/src/ui";
import { Search } from "./Search";
import { TabProps, useTabs } from "./Tabs";

interface SearchResultsProps<T> {
  onSearch: (term: string) => void;
  onSelect: (item: T) => void;
  ItemComponent: React.ComponentType<{ item: T; onSelect: (item: T) => void }>;
  items: T[];
  header?: string;
}

export const SearchResults = <T,>(props: SearchResultsProps<T>) => {
  const { onSearch, onSelect, items, ItemComponent, header, ...other } = props;
  return (
    <x.div minh300p noselect>
      <Search bgray200 onChange={onSearch} mh8p mv2 autoFocus {...other} />
      <x.div vscroll>
        {header && (
          <x.div uppercase f10 medium gray400 mb5p mh1>
            {header}
          </x.div>
        )}
        {items.map((item) => (
          <ItemComponent item={item} onSelect={onSelect} />
        ))}
      </x.div>
    </x.div>
  );
};

interface SearchGroup {
  title: string;
  ResultsCmp: React.ComponentType<any>;
}

interface SearchBoxProps {
  groups: SearchGroup[];
  tabProps?: Partial<TabProps>;
}

export const SearchBox = (props: SearchBoxProps & x.divProps) => {
  const { groups, tabProps, ...other } = props;

  const { tabs, tabsContent } = useTabs(
    groups.reduce(
      (m, key, index) => ({
        ...m,
        [index]: groups[index].title,
      }),
      {}
    ),
    tabProps
  );

  return (
    <x.div bgwhite br12p shadowPopup {...other}>
      <>
        <x.div ph1 row bbgray100>
          {tabs}
        </x.div>
        {groups.map((group, index) =>
          (tabsContent as any)[index](<group.ResultsCmp />)
        )}
      </>
    </x.div>
  );
};
