import React from "react";
import { Dashboard, t, Workflow } from "@maintmark/shared";
import DownImg from "./images/down@3x.png";
import { Menu, Popup, x } from "@maintmark/shared-web/src/ui";
import { data } from "@maintmark/shared-react";
import { portal, usePortal } from "@maintmark/shared-web/src/ui/popup/portal";
import { WorkflowForm } from "../workflow/WorkflowForm";
import { ModalProps } from "@maintmark/shared-react/src/ui/modals";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardForm } from "../dashboard/DashboardForm";
import { MenuItem } from "../order/item/menuitems";
import { useParam } from "./utils";

const NavLabel = (props: x.divProps & { drop?: boolean; active?: boolean }) => {
  const { drop, children, active, ...other } = props;
  return (
    <x.div
      f16
      pointer
      p900
      row
      mr24p
      opacity60
      opacity100={!active ? "hover" : true}
      light={!active}
      {...other}
    >
      {children}
      {drop && (
        <x.div ml5p mb2p>
          <x.img src={DownImg} width={13} height={8} />
        </x.div>
      )}
    </x.div>
  );
};

const NavLink = (props: { path: string } & x.divProps) => {
  const { path, ...other } = props;
  const { pathname } = useLocation();
  return <NavLabel {...other} active={pathname === path} />;
};

const NavDrop = (
  props: { items: MenuItem[]; active?: boolean } & x.divProps
) => {
  const { items, children, active, ...other } = props;
  const renderDrop = React.useCallback(
    (close: () => void) => (
      <Menu pv5p mt5p md items={items} onItemClick={close} />
    ),
    [items]
  );

  return (
    <Popup renderContent={renderDrop}>
      <NavLabel drop active={active}>
        {children}
      </NavLabel>
    </Popup>
  );
};

const WorkflowMenu = () => {
  const workflows = data.workflows.useStore((store) => store.all(), []);
  const navigate = useNavigate();
  const flowId = useParam("/workflow", "id");
  const activeFlow = flowId ? data.workflows.get(flowId) : null;

  const items: MenuItem[] = React.useMemo(
    () => [
      ...workflows.map((wf) => ({
        children: wf.name,
        onClick: () => {
          navigate(`/workflow?id=${wf.id}`);
        },
      })),
      ...(workflows.length > 0 ? ["separator" as "separator"] : []),
      {
        children: t("Create workflow"),
        onClick: async () => {
          const workflow = await portal.showModal<
            Workflow | "cancel",
            ModalProps<Workflow>
          >(WorkflowForm, {});

          if (workflow === "cancel") {
            return;
          }

          data.workflows.insert(workflow);

          navigate(`/workflow?id=${workflow.id}`);
        },
      },
    ],
    [workflows]
  );

  return (
    <NavDrop items={items} active={!!activeFlow}>
      {activeFlow ? activeFlow.name : t("Workflows")}
    </NavDrop>
  );
};

const DashboardMenu = () => {
  const dashboards = data.dashboards.useStore((store) => store.all(), []);
  const navigate = useNavigate();
  const boardId = useParam("/dashboard", "id");
  const activeBoard = boardId ? data.dashboards.get(boardId) : null;

  const items: MenuItem[] = React.useMemo(
    () => [
      ...dashboards.map((board) => ({
        children: board.name,
        onClick: () => {
          navigate(`/dashboard?id=${board.id}`);
        },
      })),
      ...(dashboards.length > 0 ? ["separator" as "separator"] : []),
      {
        children: t("Create dashboard"),
        onClick: async () => {
          const board = await portal.showModal<
            Dashboard | "cancel",
            ModalProps<Dashboard>
          >(DashboardForm, {});

          if (board === "cancel") {
            return;
          }

          data.dashboards.insert(board);

          navigate(`/dashboard?id=${board.id}`);
        },
      },
    ],
    [dashboards]
  );

  return (
    <NavDrop items={items} active={!!activeBoard}>
      {activeBoard ? activeBoard.name : t("Dashboards")}
    </NavDrop>
  );
};

export const NavMenu = () => {
  const navigate = useNavigate();

  return (
    <x.div row mt5p>
      <NavLink onClick={() => navigate("/")} path="/">
        {t("Scheduler")}
      </NavLink>
      <WorkflowMenu />
      <DashboardMenu />
    </x.div>
  );
};
