import * as React from "react";

export interface PortalContent<P> {
  Component: React.ComponentType<P>;
  props: P;
}

type Subscriber = (content: PortalContent<any>, remove: boolean) => void;
type Subscription = () => void;

export interface Portal {
  show<P>(content: PortalContent<P>): void;
  showModal<R, P extends ModalProps<R>>(
    Component: React.ComponentType<P>,
    props: Omit<P, "onClose">
  ): Promise<R>;
  hide<P>(content: PortalContent<P>): void;
  subscribe(subscriber: Subscriber): Subscription;
}

export interface ModalProps<R> {
  onClose(result: R): void;
}

export function createPortal(): [
  React.ComponentType<React.PropsWithChildren<{}>>,
  React.ComponentType<{ Container: React.ComponentType<any> }>,
  () => Portal,
  Portal
] {
  let subscribers: Subscriber[] = [];

  function emit(content: PortalContent<any>, remove: boolean) {
    subscribers.forEach((s) => s(content, remove));
  }

  const portal = {
    show<P>(content: PortalContent<P>) {
      emit(content, false);
      return content;
    },
    showModal<R, P extends ModalProps<R>>(
      Component: React.ComponentType<P>,
      props: Omit<P, "onClose">
    ) {
      return new Promise<R>((resolve) => {
        const content = portal.show({
          Component,
          props: {
            ...props,
            onClose: (result: R) => {
              portal.hide(content);
              resolve(result);
            },
          } as P,
        });
      });
    },
    hide<P>(content: PortalContent<P>) {
      emit(content, true);
    },
    subscribe(subscriber: Subscriber) {
      subscribers.push(subscriber);
      return () => {
        subscribers = subscribers.filter((s) => s !== subscriber);
      };
    },
  };

  const PortalSurface = (props: { Container: React.ComponentType<any> }) => {
    const [contents, setContents] = React.useState<PortalContent<any>[]>([]);

    React.useEffect(
      () =>
        portal.subscribe((content, remove) =>
          setContents((contents) => {
            if (remove) {
              return contents.filter((c) => c !== content);
            } else {
              return [...contents, content];
            }
          })
        ),
      []
    );

    return React.createElement(props.Container, {
      children: contents.map((content) =>
        React.createElement(content.Component, content.props)
      ),
    });
  };

  const Context = React.createContext<Portal>(portal);

  return [
    (props: React.PropsWithChildren<{}>) =>
      React.createElement(Context.Provider, {
        children: props.children,
        value: portal,
      }),
    PortalSurface,
    () => React.useContext(Context),
    portal,
  ];
}
