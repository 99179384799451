import { alpha, theme, time, XYChart } from "@maintmark/shared";
import { x } from "@maintmark/shared-react/src/ui/web/x";
import * as rc from "recharts";
import styled from "styled-components";
import { useXYData } from "./data";
import { getXAxisProps } from "./utils";

interface Props {
  size: "sm" | "md";
  chart: XYChart<any>;
}

function formatLabel(value: any) {
  return time.format(parseInt(value as any, 10), "yyyy-MM-dd");
}

export const LineChartView = styled((props: Props & x.divProps) => {
  const { size, className, chart } = props;
  const chartData = useXYData(chart);

  return (
    <rc.ResponsiveContainer className={className}>
      <rc.LineChart data={chartData.data}>
        <rc.XAxis {...getXAxisProps(chartData)} hide />
        <rc.YAxis fontSize={10} hide />
        <rc.CartesianGrid
          stroke={alpha(theme.colors.primary[900], 0.15)}
          strokeDasharray="5 5"
        />
        <rc.Tooltip labelFormatter={formatLabel} />
        {chartData.yFields.map((yField) => (
          <rc.Line
            type="monotone"
            dataKey={yField.id}
            stroke={alpha(theme.colors.primary[900], 0.8)}
            strokeWidth={size === "md" ? 4 : 3}
            dot={false}
          />
        ))}
      </rc.LineChart>
    </rc.ResponsiveContainer>
  );
})`
  g.recharts-line {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
  }
`;
