import { Bot } from "../types";

export interface Column {
  name: string;
  values?: string[];
  desc?: string;
}

export interface Table {
  name: string;
  description: string;
  columns: Column[];
}

export interface Schema {
  tables: Table[];
}

export interface Database {
  run(cmd: string): Promise<string>;
  describe(): string;
}

function formatColumn(col: Column) {
  let result = col.name;

  if (col.values) {
    result += ` [possible values: ${col.values.map((v) => v).join(",")}]`;
  }

  if (col.desc) {
    result += ` (${col.desc})`;
  }

  return result;
}

// ${schema.tables
//   .map(
//     (table) =>
//       `table: ${table.name} columns: ${table.columns
//         .map((col) => formatColumn(col))
//         .join(",")}`
//   )
//   .join("\n")}

function formatError(error: any) {
  return getErrorText(error).replace("alasql.fn.", "");
}

function getErrorText(error: any) {
  if (typeof error === "string") {
    return error;
  }
  if ("toString" in error) {
    return error.toString();
  }
  return JSON.stringify(error);
}

export function createSqlBot(database: Database, schema: string): Bot {
  return {
    name: "Sql",
    description: `An SQL database client that accepts SQL as input. ${database.describe()}. The schema (SQLITE dialect):
    ${schema}
    `,
    async run(command: string) {
      try {
        return await database.run(command);
      } catch (error) {
        return formatError(error);
      }
    },
  };
}
