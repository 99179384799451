import React from "react";
import { useLocation } from "react-router";

export function useParam(url: string, param: string) {
  const { pathname, search } = useLocation();

  return React.useMemo(() => {
    if (pathname !== url) {
      return null;
    }

    const params = new URLSearchParams(search);
    const id = params.get(param);
    return id || null;
  }, [pathname, search, url, param]);
}
