import { CSSProperties } from "react";
import { extend, ExtractPropType } from "../extend2";
import generic from "../styles/generic";
import text from "../styles/text";

const styles = {
  ...generic,
  ...text,
};

const props = {};

export const a = extend<
  HTMLAnchorElement,
  CSSProperties | undefined,
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  typeof styles,
  typeof props
>("a", styles, {}, props);

export type aProps = ExtractPropType<typeof a>;
