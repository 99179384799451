import { createEvent, emit, addListener, EventResult } from "@maintmark/shared";
import React, { CSSProperties } from "react";
import {
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";

interface DroppedPayload {
  target: DropTarget<any>;
  itemId: string;
  index: number;
}

interface DraggableId {
  itemId: string;
  listId: string;
}

const droppedEvent = createEvent<DroppedPayload>("dropped");

interface DropPayload {
  target: DropTarget<any>;
  itemId: string;
  style: DraggingStyle | NotDraggingStyle | undefined;
  setStyle: (style: CSSProperties) => void;
}

const dropEvent = createEvent<DropPayload>("drop");

function decodeTarget(droppableId: string) {
  const target = JSON.parse(droppableId) as DropTarget<any>;
  if (!("type" in target)) {
    return null;
  }
  return target;
}

export function encodeDraggableId(draggableId: DraggableId) {
  return JSON.stringify(draggableId);
}

function decodeDraggableId(data: string) {
  return JSON.parse(data) as DraggableId;
}

export function dispatchDropped(drop: DropResult) {
  const { destination } = drop;
  if (!destination) {
    return;
  }

  const target = decodeTarget(destination.droppableId);
  if (target) {
    emit(
      droppedEvent({
        target,
        itemId: decodeDraggableId(drop.draggableId).itemId,
        index: destination.index,
      })
    );
  }
}

export function dispatchDrop(
  dropped: {
    droppableId: string;
  } & Omit<DropPayload, "target">
) {
  const { droppableId, ...other } = dropped;

  if (!droppableId) {
    return;
  }

  const target = decodeTarget(droppableId);
  if (target) {
    emit(dropEvent({ target, ...other }));
  }
}

export interface DropTarget<T extends string> {
  type: T;
  data: any;
}

type Payload = DroppedPayload | DropPayload;
type CallbackData<T extends Payload, D> = Omit<T, "target"> & { target: D };

export function useDropTarget<T extends string, D>(
  type: T,
  onDrop: (data: CallbackData<DropPayload, D>) => void,
  onDropped: (data: CallbackData<DroppedPayload, D>) => void,
  deps: React.DependencyList
): (data: D) => DropTarget<T> {
  React.useEffect(() => {
    return addListener(droppedEvent, (event) => {
      const { target, ...other } = event;
      if (target.type !== type) {
        return EventResult.NotHandled;
      }

      const data: CallbackData<DroppedPayload, D> = {
        ...other,
        target: target.data,
      };

      onDropped(data);

      return EventResult.Handled;
    });
  }, [deps]);

  React.useEffect(() => {
    return addListener(dropEvent, (event) => {
      const { target, ...other } = event;
      if (target.type !== type) {
        return EventResult.NotHandled;
      }

      const data: CallbackData<DropPayload, D> = {
        ...other,
        target: target.data,
      };

      onDrop(data);

      return EventResult.Handled;
    });
  }, [deps]);

  return React.useCallback((data: D) => {
    return {
      type,
      data,
    };
  }, []);
}
