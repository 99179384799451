import * as React from "react";
import { useFilters } from "./filters";
import { Filter, MenuItem, MenuItemType, Storage, t } from "@maintmark/shared";

function createMenuItem(filter: Filter): MenuItem {
  return {
    id: filter.id,
    type: MenuItemType.Organization,
    title: filter.title || t("Untitled"),
    children: [],
    filter,
  };
}

export interface MenuStore {
  activeId: string;
}

export function useMenuItems(
  storage: Storage<MenuStore>,
  selectDefault: (items: MenuItem[]) => MenuItem
): [MenuItem, MenuItem[], (item: MenuItem) => void] {
  const filters = useFilters();

  const items = React.useMemo(() => {
    return filters.map(createMenuItem);
  }, [filters]);

  const [{ activeId }] = storage.use();

  const active = React.useMemo(
    () => items.find((x) => x.id === activeId) || selectDefault(items),
    [items, activeId]
  );

  const set = React.useCallback((item: MenuItem) => {
    storage.set({
      activeId: item.id,
    });
  }, []);

  if (!active) {
    throw new Error("At least one filter needs to be active.");
  }

  return [active, items, set];
}
