export function removeInPlaceIf<T>(list: T[], predicate: (item: T) => boolean) {
  for (let i = 0; i < list.length; i++) {
    if (predicate(list[i])) {
      list.splice(i--, 1);
    }
  }
  return list;
}

export function uncapitalize(text: string) {
  if (text.length === 0) {
    return text;
  }

  return text[0].toLowerCase() + text.slice(1);
}

export function opt<T>(active: boolean, props: T): T | {} {
  if (!active) {
    return {};
  }
  return props;
}
