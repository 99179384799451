import React from "react";
import styled from "styled-components";
import cn from "classnames";
import { x } from "@maintmark/shared-web/src/ui";

export const GrabToScroll = styled(
  (props: React.PropsWithChildren<x.divProps>) => {
    const [grabbing, setGrabbing] = React.useState(false);
    const scroll = React.useRef<HTMLDivElement | null>(null);
    const start = React.useRef(0);

    const mouseDown = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (!scroll.current) {
          return;
        }
        start.current = e.clientX + scroll.current.scrollLeft;
        setGrabbing(true);
      },
      []
    );

    const mouseUp = React.useCallback(() => {
      setGrabbing(false);
    }, []);

    const mouseMove = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (!grabbing) {
          return;
        }

        if (scroll.current) {
          scroll.current.scrollLeft = start.current - e.clientX;
        }
      },
      [grabbing]
    );

    return (
      <div
        {...props}
        className={cn(
          {
            grabbing,
          },
          props.className
        )}
        ref={scroll}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onMouseMove={mouseMove}
      />
    );
  }
)`
  cursor: grab;
  overflow-x: hidden;

  &.grabbing {
    cursor: grabbing;
  }
`;
