import styled from "styled-components";
import { useScroll, x } from "@maintmark/shared-web/src/ui";
import { ChatMessage, t } from "@maintmark/shared";
import { ChatInput } from "./ChatInput";
import { useAssistant } from "src/assistant/hooks";
import { MessageItem } from "./MessageItem";
import { BusyItem } from "./BusyItem";
import MarkImg from "../images/mark@3x.png";
import RightImg from "../images/right@3x.png";
import { PromptView } from "./task/PromptView";
import React from "react";

const width = 440;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  secret: string;
}

export const Chat = styled((props: x.divProps & Props) => {
  const [_send, messages, prompts, isBusy] = useAssistant(props.secret);
  const autoScroll = React.useRef<boolean>(true);

  const [scrollRef, scrollTo] = useScroll<HTMLDivElement>({
    initial: "bottom",
  });

  React.useEffect(() => {
    if (props.isOpen) {
      scrollTo("bottom");
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    if (autoScroll) {
      scrollTo("bottom");
      autoScroll.current = false;
    }
  }, [messages]);

  const send = React.useCallback(
    (text: string) => {
      autoScroll.current = true;
      _send(text);
      scrollTo("bottom");
    },
    [_send]
  );

  return (
    <x.div className={props.className}>
      <x.div className="outside" onClick={props.onClose} />
      <x.div className="panel" shadowPopup bgwhite column>
        <x.div pv20p ph2 bgp50 bbgray200 row centers>
          <x.img src={MarkImg} width={40} height={40} mr1 />
          <x.div flex p900>
            <x.div extrabold f18>
              {t("maintmark copilot")}
            </x.div>
            <x.div f14 light>
              {t("Your assistant")}
            </x.div>
          </x.div>
          <x.div pointer>
            <x.img
              src={RightImg}
              width={8}
              height={14}
              onClick={props.onClose}
            />
          </x.div>
        </x.div>
        <x.div bggray50 flex column>
          <x.div column flex relative mv1>
            <x.div fill ph20p vscroll ref={scrollRef} column>
              <x.div flex />
              {messages.map((m) => (
                <MessageItem message={m} />
              ))}
              {isBusy && <BusyItem />}
            </x.div>

            <x.div p1 className="prompts">
              {prompts
                .slice(prompts.length - 1, prompts.length)
                .map((prompt) => (
                  <PromptView prompt={prompt} />
                ))}
            </x.div>
          </x.div>

          <x.div ph1 pb2 bggray50>
            <ChatInput onSend={send} isOpen={props.isOpen} />
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};

  & .outside {
    position: absolute;
    top: 0;
    left: 0;
    right: ${width}px;
    bottom: 0;
    pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  }

  & .panel {
    transition: all ease-out 0.2s;
    position: absolute;
    top: 0;
    right: ${(props) => (props.isOpen ? 0 : -width)}px;
    width: ${width}px;
    bottom: 0;
  }

  & .prompts {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;
