import { JSONNode, toCSS } from "css-convert-json";
import * as _ from "lodash";
import React from "react";
import { CSSObject } from "./types";

const isUnitLess: { [key: string]: boolean } = {
  animationIterationCount: true,
  borderImageOutset: true,
  borderImageSlice: true,
  borderImageWidth: true,
  boxFlex: true,
  boxFlexGroup: true,
  boxOrdinalGroup: true,
  columnCount: true,
  columns: true,
  flex: true,
  flexGrow: true,
  flexPositive: true,
  flexShrink: true,
  flexNegative: true,
  flexOrder: true,
  gridArea: true,
  gridRow: true,
  gridRowEnd: true,
  gridRowSpan: true,
  gridRowStart: true,
  gridColumn: true,
  gridColumnEnd: true,
  gridColumnSpan: true,
  gridColumnStart: true,
  fontWeight: true,
  lineClamp: true,
  lineHeight: true,
  opacity: true,
  order: true,
  orphans: true,
  tabSize: true,
  widows: true,
  zIndex: true,
  zoom: true,
  // SVG-related properties
  fillOpacity: true,
  floodOpacity: true,
  stopOpacity: true,
  strokeDasharray: true,
  strokeDashoffset: true,
  strokeMiterlimit: true,
  strokeOpacity: true,
  strokeWidth: true,
};

export function formatUnitValue(value: number) {
  return `${value}px`;
}

function cssToNode(css: CSSObject): JSONNode {
  return Object.keys(css).reduce(
    (node, key) => {
      const value = css[key];
      switch (typeof value) {
        case "object": {
          node.children[key] = cssToNode(value);
          break;
        }
        case "number": {
          node.attributes[_.kebabCase(key)] = isUnitLess[key]
            ? value.toString()
            : formatUnitValue(value);
          break;
        }
        default: {
          if (value !== undefined) {
            node.attributes[_.kebabCase(key)] = value.toString();
          }
          break;
        }
      }
      return node;
    },
    { children: {}, attributes: {} } as JSONNode
  );
}

export function toString(css: CSSObject) {
  return toCSS(cssToNode(css));
}

export function getSheet(tag: HTMLStyleElement) {
  if (tag.sheet) {
    return tag.sheet;
  }

  // Avoid Firefoz quirk where the style element might not have a sheet prop
  for (const sheet of document.styleSheets) {
    if (sheet.ownerNode === tag) {
      return sheet;
    }
  }

  throw new Error("Can't find style sheet.");
}

function createStyle() {
  const style = document.createElement("style");
  document.head.appendChild(style);
  return style;
}

export const StyleContext = React.createContext<{
  rulesLookup: Record<string, CSSObject[]>;
  sheet: CSSStyleSheet | null;
}>({
  rulesLookup: {},
  sheet: null,
});

export function useStyleRules() {
  const { sheet, rulesLookup } = React.useContext(StyleContext);

  const insertRules = React.useCallback(
    (key: string, items: CSSObject[]) => {
      if (rulesLookup[key]) {
        return;
      }

      if (!sheet) {
        return;
      }

      for (let item of items) {
        sheet.insertRule(toString(item), sheet.cssRules.length);
      }

      rulesLookup[key] = items;
    },
    [rulesLookup]
  );

  return insertRules;
}

export const StyleProvider = (props: React.PropsWithChildren<{}>) => {
  const [sheet, setSheet] = React.useState<CSSStyleSheet | null>(null);

  React.useEffect(() => {
    const style = createStyle();
    setSheet(getSheet(style));
  }, []);

  if (!sheet) {
    return null;
  }

  return React.createElement(StyleContext.Provider, {
    value: {
      rulesLookup: {},
      sheet,
    },
    ...props,
  });
};
