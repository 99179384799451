import { ui } from "@maintmark/shared-react";
import { constants } from "@maintmark/shared/src/ui/theme";
import GradientImg from "./images/gradient.png";

const { styles } = ui.x;

export default {
  ...styles.generic,
  // Drawer width
  z1: {
    zIndex: 1,
  },
  z2: {
    zIndex: 2,
  },
  z3: {
    zIndex: 3,
  },
  z4: {
    zIndex: 4,
  },
  wdrawer: {
    width: 243,
  },
  bggradient: {
    backgroundImage: `url(${GradientImg})`,
    backgroundRepeat: "round",
    backgroundSize: "cover",
  },
  viewport: {
    height: "100vh",
    width: "100vw",
  },
  shadow100: {
    boxShadow: "rgb(3 77 99 / 6%) 4px 0px 8px 0px",
  },
  shadowborder: {
    boxShadow: "rgb(91 93 107 / 20%) 0px 0px 1px 1px",
  },
  shadowPopup: {
    boxShadow: "rgb(16 18 35 / 15%) 0px 5px 20px 2px",
  },
  shadowLight: {
    boxShadow: "rgb(16 18 35 / 5%) 0px 5px 20px 2px",
  },
  pointer: {
    cursor: "pointer",
  },
  grab: {
    cursor: "grab",
  },
  hscroll: {
    overflowX: "scroll" as "scroll",
  },
  vscroll: {
    overflowY: "scroll" as "scroll",
  },
  sflex: {
    alignSelf: "stretch" as "stretch",
  },
  minh0p: {
    minHeight: 0,
  },
  minh100: {
    minHeight: "100%",
  },
  maxh0p: {
    maxHeight: 0,
  },
  maxh100: {
    maxHeight: "100%",
  },
  relative: {
    position: "relative" as "relative",
  },
  minw0p: {
    minWidth: 0,
  },
  minw30p: {
    minWidth: 30,
  },
  minh30p: {
    minHeight: 30,
  },
  minh300p: {
    minHeight: 300,
  },
  minw32p: {
    minWidth: 32,
  },
  minw220p: {
    minWidth: 220,
  },
  minw500p: {
    minWidth: 500,
  },
  noevents: {
    pointerEvents: "none" as "none",
  },
  events: {
    pointerEvents: "auto" as "auto",
  },
  noselect: {
    WebkitUserSelect: "none" as "none",
    MozUserSelect: "none" as "none",
    MsUserSelect: "none" as "none",
    userSelect: "none" as "none",
  },
  tail: {
    display: "block",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textright: {
    textAlign: "right" as "right",
  },
  mauto: {
    margin: "auto" as "auto",
  },
  header: {
    fontFamily: "Montserrat",
  },

  hide: {
    display: "none" as "none",
  },

  nowrap: {
    whiteSpace: "nowrap" as "nowrap",
  },

  /* Grid system */
  gridrow: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
  },

  col1: {
    gridColumn: "span 1",
  },
  col2: {
    gridColumn: "span 2",
  },
  col3: {
    gridColumn: "span 3",
  },
  col4: {
    gridColumn: "span 4",
  },
  col5: {
    gridColumn: "span 5",
  },
  col6: {
    gridColumn: "span 6",
  },
  col7: {
    gridColumn: "span 7",
  },
  col8: {
    gridColumn: "span 8",
  },
  col9: {
    gridColumn: "span 9",
  },
  col10: {
    gridColumn: "span 10",
  },
  col11: {
    gridColumn: "span 11",
  },
  col12: {
    gridColumn: "span 12",
  },

  gap1: {
    gridGap: constants.gridSize,
  },
  gap2: {
    gridGap: constants.gridSize * 2,
  },
  gap3: {
    gridGap: constants.gridSize * 3,
  },
  gap4: {
    gridGap: constants.gridSize * 4,
  },

  scale115: {
    transform: "scale(1.15)",
  },

  scale105: {
    transform: "scale(1.05)",
  },

  transition50: {
    transition: "all 50ms",
  },

  transition100: {
    transition: "all 100ms",
  },

  transition200: {
    transition: "all 200ms ease-out",
  },

  transition300: {
    transition: "all 300ms",
  },

  bdashed: {
    borderStyle: "dashed",
  },
  bwidth80: {
    borderWidth: 0.8,
  },
  bwidth50: {
    borderWidth: 0.5,
  },
  bwidth4p: {
    borderWidth: 4,
  },
  fadein300: {
    animation: "fadeIn 300ms",
  },
  rotate90: {
    transform: "rotate(90deg)",
  },
  scaley0: {
    transform: "scaleY(0)",
  },
};
