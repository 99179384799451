import { FieldDef } from "@maintmark/shared";
import { ModalProps } from "@maintmark/shared-react/src/ui/modals";
import { portal } from "@maintmark/shared-web/src/ui/popup/portal";
import { EditFieldForm } from "src/ui/field/EditFieldForm";

export function createField() {
  return portal.showModal<
    FieldDef<any> | "cancel",
    ModalProps<FieldDef<any> | "cancel">
  >(EditFieldForm, {});
}
