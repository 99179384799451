import React from "react";
import { x } from "@maintmark/shared-web/src/ui";
import { Label } from "./Label";
import DownImg from "./images/down-sm-gray300@3x.png";
import { Popup } from "../../popup";
import { Portal } from "@maintmark/shared-react/src/ui/modals";

export interface DropdownProps {
  name: string;
  text: string;
  renderContent: (close: () => void) => React.ReactElement;
  portal?: Portal;
  DropImg?: string;
}

export const Dropdown = (props: DropdownProps & x.divProps) => {
  const { name, DropImg, text, textright, portal, renderContent, ...other } =
    props;

  const [open, setOpen] = React.useState(false);

  return (
    <x.div column {...other} minw0p>
      <Label active={open}>{name}</Label>
      <Popup
        renderContent={renderContent}
        onToggle={setOpen}
        portal={portal}
        fullwidth
      >
        <x.div
          h40p
          f14
          gray900
          bgray200
          bgray300={open}
          br6p
          ph1
          pointer
          row
          centers
          relative
          bgwhite
          pr2
        >
          {textright && <x.div flex />}
          {text}
          <x.div right mr1>
            <x.img src={DropImg || DownImg} width={10} height={7} />
          </x.div>
        </x.div>
      </Popup>
    </x.div>
  );
};
