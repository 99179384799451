import { Config } from "./types";

const config: Config = {
  web: {
    uri: "http://localhost:5000",
  },
  api: {
    uri: "http://localhost:8082",
    secure: false,
  },
  events: {
    uri: "ws://localhost:8083",
  },
};

export default config;
