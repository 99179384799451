import React from "react";
import { Comment, Order, t, time } from "@maintmark/shared";
import cuid from "cuid";
import { useScroll, x } from "@maintmark/shared-web/src/ui";
import { useContext } from "@maintmark/shared-react";
import { useAutosizeTextArea } from "src/ui/textarea";

export const CommentItem = (props: {
  comment: Comment;
  index: number;
  other?: boolean;
}) => {
  const { comment, index, other } = props;

  var pane = document.getElementById("pane");
  if (pane) {
    pane.scrollTop = pane.offsetHeight;
  }

  return (
    <x.div p900 row mt2 ph1>
      {!other && <x.div flex />}
      <x.div column ends={!other} maxw260p>
        <x.div row mb5p ph1 ends opacity70>
          <x.div f12 medium mr10p>
            {comment.createdBy?.firstName}
          </x.div>
          <x.div light f12>
            {comment.createdAt &&
              time.format(comment.createdAt, "yyyy-MM-dd HH:mm")}
          </x.div>
        </x.div>
        <x.div
          bgp500={!other}
          br12p
          white={!other}
          bggray200={other}
          f14
          p900={other}
          p1
          bgray300={other}
          medium={!other}
        >
          {comment.message}
        </x.div>
      </x.div>
    </x.div>
  );
};

interface CommentInputProps {
  onAdd: (comment: Comment) => void;
  autoFocus?: x.textareaProps["autoFocus"];
}

export const CommentInput = (props: x.divProps & CommentInputProps) => {
  const { onAdd, autoFocus, ...other } = props;

  const { user } = useContext();

  const [value, setValue] = React.useState("");
  const [id, setId] = React.useState(cuid());

  const onRef = useAutosizeTextArea(18, [value]);

  const change = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
    },
    []
  );

  const submit = React.useCallback(() => {
    onAdd({
      __typename: "Comment",
      id,
      message: value,
      createdAt: time.now(),
      createdBy: user,
    });

    setTimeout(() => {
      setId(cuid());
    }, 1);
  }, [id, onAdd, user, value]);

  const keyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        submit();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [submit]
  );

  return (
    <x.div row {...other}>
      <x.div bgwhite bgray200 flex row ends pv1 mask br20p>
        <x.textarea
          ref={onRef}
          key={id}
          flex
          h40p
          f14
          pl20p
          placeholder={t("Enter your comment...")}
          onChange={change}
          defaultValue={""}
          autoFocus={autoFocus}
          onKeyDown={keyDown}
        />
        <x.div
          ph1
          f12
          mb2p
          p900
          uppercase
          regular
          pointer
          noselect
          onClick={submit}
        >
          {t("Submit")}
        </x.div>
      </x.div>
    </x.div>
  );
};

interface Props {
  order: Order;
  onAdd: (comment: Comment) => void;
}

export const Comments = (props: Props) => {
  const { order, onAdd } = props;

  const { user } = useContext();
  const [scrollRef, scrollTo] = useScroll<HTMLDivElement>({
    initial: "bottom",
  });

  return (
    <x.div flex column btgray200>
      <x.div flex relative>
        <x.div column vscroll fill ref={scrollRef} pb2>
          {order.comments.map((comment, index) => (
            <CommentItem
              key={comment.id}
              index={index}
              comment={comment}
              other={user.id !== comment.createdBy?.id}
            />
          ))}
        </x.div>
      </x.div>
      <CommentInput onAdd={onAdd} autoFocus mb2 />
    </x.div>
  );
};
