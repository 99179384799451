import { t } from "@maintmark/shared";
import cuid from "cuid";
import React from "react";
import { useAutosizeTextArea } from "../textarea";
import { x } from "@maintmark/shared-web/src/ui";
import SendImg from "./images/send@3x.png";
import AttachmentImg from "./images/attachment@3x.png";

interface Props {
  onSend: (text: string) => void;
  isOpen: boolean;
}

const Button = (props: x.imageProps) => (
  <x.div
    bggray100="hover"
    opacity70
    opacity100="hover"
    row
    centers
    centerp
    br6p
    pointer
    w2
    h2
  >
    <x.img width={28} height={28} {...props} />
  </x.div>
);

export const ChatInput = (props: Props) => {
  const [id, setId] = React.useState(cuid());
  const [value, setValue] = React.useState("");

  const setRef = useAutosizeTextArea(18, [value]);

  const change = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
    },
    []
  );

  const send = React.useCallback(() => {
    props.onSend(value);

    requestAnimationFrame(() => {
      setId(cuid());
    });
  }, [value]);

  const keyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter") {
        send();
      }
    },
    [send]
  );

  return (
    <x.div bgray300 br6p column bgwhite>
      <x.textarea
        sflex
        pt1
        ph1
        ref={setRef}
        key={id}
        light
        f14
        autoFocus={props.isOpen}
        placeholder={t("Enter message...")}
        autoComplete="off"
        spellCheck={false}
        onChange={change}
        defaultValue={""}
        onKeyDown={keyDown}
      />
      <x.div row h48p centers bbp900 bwidth4p ph1>
        <Button src={AttachmentImg} />
        <x.div flex />
        <Button src={SendImg} />
      </x.div>
    </x.div>
  );
};
