import { x } from "@maintmark/shared-web/src/ui";
import { Order, time } from "@maintmark/shared";
import { Avatar } from "src/ui/components";
import ReportedImg from "./images/note-reported@3x.png";
import CommentImg from "./images/note-comments@3x.png";
import _ from "lodash";

interface NoteProps {
  order: Order;
}

const ReportedNote = (props: NoteProps & x.divProps) => {
  const { order, ...other } = props;

  return (
    <x.div row centers sflex mr3p opacity40 {...other}>
      <x.img src={ReportedImg} width={16} height={16} />
      <x.div ml2p f12 p900>
        {time.formatDuration(
          _.sumBy(order.reportedTime, (x) => x.duration),
          true
        )}
      </x.div>
    </x.div>
  );
};

const CommentNote = (props: NoteProps & x.divProps) => {
  const { order, ...other } = props;

  return (
    <x.div row centers opacity40 {...other}>
      <x.img src={CommentImg} width={16} height={16} />
      <x.div ml4p f12 p900>
        {order.comments.length}
      </x.div>
    </x.div>
  );
};

export const Details = (props: NoteProps & x.divProps) => {
  const { order, ...other } = props;
  return (
    <x.div row h45p centers {...other}>
      <ReportedNote order={order} mr10p />
      <CommentNote order={order} />
      <x.div flex />
      <Avatar assignee={order.assignedTo} bggray300={!!order.closedAt} />
    </x.div>
  );
};
