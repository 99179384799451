import { assignTo, Filter, MenuItem, t, ui } from "@maintmark/shared";
import { DroppableList, Search } from "../components";
import { x } from "@maintmark/shared-web/src/ui";
import styled from "styled-components";
import { useDropTarget } from "src/ui/droppable";
import React from "react";
import { menuStorage } from "./ListSelector";
import { data, useFilterItems, useMenuItems } from "@maintmark/shared-react";
import { ListOrderItem } from "../order/item/ListOrderItem";

const ListView = styled(x.div)`
  top: 100px !important;

  overflow-y: hidden !important;
  // padding-right: 10px;

  &:hover {
    overflow-y: overlay !important;
    // padding-right: 3px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 0;
    box-shadow: none;
    -webkit-border-radius: 0;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-corner {
    background: #000;
  }
`;

const MenuButton = styled(
  (props: { item: MenuItem; select: (item: MenuItem) => void }) => {
    const { item, select, ...other } = props;
    return (
      <x.div
        h32p
        ph2
        centers
        row
        pointer
        onClick={() => select(item)}
        {...other}
      >
        <x.div gray800 f14 medium>
          {props.item.title}
        </x.div>
      </x.div>
    );
  }
)`
  &:hover {
    background-color: ${ui.theme.colors.gray[100]};
  }
`;

interface Props {
  filter: Filter | null;
}

const notScheduledFilter: Filter = {
  title: t("Not scheduled"),
  id: "filter:notscheduled:drawer",
  query: `orders[startsAt=0 and closedAt=0]`,
  patches: [{ op: "replace", path: ["startsAt"], value: 0 }],
};

export const Drawer = styled((props: Props & x.divProps) => {
  const filter = props.filter;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [active] = useMenuItems(menuStorage, (items) => items[0]);
  // const filter = active.filter!;

  const allItems = data.orders.useStore((store) => store.all(), []);

  const searchedItems = data.orders.useStore(
    (store) => (searchTerm ? store.search(searchTerm) : store.all()),
    [searchTerm]
  );

  const excludeIds = new Set(
    useFilterItems(
      allItems,
      filter || {
        title: t("Not scheduled"),
        id: "filter:notscheduled:drawerasd",
        query: `orders[startsAt!=0 or closedAt!=0]`,
        patches: [{ op: "replace", path: ["startsAt"], value: 0 }],
      }
    ).map((item) => item.id)
  );

  const items = searchedItems.filter((item) => !excludeIds.has(item.id));

  const encodeTarget = useDropTarget<"drawer", Filter>(
    "drawer",
    () => {},
    ({ itemId, target, index }) => {
      data.orders.update(itemId, (item) => assignTo(item, target, index));
    },
    []
  );

  return (
    <x.div bgwhite wdrawer shadow100 sflex h100 relative column {...props}>
      <x.div className="header" column endp ph1>
        <Search
          onChange={setSearchTerm}
          bgwhite
          bgray200
          placeholderColor={ui.theme.colors.gray[400]}
          w160p={false}
          w200p
        />
      </x.div>
      <x.div flex>
        <ListView mt1 fill>
          <DroppableList
            target={encodeTarget(notScheduledFilter)}
            ItemComponent={ListOrderItem}
            items={items}
          />
        </ListView>
      </x.div>

      {/* <ScrollView vscroll pl1 mr3p fill pt1p>
        {orders.map((order) => (
          <x.div mb1>
            <OrderItem item={order} />
          </x.div>
        ))}
      </ScrollView> */}
    </x.div>
  );
})`
  & .header {
    height: 100px;
  }
`;
