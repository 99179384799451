import { alpha, FieldType, theme, XYChart } from "@maintmark/shared";
import { x } from "@maintmark/shared-react/src/ui/web/x";
import * as rc from "recharts";
import styled from "styled-components";
import { ChartData, useXYData } from "./data";
import { getXAxisProps } from "./utils";

interface Props {
  size: "sm" | "md";
  chart: XYChart<any>;
}

export const BarChartView = styled((props: Props & x.divProps) => {
  const { size, chart, className } = props;
  const chartData = useXYData(chart);

  return (
    <rc.ResponsiveContainer className={className}>
      <rc.BarChart data={chartData.data}>
        <rc.CartesianGrid
          stroke={alpha(theme.colors.primary[900], 0.15)}
          strokeDasharray="5 5"
        />
        <rc.XAxis {...getXAxisProps(chartData)} hide />
        <rc.YAxis hide />
        <rc.Tooltip cursor={{ fill: alpha(theme.colors.primary[800], 0.1) }} />
        {/* <rc.Legend /> */}
        {chartData.yFields.map((yField) => (
          <rc.Bar
            dataKey={yField.id}
            fill={theme.colors.primary[900]}
            radius={12}
          />
        ))}
        {/* <rc.Bar dataKey="uv" fill={theme.colors.primary[600]} radius={12} /> */}
      </rc.BarChart>
    </rc.ResponsiveContainer>
  );
})`
  g.recharts-bar-rectangle {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
  }
`;
