import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { Scheduler } from "./scheduler/Scheduler";
import { x } from "@maintmark/shared-web/src/ui";
import { dispatchDropped } from "./droppable";
import { MouseProvider } from "./mouse";
import * as demo from "./demo";
import { GlobalStyle } from "./styles/GlobalStyle";
import { openSocket, ui } from "@maintmark/shared-web";
import { ProtectedRoute, SignIn } from "./auth";
import { theme } from "@maintmark/shared/src/ui";
import { StyleProvider } from "@maintmark/shared-web/src/ui/x";
import { Chat } from "./chat/Chat";
import cn from "classnames";
import { setTokenGetter } from "@maintmark/shared/src/auth";
import {
  data,
  EventResult,
  getEnvConfig,
  Socket,
  store,
} from "@maintmark/shared";
import { DrawSheet } from "./draw/DrawContext";
import { useMouseTracking } from "./multiplayer/mouse";
import { SidePanelSurface } from "./sidepanel";
import { pubToSocket, subToSocket } from "@maintmark/shared/src/store";
import { Dashboard } from "./dashboard/Dashboard";
import { Workflow } from "./workflow/Workflow";

function pubsub<T>(socket: Socket, store: store.Items<T>) {
  subToSocket(socket, (event) => {
    if (event.type !== store.type) {
      return;
    }

    switch (event.op) {
      case "sync": {
        store.change(event.changes, true);
        break;
      }
      case "change": {
        store.change(event.changes);
        break;
      }
    }
  });

  pubToSocket(socket, {
    op: "init",
    type: store.type,
  });

  store.subscribe((event) => {
    if (!("op" in event)) {
      if (!event.remote) {
        pubToSocket(socket, {
          op: "change",
          changes: [event.change],
          type: store.type,
        });

        return EventResult.Handled;
      }
    }
    return EventResult.NotHandled;
  });
}

export const App = () => {
  const dragEnd = React.useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      console.log(">>>> dragUpdated");
      dispatchDropped(result);
      setDragging(false);
    },
    []
  );

  const dragUpdated = React.useCallback(
    (initial: DragUpdate, provided: ResponderProvided) => {
      console.log(">>>> dragUpdated");
    },
    []
  );

  const beforeDragStart = React.useCallback((initial: DragStart) => {
    console.log(">>>> beforeDragStart");
    setDragging(true);
  }, []);

  const dragStart = React.useCallback((evt: any) => {}, []);

  const [open, setOpen] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const [ready, setReady] = React.useState(false);
  const secret = urlParams.get("secret");

  const [socket, setSocket] = React.useState<Socket | null>(null);

  React.useEffect(() => {
    setTokenGetter(async () => secret || "");
    openSocket(getEnvConfig().events.uri)
      .then(async (socket) => {
        pubsub(socket, data.orders);
        pubsub(socket, data.fieldDefs);
        pubsub(socket, data.templates);
        pubsub(socket, data.prompts);
        pubsub(socket, data.messages);
        return socket;
      })
      .then(setSocket)
      .finally(() => {
        setReady(true);
      });
  }, [secret]);

  useMouseTracking(socket);

  if (!ready) {
    return null;
  }

  return (
    <StyleProvider>
      <MouseProvider>
        <GlobalStyle colors={theme.colors} constants={theme.constants} />
        <ui.portal.Provider>
          <DragDropContext
            onDragStart={dragStart}
            onBeforeDragStart={beforeDragStart}
            onDragEnd={dragEnd}
            onDragUpdate={dragUpdated}
          >
            <x.div className={cn({ dragging })}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<ProtectedRoute />}>
                    <Route
                      path=""
                      element={<Scheduler onOpenChat={() => setOpen(true)} />}
                    />
                    <Route
                      path="/workflow"
                      element={<Workflow onOpenChat={() => setOpen(true)} />}
                    />
                    <Route
                      path="/dashboard"
                      element={<Dashboard onOpenChat={() => setOpen(true)} />}
                    />
                  </Route>
                  <Route path="/login" element={<SignIn />} />
                </Routes>
              </BrowserRouter>
            </x.div>
          </DragDropContext>

          <Chat
            isOpen={open}
            onClose={() => setOpen(false)}
            secret={secret || ""}
          />

          <x.div style={{ zIndex: 10000 }}>
            <SidePanelSurface />
            <ui.portal.Surface Container={x.div} />
            <demo.Surface Container={x.div} />
            <demo.DemoNotices />
          </x.div>
          <DrawSheet />
        </ui.portal.Provider>
      </MouseProvider>
    </StyleProvider>
  );
};
