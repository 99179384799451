import React from "react";
import { addListener, EventDef, EventListener } from "@maintmark/shared";

export function useEvent<T>(
  eventDef: EventDef<T>,
  listener: EventListener<T>,
  deps: React.DependencyList
) {
  React.useEffect(() => {
    return addListener(eventDef, listener);
  }, [listener, ...deps]);
}
