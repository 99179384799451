import React from "react";
import { DependencyList } from "react";

export function useKeyDown(
  cb: (event: KeyboardEvent) => void,
  deps: DependencyList
) {
  React.useEffect(() => {
    window.addEventListener("keydown", cb);
    return () => {
      window.removeEventListener("keydown", cb);
    };
  }, deps);
}
