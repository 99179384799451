import { theme, time } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";
import React from "react";
import styled from "styled-components";
import { useHover } from "../observable";
import RightArrowImg from "./images/right-open-md-gray800@3x.png";

export interface MenuButtonProps extends x.divProps {
  sm?: boolean;
  lg?: boolean;
  md?: boolean;
  align?: "left" | "right";
  icon?: "arrow";
  renderChild?: () => JSX.Element;
}

export type MenuSeparator = "separator";

export interface MenuItemGroup {
  children: string;
  subItems: MenuItem[];
  md?: boolean;
}

export type MenuItem = MenuItemGroup | MenuButtonProps | MenuSeparator;

const widths = {
  lg: 340,
  md: 200,
  sm: 160,
};

export function renderMenuItem(item: MenuItem, onItemClick?: () => void) {
  if (item === "separator") {
    return <x.div mv5p h1p bggray200 />;
  } else if ("subItems" in item) {
    const { subItems, ...other } = item;
    return (
      <MenuButton
        {...other}
        icon="arrow"
        renderChild={() => (
          <Menu
            sm={!item.md}
            md={item.md}
            pv5p
            onItemClick={onItemClick}
            items={item.subItems}
          />
        )}
      />
    );
  } else {
    return (
      <MenuButton
        {...item}
        onClick={(e: any) => {
          if (onItemClick) {
            onItemClick();
          }
          if (item.onClick) {
            item.onClick(e);
          }
        }}
      />
    );
  }
}
function getWidth(props: MenuButtonProps) {
  return props.lg ? widths.lg : props.sm ? widths.sm : widths.md;
}

export const MenuButton = React.memo(styled(
  (props: React.PropsWithChildren<MenuButtonProps>) => {
    const { children, renderChild, icon, ...other } = props;
    const [node, setNode] = React.useState<HTMLDivElement | null>(null);

    const isHover = useHover(
      {
        node,
        delay: time.seconds(0.1),
      },
      []
    );

    return (
      <x.div
        f12={!props.lg}
        f14={props.lg}
        centers
        row
        pointer
        ph1={!props.lg}
        ph2={props.lg}
        h35p
        gray800
        relative
        noselect
        {...other}
        ref={setNode}
      >
        <x.div flex>{children}</x.div>
        <x.div className="child">
          {isHover && renderChild && renderChild()}
        </x.div>
        {icon === "arrow" && (
          <x.img src={RightArrowImg} width={6} height={10} opacity30 />
        )}
      </x.div>
    );
  }
)`
  height: ${(props) => (props.lg ? 32 : 28)}px;
  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`);

export interface MenuProps extends x.divProps {
  sm?: boolean;
  lg?: boolean;
  md?: boolean;
  items?: MenuItem[];
  onItemClick?: () => void;
}

export const Menu = styled((props: React.PropsWithChildren<MenuProps>) => {
  const { children, lg, sm, items = [], ...other } = props;
  return (
    <x.div bgwhite br6p shadowPopup bgray200 column {...other}>
      {items.map((item) => renderMenuItem(item, props.onItemClick))}
      {children}
    </x.div>
  );
})(
  (props) => `
  ${props.lg || props.sm || props.md ? `width: ${getWidth(props)}px;` : ""}
  & .child {
    position: absolute;
    top: 6px;
    left: 100%;
    z-index: 1;
  }
`
);
