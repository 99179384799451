import * as React from "react";

type Props<X> = { [_ in keyof X]?: boolean };

export type ExtractPropType<Type> = Type extends React.ComponentType<infer X>
  ? X
  : never;

export function extend<
  R,
  S,
  P extends { style?: S },
  XS extends { [key: string]: P["style"] },
  XP extends { [key: string]: Partial<P> } = {}
>(
  Component: React.ComponentType<P> | string,
  styles: XS,
  defaultProps?: P,
  extendedProps?: XP
) {
  return React.forwardRef(
    (props: Props<XS & XP> & P, ref: React.ForwardedRef<R>) => {
      const calcedStyle = Object.keys(props).reduce(
        (style, key) => {
          if (key in styles && props[key] === true) {
            return {
              ...style,
              ...styles[key],
            };
          }
          return style;
        },
        { ...defaultProps?.style } as S
      );

      const calcedProps = extendedProps
        ? Object.keys(props).reduce(
            (prop, key) => {
              if (key in extendedProps && props[key] === true) {
                return {
                  ...prop,
                  ...extendedProps[key],
                };
              }
              return prop;
            },
            { ...props, ...defaultProps, ref } as P
          )
        : props;

      return React.createElement(Component, {
        ...calcedProps,
        style: { ...calcedStyle, ...props.style },
      });
    }
  );
}
