import { Config } from "../types";

const config: Config = {
  api: {
    uri: "https://app.maintmark.com:8082",
    secure: true,
  },
  web: {
    uri: "https://app.maintmark.com",
  },
  events: {
    uri: "wss://app.maintmark.com:8083",
  },
};

export default config;
