import { ui } from "@maintmark/shared-react";

const { styles } = ui.x;

export default {
  ...styles.text,
  // bold: {
  //   fontWeight: 700,
  // },
  // extralight: {
  //   fontWeight: 100,
  // },
  // light: {
  //   fontWeight: 300,
  // },
  // regular: {
  //   fontWeight: 400,
  // },
  // medium: {
  //   fontWeight: 500,
  // },
  // semibold: {
  //   fontWeight: 600,
  // },
  // extrabold: {
  //   fontWeight: 800,
  // },
  // black: {
  //   fontWeight: 900,
  // },
  bold: {
    fontFamily: "Neometric-Bold",
    // fontWeight: 700,
  },
  light: {
    fontFamily: "Neometric-Light",
    // fontWeight: 300,
  },
  regular: {
    fontFamily: "Neometric-Regular",
    // fontWeight: 400,
  },
  medium: {
    fontFamily: "Neometric-Medium",
    // fontWeight: 500,
  },
  semibold: {
    fontFamily: "Neometric-Bold",
    // fontWeight: 600,
  },
  extrabold: {
    fontFamily: "Neometric-ExtraBold",
    // fontWeight: 800,
  },
  black: {
    fontFamily: "Neometric-Black",
    // fontWeight: 900,
  },
  mono: {
    fontFamily: "JetBrains Mono",
  },
  italic: {
    fontStyle: "italic",
  },
  underline: {
    textDecoration: "underline" as "underline",
  },
  lh140: {
    lineHeight: "140%",
  },
  lh180: {
    lineHeight: "180%",
  },
};
