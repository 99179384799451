import _ from "lodash";
import { Component, ComponentType } from "./types";

export function isComponentType<T extends Component["type"]>(
  component: Component,
  type: T
): component is ComponentType<T> {
  if (component.type === type) {
    return true;
  }
  return false;
}

export function queryComponentIndex<T extends Component["type"]>(
  components: Component[],
  type: T
) {
  return components.findIndex((x) => x.type === type);
}

export function queryComponent<T extends Component["type"]>(
  components: Component[],
  type: T,
  defaultValue: ComponentType<T>
): ComponentType<T> {
  return (components.find((x) => x.type === type) ||
    defaultValue) as ComponentType<T>;
}

export function mapComponent<T extends Component["type"]>(
  components: Component[],
  type: T,
  map: (cmp: ComponentType<T>) => ComponentType<T> | void
): Component[] {
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (isComponentType(component, type)) {
      const result = map(component);
      if (result) {
        components[i] = result;
      }
    }
  }
  return components;
}
