import { alpha, theme } from "@maintmark/shared";
import { x } from "@maintmark/shared-react/src/ui/web/x";
import * as rc from "recharts";
import styled from "styled-components";

interface Props {
  size: "sm" | "md";
}

export const PieChartView = styled((props: Props & x.divProps) => {
  const { size, className } = props;
  const data01 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const data02 = [
    { name: "A1", value: 100 },
    { name: "A2", value: 300 },
    { name: "B1", value: 100 },
    { name: "B2", value: 80 },
    { name: "B3", value: 40 },
    { name: "B4", value: 30 },
    { name: "B5", value: 50 },
    { name: "C1", value: 100 },
    { name: "C2", value: 200 },
    { name: "D1", value: 150 },
    { name: "D2", value: 50 },
  ];

  return (
    <rc.ResponsiveContainer className={className}>
      <rc.PieChart>
        <rc.Tooltip />
        <rc.Pie
          data={data01}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius="60%"
          fill={theme.colors.primary[600]}
        />
        <rc.Pie
          data={data02}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius="70%"
          outerRadius="90%"
          fill={theme.colors.primary[900]}
        />
      </rc.PieChart>
    </rc.ResponsiveContainer>
  );
})`
  g.recharts-pie {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
  }
`;
