import { ChartDataSource, t } from "@maintmark/shared";
import React from "react";

export function getDataSourceLabel(source: ChartDataSource) {
  switch (source) {
    case "Order": {
      return t("Work orders");
    }
    case "TimeReport": {
      return t("Time reports");
    }
  }
}

const sources: ChartDataSource[] = ["Order", "TimeReport"];

export function useDataSources(onSelect: (source: ChartDataSource) => void) {
  return React.useMemo(
    () =>
      sources.map((source) => ({
        children: getDataSourceLabel(source),
        onClick: () => onSelect(source),
      })),
    [onSelect]
  );
}
