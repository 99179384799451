import { Item, ItemType } from "./store";
import { Patch } from "immer";

export type DateTime = number;

// interface OrderAssignment {
//   type: "User" | "Order";
//   id: string;
// }

export enum OrderUrgency {
  Normal = "NORMAL",
  Urgent = "URGENT",
}

export enum PeriodUnit {
  Daily = "DAILY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export interface Periodicity {
  startsAt: number;
  unit: PeriodUnit;
  length: number;
}

export interface Recurrence {
  index: number;
  periodicity: Periodicity;
}

export type Assignee = User | Group;

interface ComponentBase<T extends string> {
  type: T;
}

export enum FieldType {
  String,
  ValueList,
  Float,
  Duration,
  Date,
  Boolean,
  Asset,
  Order,
}

export interface FieldGroup {
  id: string;
  name: string;
}

export interface FieldDef<T extends Item<any>> {
  id: keyof T & string;
  name: string;
  type: FieldType;
  group: FieldGroup;
  itemType: ItemType;
}

export interface Field<T extends Item<any>> {
  def: FieldDef<T>;
  value: any;
}

export interface TabDef {
  id: string;
  name: string;
}

export interface Tab {
  def: TabDef;
  value: any;
}

export interface ItemDef extends Item<"Item"> {
  name: string;
  number: string;
  components: Component[];
}

export interface OrderItem {
  type: ItemDef;
  allocated: number;
  issued: number;
  returned: number;
}

export type ComponentType<T extends Component["type"]> = Extract<
  Component,
  { type: T }
>;

export interface FieldsComponent extends ComponentBase<"fields"> {
  fields: Field<any>[];
}

export interface TabsComponent extends ComponentBase<"tabs"> {
  tabs: Tab[];
}

export interface ItemsComponent extends ComponentBase<"items"> {
  items: OrderItem[];
}

export type Component = ItemsComponent;

export interface Order extends Item<"Order"> {
  number: string;
  name: string;
  startsAt?: number;
  closedAt?: number;
  deletedAt?: number;
  description: string;
  assignedTo: Assignee;
  asset?: Asset;
  urgency: OrderUrgency;
  recurrence?: Recurrence;
  comments: Comment[];
  plannedTime: number;
  reportedTime: TimeReport[];
  rank?: FilterRank;
  subOrders: Order[];
  fields: FieldsComponent;
  tabs: TabsComponent;
  components: Component[];
}

export interface Template extends Item<"Template"> {
  order: Order;
}

export interface WorkflowPhase {
  id: string;
  name: string;
}

export interface Workflow extends Item<"Workflow"> {
  name: string;
  phases: WorkflowPhase[];
}

export enum ChartAggregate {
  Sum,
  Average,
  Min,
  Max,
  Count,
  Accumulate,
}

export type ChartType = "line" | "pie" | "bar";
export type ChartDataSource = "Order" | "TimeReport";

export interface ChartLayout {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface BaseChart<T extends ChartType> extends Item<"Chart"> {
  type: T;
  name: string;
  source: ChartDataSource;
  layout: ChartLayout;
}

export interface XYChart<T extends ChartType> extends BaseChart<T> {
  xAxis: {
    fieldId: string;
  };
  yAxis: {
    fieldId: string;
    aggregate: ChartAggregate;
  };
}

export interface PieChart extends BaseChart<"pie"> {}
export interface LineChart extends XYChart<"line"> {}
export interface BarChart extends XYChart<"bar"> {}
export type Chart = LineChart | BarChart | PieChart;

export interface Dashboard extends Item<"Dashboard"> {
  charts: Chart[];
  name: string;
}

export interface Group extends Item<"Group"> {
  id: string;
  name: string;
  type: "org" | "dep";
  children: Group[];
  parent?: Group;
}

export interface User extends Item<"User"> {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  short: string;
  groups: Group[];
}

export interface Asset extends Item<"Asset"> {
  id: string;
  number: string;
  name: string;
}

export interface TimeReport extends Item<"TimeReport"> {
  id: string;
  duration: number;
}

export interface Comment extends Item<"Comment"> {
  id: string;
  message: string;
}

export interface Filter extends Item<"Filter"> {
  query: string;
  title?: string;
  patches: Patch[];
}

export interface FilterRank {
  filterId: string;
  value: number;
}

interface Kanban {}

export interface WeekKanban extends Kanban, Item<"WeekKanban"> {
  startOfWeek: number;
}

export type AnyItem =
  | Order
  | Comment
  | Filter
  | TimeReport
  | Asset
  | User
  | Group;

export interface Storage<T> {
  set(item: T): void;
  use(): [T, boolean];
  get(): Promise<T>;
}

export enum MenuItemType {
  Organization,
  Group,
}

export interface MenuItem {
  id: string;
  type: MenuItemType;
  title: string;
  children: MenuItem[];
  filter?: Filter;
}

export interface PromptAction {
  thought: string;
  result?: string;
}

export interface Prompt {
  id: string;
  cmd: string;
  actions: PromptAction[];
  completedAt?: number;
}

export interface ChatMessage {
  id: string;
  senderUserId: string | null;
  text: string;
  read: boolean;
  prompt?: Prompt;
}
