import _ from "lodash";
import { getDayFilter } from "./filters";
import { time } from "./time";
import { WeekKanban } from "./types";

export function getLaneFilters(kanban: WeekKanban) {
  return _.range(0, 7).map((index) => {
    return getDayFilter(time.addDays(kanban.startOfWeek, index));
  });
}
