import { data, Order } from "@maintmark/shared";
import { useCreateOrder } from "@maintmark/shared-react";
import React from "react";
import { OrderPanel } from "src/ui/order/form/OrderPanel";
import { showSidePanel } from "src/ui/sidepanel";

export function useCreate() {
  const createOrder = useCreateOrder();

  return React.useCallback((values?: Partial<Order>) => {
    const order = createOrder(values);

    data.orders.insert(order);

    showSidePanel(OrderPanel, { orderId: order.id });

    return order;
  }, []);
}

export function edit(orderId: string, initialTab?: "comments") {
  showSidePanel(OrderPanel, { orderId, initialTab });
}
