import { Group, User } from "./types";

export function isUserInGroup(user: User, groupId: string) {
  let group: Group | undefined;
  for (group of user.groups) {
    for (; group; group = group.parent) {
      if (group.id === groupId) {
        return true;
      }
    }
  }

  return false;
}
