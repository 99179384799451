import { t, wait } from "@maintmark/shared";
import { TextInput } from "@maintmark/shared-web/src/ui";
import cuid from "cuid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { tokenStorage } from "src/token";
import styled from "styled-components";
import { Button } from "../components";
import { x } from "@maintmark/shared-web/src/ui";
import AvatarImg from "./images/avatar@3x.png";

export const SignIn = styled((props: React.PropsWithChildren<{}>) => {
  const [authError, setAuthError] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  const signIn = React.useCallback(async () => {
    setBusy(true);
    await wait(1000);
    setBusy(false);

    if (username.trim() === "test@test.com" && password.trim() === "test") {
      tokenStorage.set({ token: "test-token" });
      navigate("/");
    } else {
      setAuthError(true);
      setPassword("");
      setUsername("");
    }
  }, [username, password]);

  const valid = password.length > 0 && username.trim().indexOf("@") > 0;

  const resetError = React.useCallback(() => {
    setAuthError(false);
  }, []);

  const keyDownPassword = React.useCallback(
    (event: React.KeyboardEvent) => {
      resetError();

      if (event.key === "Enter" && valid) {
        signIn();
      }
    },
    [valid, signIn]
  );

  return (
    <x.div bggray200 {...props}>
      <x.div fill row centers centerp>
        <x.div id="frame" bgwhite br12p shadowPopup row>
          <x.div ph10p pv10p row flex>
            <x.div id="left" br12p bgp800 h100 column>
              <x.div p2 column flex>
                <x.div f14 p200 row id="top">
                  <x.div bold>maint</x.div>
                  <x.div medium>mark</x.div>
                </x.div>
                <x.div f24 extrabold white mt1>
                  {t("Start your next journey with us.")}
                </x.div>
                <x.div f14 p300 mt1 flex>
                  {t(
                    "Discover a ground breaking tool that will change the way you organize your work orders."
                  )}
                </x.div>
                <x.div bgp900 br12p p1 mb5p>
                  <x.div f10 p100 medium mb1>
                    {t(
                      "Finally!!! A tool that is truly user friendly and works with me in every moment."
                    )}
                  </x.div>
                  <x.div row>
                    <x.img src={AvatarImg} width={40} height={40} />
                    <x.div f10 p200 ml10p>
                      <x.div semibold mb3p mt3p>
                        Leslie Alexander
                      </x.div>
                      <x.div light>{t("Maintenance worker")}</x.div>
                    </x.div>
                  </x.div>
                </x.div>
                <x.div h2 row centerp centers>
                  <x.div h6p w6p bgwhite circle mr10p />
                  <x.div h6p w6p bgp900 circle mr10p />
                  <x.div h6p w6p bgp900 circle />
                </x.div>
              </x.div>
            </x.div>
            <x.div p2 column centerp flex>
              <x.div f24 black mb10p>
                {t("Sign in")}
              </x.div>
              <x.div f14 gray700 mb60p>
                {t("Enter your username and password to sign in.")}
              </x.div>

              <TextInput
                id="username"
                name={t("Username")}
                value={username}
                onUpdate={setUsername}
                placeholder="somebody@example.com"
                onKeyDown={resetError}
                mb20p
                autoFocus
                focus={authError}
              />

              <TextInput
                id="password"
                name={t("Password")}
                value={password}
                onUpdate={setPassword}
                type="password"
                placeholder={t("Enter password...")}
                onKeyDown={keyDownPassword}
              />

              <x.div alert600 mt1 uppercase medium f12 h16p>
                {authError ? t("Incorrect username or password") : ""}
              </x.div>

              <Button
                busy={busy}
                id="button"
                onClick={signIn}
                disabled={!valid}
              >
                {t("Sign in")}
              </Button>
            </x.div>
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
})`
  position: fixed;
  height: 100vh;
  width: 100vw;
  #frame {
    width: 820px;
    height: 620px;
    margin-bottom: 100px;

    #left {
      width: 325px;

      #top {
        height: 100px;
      }
    }

    #button {
      margin-top: 60px;
    }
  }
`;
