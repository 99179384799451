import React from "react";
import { x } from "@maintmark/shared-web/src/ui";

export const Tab = (
  props: React.PropsWithChildren<{ active?: boolean; onClick: () => void }>
) => {
  const { active } = props;
  return (
    <x.div
      bb0p
      f12
      p900
      opacity40
      opacity80="hover"
      opacity100={active}
      medium={active}
      ph10p
      pv10p
      pointer
      brt6p
      noselect
      {...props}
    />
  );
};

export const Tabs = (props: React.PropsWithChildren<x.divProps>) => {
  return <x.div row {...props} />;
};

type Content<T> = {
  [P in keyof T]: (elements: React.ReactNode) => React.ReactNode;
};

export function useTabs<T extends { [key: string]: string }>(
  data: T,
  enabled?: { [P in keyof T]?: boolean },
  initial?: keyof T
): {
  tabs: React.ReactNode;
  tabsContent: Content<T>;
} {
  const keys = Object.keys(data);
  const enabledKeys = keys.filter((key) => !enabled || enabled[key]);
  const [active, setActive] = React.useState<keyof T>(
    initial && (!enabled || enabled[initial]) ? initial : enabledKeys[0]
  );

  React.useEffect(() => {
    if (!enabledKeys.some((key) => key === active)) {
      setActive(enabledKeys[0]);
    }
  }, [enabled, active]);

  return {
    tabs: (
      <Tabs>
        {enabledKeys.map((key) => (
          <Tab active={active === key} onClick={() => setActive(key)}>
            {data[key]}
          </Tab>
        ))}
      </Tabs>
    ),
    tabsContent: keys.reduce(
      (m, k) => ({
        ...m,
        [k]: (content) => (active === k ? content : null),
      }),
      {} as Content<T>
    ),
  };
}
