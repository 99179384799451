import * as _ from "lodash";
import { Environment } from "./types";
import defaultConfig from "./default";
import * as configs from "./env";
export * from "./types";

let _env: Environment = "development";

export function setEnv(env: Environment) {
  console.log("Current environment: ", env);
  _env = env;
}

export function getEnvConfig() {
  const config = configs[_env] || defaultConfig;
  console.log(">>>>>>>>>>> coinfig", _env, config.events.uri);
  return config;
}
