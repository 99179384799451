import { Drawer } from "../drawer/Drawer";
import { NavBar } from "./NavBar";
import _ from "lodash";
import { Filter } from "@maintmark/shared";
import { x } from "@maintmark/shared-web/src/ui";

interface Props {
  onOpenChat: () => void;
  filter: Filter | null;
}

export const MainPage = (props: Props & x.divProps) => {
  return (
    <x.div>
      <x.div fill row>
        <Drawer filter={props.filter} />
        <x.div bgp50 flex column vscroll>
          <NavBar onOpenChat={props.onOpenChat} />
          {props.children}
        </x.div>
      </x.div>
    </x.div>
  );
};
