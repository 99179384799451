import { ChartData } from "./data";
import * as rc from "recharts";
import { FieldType } from "@maintmark/shared";

export function getXAxisProps(chartData: ChartData): rc.XAxisProps {
  switch (chartData.xField.type) {
    case FieldType.Date: {
      if (chartData.data.length === 0) {
        return {};
      }

      return {
        dataKey: chartData.xField.id,
        scale: "time",
        type: "number",
        domain: [
          chartData.data[0][chartData.xField.id],
          chartData.data[chartData.data.length - 1][chartData.xField.id],
        ],
        // tickFormatter: (t) => time.format(t, "YYYY-MM-DD"),
      };
    }
  }
  return {};
}
