import { x } from "@maintmark/shared-web/src/ui";
import { Spinner } from "./Spinner";
import CheckImg from "../images/check@3x.png";
import { PromptAction } from "@maintmark/shared";

interface Props {
  action: PromptAction;
}

export const ActionView = (props: React.PropsWithChildren<Props>) => {
  const { action } = props;
  return (
    <x.div bggray50>
      <x.div row ph1 pv12p btgray200 bgwhite opacity70>
        {!action.result ? (
          <Spinner />
        ) : (
          <x.img src={CheckImg} width={24} height={24} />
        )}
        <x.div mh1 p900 f12 flex lh140>
          {action.thought}
        </x.div>
        <x.div
          maxw120p
          medium
          f12
          row
          endp
          textright
          style={{
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {action.result}
        </x.div>
      </x.div>
    </x.div>
  );
};
