import * as React from "react";
import { FieldDef, FieldType, t } from "@maintmark/shared";
import { ModalProps } from "@maintmark/shared-react/src/ui/modals";
import {
  Dropdown,
  Form,
  FormFooter,
  FormHeader,
  Menu,
  MenuButton,
  TextInput,
  x,
} from "@maintmark/shared-web/src/ui";
import { Button } from "../components";
import cuid from "cuid";
import { fieldGroups } from "@maintmark/shared/src/data/mocked";

function getLabel(type: FieldType) {
  const labels = {
    [FieldType.Boolean]: t("True/False value"),
    [FieldType.Date]: t("Date"),
    [FieldType.Duration]: t("Time duration"),
    [FieldType.Float]: t("Number"),
    [FieldType.String]: t("String"),
    [FieldType.ValueList]: t("Value list"),
    [FieldType.Asset]: t("Asset"),
    [FieldType.Order]: t("Order"),
  };

  return labels[type];
}

export const EditFieldForm = (props: ModalProps<FieldDef<any> | "cancel">) => {
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState(FieldType.String);
  const valid = name.trim().length > 0;

  const renderTypeList = React.useCallback((close: () => void) => {
    const types = [
      FieldType.Boolean,
      FieldType.Date,
      FieldType.Duration,
      FieldType.Float,
      FieldType.String,
      FieldType.ValueList,
      FieldType.Asset,
      FieldType.Order,
    ];

    return (
      <Menu pv1>
        {types.map((type) => (
          <MenuButton
            onClick={() => {
              setType(type);
              close();
            }}
          >
            {getLabel(type)}
          </MenuButton>
        ))}
      </Menu>
    );
  }, []);
  return (
    <x.div
      fill
      column
      centers
      centerp
      overlay
      onClick={() => props.onClose("cancel")}
    >
      <Form
        w500p
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <FormHeader>
          <x.div f16>{t("Custom field")}</x.div>
        </FormHeader>

        <x.div ph2 mt20p mb2 row>
          <TextInput
            name={t("Name")}
            value={name}
            onUpdate={setName}
            flex
            autoFocus
            mr1
          />

          <Dropdown
            flex
            name={t("Type")}
            text={getLabel(type)}
            renderContent={renderTypeList}
          />
        </x.div>
        <FormFooter>
          <x.div
            p900
            h35p
            w120p
            row
            centers
            centerp
            opacity80
            opacity100="hover"
            f14
            pointer
            onClick={() => props.onClose("cancel")}
          >
            {t("Cancel")}
          </x.div>
          <x.div flex />
          <Button
            disabled={!valid}
            onClick={() => {
              props.onClose({
                group: fieldGroups.custom,
                id: cuid(),
                name: name.trim(),
                type,
                itemType: "Order",
              });
            }}
          >
            {t("Create")}
          </Button>
        </FormFooter>
      </Form>
    </x.div>
  );
};
